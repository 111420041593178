import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  CheckboxGroup,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import Checkbox from '~components/Checkbox'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import { ModalCloseButton } from '~components/Modal'
import Textarea from '~components/Textarea'

import Input from '../../../components/Input/index'

export const UpdateNotificationPage = (): JSX.Element | null => {
  const { notificationId } = useParams()

  const navigate = useNavigate()
  const toast = useToast()
  const [result, setResult] = useState({
    notificationType: {
      name: '',
      code: '',
    },
    _id: '',
    appendNotification: '',
    smsNotificationEnable: false,
    emailNotificationEnable: false,
    emailNotification: { subject: '', body: '' },
    smsNotification: '',
    agency: {
      agencyId: '',
      agencyName: '',
    },
    createdBy: {
      userCategoryCode: '',
      userCategoryName: '',
      userId: '',
      userName: '',
    },
    lastModifiedBy: {
      userId: '',
      userName: '',
      userCategoryCode: '',
      userCategoryName: '',
    },
    created: '',
    lastModifiedAt: '',
    _v: 0,
    lastAccessed: '',
  })
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)

  const [error, setError] = useState()

  async function getNotification() {
    ApiService.get(`/agency-notifications/${notificationId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setResult(data.data.agencyNotification) //result
          setIsLoaded(true)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const response = getNotification()
  }, [])

  useEffect(() => {
    mainFormValues.setValue('notifiId', result?._id ? result?._id : '')

    mainFormValues.setValue(
      'appendNotification',
      result?.appendNotification ? result?.appendNotification : '',
    )
    mainFormValues.setValue(
      'smsNotificationEnable',
      result?.smsNotificationEnable ? result?.smsNotificationEnable : false,
    )
    mainFormValues.setValue(
      'emailNotificationEnable',
      result?.emailNotificationEnable ? result?.emailNotificationEnable : false,
    )
    mainFormValues.setValue(
      'subject',
      result?.emailNotification.subject
        ? result?.emailNotification.subject
        : '',
    )
    mainFormValues.setValue(
      'body',
      result?.emailNotification.body ? result?.emailNotification.body : '',
    )
    mainFormValues.setValue(
      'smsNotification',
      result?.smsNotification ? result?.smsNotification : '',
    )
    mainFormValues.setValue(
      'notificationType',
      result?.notificationType?.name ? result?.notificationType?.name : '',
    )
    mainFormValues.setValue(
      'notificationCode',
      result?.notificationType?.code ? result?.notificationType?.code : '',
    )
    mainFormValues.setValue(
      'agencyId',
      result?.agency?.agencyId ? result?.agency?.agencyId : '',
    )
    mainFormValues.setValue(
      'agencyName',
      result?.agency?.agencyName ? result?.agency?.agencyName : '',
    )
    mainFormValues.setValue(
      'lastModifiedUserId',
      result?.lastModifiedBy?.userId ? result?.lastModifiedBy?.userId : '',
    )
    mainFormValues.setValue(
      'lastModifiedUserName',
      result?.lastModifiedBy?.userName ? result?.lastModifiedBy?.userName : '',
    )
    mainFormValues.setValue(
      'lsmUserCategoryCode',
      result?.lastModifiedBy?.userCategoryCode
        ? result?.lastModifiedBy?.userCategoryCode
        : '',
    )
    mainFormValues.setValue(
      'lsmUserCategoryName',
      result?.lastModifiedBy?.userCategoryName
        ? result?.lastModifiedBy?.userCategoryName
        : '',
    )
    mainFormValues.setValue('createdAt', result?.created ? result?.created : '')

    if (result?.emailNotificationEnable === true) {
      setEmailDis(true)
    } else {
      setEmailDis(false)
    }

    if (result?.smsNotificationEnable === true) {
      setSmsDis(true)
    } else {
      setSmsDis(false)
    }
  }, [result])

  const mainFormValues = useForm({
    defaultValues: {
      notifiId: '',
      appendNotification: '',
      smsNotificationEnable: false,
      emailNotificationEnable: false,
      subject: '',
      body: '',
      smsNotification: '',
      notificationType: '',
      notificationCode: '',
      agencyId: '',
      agencyName: '',
      lastModifiedUserId: '',
      lastModifiedUserName: '',
      lsmUserCategoryCode: '',
      lsmUserCategoryName: '',
      createdAt: '',
    },
  })

  const [emailDis, setEmailDis] = useState(false)

  const [smsDis, setSmsDis] = useState(false)

  const handleEmail = (event: any) => {
    if (event.target.checked) {
      setEmailDis(true)
    } else {
      setEmailDis(false)
    }
  }

  const handleSms = (event: any) => {
    if (event.target.checked) {
      setSmsDis(true)
    } else {
      setSmsDis(false)
    }
  }

  const onSubmitMain = (data: any) => {
    const userParams: any = {
      agencyId: data.agencyId,
      agency: {
        agencyId: data.agencyId,
        agencyName: data.agencyName,
      },
      notificationType: {
        code: data.notificationCode,
        name: data.notificationType,
      },
      smsNotificationEnable: data.smsNotificationEnable,
      emailNotificationEnable: data.emailNotificationEnable,
      appendNotification: data.appendNotification,
      lastModifiedBy: {
        userId: data.lastModifiedUserId,
        userName: data.lastModifiedUserName,
        userCategoryCode: data.lsmUserCategoryCode,
        userCategoryName: data.lsmUserCategoryName,
      },
    }
    if (data.emailNotificationEnable === true) {
      userParams.emailNotification = {
        subject: data.subject,
        body: data.body,
      }
    }
    if (data.smsNotificationEnable === true) {
      userParams.smsNotification = data.smsNotification
    }

    const requestBody = {
      agencyNotification: userParams,
    }

    ApiService.put(`/agency-notifications/${notificationId}`, requestBody)
      .then((data: any) => {
        if (data?.status === 200) {
          toast({
            title: '',
            description: 'Notification Updated',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          navigate('/notification')
        } else {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong!',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  return (
    <>
      <Box bg={'gray.50'} p={10}>
        <Box height="40px">
          <Heading as="h4" size="md">
            Update Notification
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          {/* <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px"> */}
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
            <span>
              Notification Type :{' '}
              {` ${mainFormValues.getValues('notificationType')}`}
            </span>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.appendNotification
                      ? true
                      : false
                  }
                  isRequired
                >
                  <FormLabel htmlFor="appendNotification" mb="8px">
                    Append to Notification *
                  </FormLabel>
                  <Input
                    placeholder={'Append to Notification'}
                    id="appendNotification"
                    maxLength={100}
                    {...mainFormValues.register('appendNotification', {
                      required: 'Append to Notification is Required',
                      maxLength: { value: 100, message: 'Max length is 100' },
                    })}
                  />
                  {mainFormValues.formState.errors.appendNotification && (
                    <FormErrorMessage>
                      {
                        mainFormValues.formState.errors.appendNotification
                          .message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box></Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.emailNotificationEnable
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="emailNotificationEnable" mb="8px">
                    Email
                  </FormLabel>
                  <CheckboxGroup>
                    <Stack spacing={[1, 5]} direction={['column', 'row']}>
                      <Checkbox
                        {...mainFormValues.register('emailNotificationEnable')}
                        onChange={handleEmail}
                      >
                        Enable Email Notification
                      </Checkbox>
                    </Stack>
                  </CheckboxGroup>
                </FormControl>
              </Box>
              {emailDis === true ? <Box></Box> : null}
              {emailDis === true ? (
                <div>
                  <Box height="80px">
                    <FormControl
                      isInvalid={
                        mainFormValues?.formState?.errors?.subject
                          ? true
                          : false
                      }
                      isRequired
                    >
                      <FormLabel htmlFor="emailSubject" mb="8px">
                        Subject *
                      </FormLabel>
                      <Input
                        placeholder={'Subject'}
                        id="subject"
                        maxLength={100}
                        {...mainFormValues.register('subject', {
                          required: 'Subject is Required',
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                        })}
                      />
                      {mainFormValues.formState.errors.subject && (
                        <FormErrorMessage>
                          {mainFormValues.formState.errors.subject.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                  <br />
                  <Box height="120px">
                    <FormControl
                      isInvalid={
                        mainFormValues?.formState?.errors?.body ? true : false
                      }
                      isRequired
                    >
                      <FormLabel htmlFor="emailBody" mb="8px">
                        Body *
                      </FormLabel>
                      <Textarea
                        placeholder={'Body'}
                        id="body"
                        minAutosizeRows={5}
                        maxLength={600}
                        {...mainFormValues.register('body', {
                          required: 'Body is Required',
                          maxLength: {
                            value: 600,
                            message: 'Max length is 600',
                          },
                        })}
                      />
                      {mainFormValues.formState.errors.body && (
                        <FormErrorMessage>
                          {mainFormValues.formState.errors.body.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>
                </div>
              ) : null}
              <Box></Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.smsNotificationEnable
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="smsNotificationEnable" mb="8px" mt={10}>
                    SMS
                  </FormLabel>
                  <CheckboxGroup>
                    <Stack spacing={[1, 5]} direction={['column', 'row']}>
                      <Checkbox
                        {...mainFormValues.register('smsNotificationEnable')}
                        onChange={handleSms}
                      >
                        Enable SMS Notification
                      </Checkbox>
                    </Stack>
                  </CheckboxGroup>
                </FormControl>
              </Box>
              {smsDis === true ? <Box></Box> : null}
              {smsDis === true ? (
                <Box height="70px" mt={10}>
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.smsNotification
                        ? true
                        : false
                    }
                    isRequired
                  >
                    <FormLabel htmlFor="smsNotification" mb="8px">
                      SMS Content *
                    </FormLabel>
                    <Textarea
                      placeholder={'SMS Content'}
                      id="smsNotification"
                      maxLength={255}
                      minAutosizeRows={1}
                      maxAutosizeRows={4}
                      {...mainFormValues.register('smsNotification', {
                        required: 'SMS Content is Required',
                        maxLength: { value: 255, message: 'Max length is 255' },
                      })}
                    />
                    {mainFormValues.formState.errors.smsNotification && (
                      <FormErrorMessage>
                        {
                          mainFormValues.formState.errors.smsNotification
                            .message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
              ) : null}
            </SimpleGrid>
            <dl>
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px" mt={10}>
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Form Name : <Text as="b">{'<FormName>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Citizen Name : <Text as="b">{'<CitizenName>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Submitted At : <Text as="b">{'<SubmittedAt>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Payment Amount : <Text as="b">{'<FormAmount>'}</Text>
                  </dd>
                </div>
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Accepted At : <Text as="b">{'<AcceptedAt>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Rejected At : <Text as="b">{'<RejectedAt>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Completed At : <Text as="b">{'<CompletedAt>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '26%', padding: '10px', fontWeight: 500 }}>
                    Rejected Reason : <Text as="b">{'<RejectedReason>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Accepted Note : <Text as="b">{'<AcceptedNote>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Rejected Note : <Text as="b">{'<RejectedNote>'}</Text>
                  </dd>
                </div>
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '26%', padding: '10px', fontWeight: 500 }}>
                    Completed Note : <Text as="b">{'<CompletedNote>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Refunded Note : <Text as="b">{'<RefundedNote>'}</Text>
                  </dd>
                </div>

                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '25%', padding: '10px', fontWeight: 500 }}>
                    Serial Number : <Text as="b">{'<SerialNumber>'}</Text>
                  </dd>
                </div>
              </SimpleGrid>
            </dl>

            <Center>
              <ButtonGroup variant="outline" spacing="4" padding={10}>
                <Button type="submit">Update</Button>
                <Button onClick={() => setIsConfirmOpen(true)}>Cancel</Button>
              </ButtonGroup>
            </Center>
          </SimpleGrid>
          {/* </SimpleGrid> */}
        </form>
      </Box>
      {isConfirmOpen ? (
        <Modal
          isOpen={isConfirmOpen}
          onClose={() => {
            setIsConfirmOpen(false)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              Are you sure you want to cancel?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    setIsConfirmOpen(false)
                    navigate('/notification')
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setIsConfirmOpen(false)
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}
