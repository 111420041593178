import { UseControllerProps } from 'react-hook-form'

import {
  MAX_OTP_LENGTH,
  MAX_PHONE_LENGTH,
  MAX_USERNAME_LENGTH,
} from '~shared/constants'

export const PASSWORDS_MISMATCH = 'Please make sure the passwords match'
export const EMAIL_PHONE_INVALID = 'Invalid email address / phone number'
export const USERNAME_MUST_ALPHANUMERIC = 'Invalid username'
export const PHONE_INVALID = 'Invalid phone number'
export const EMAIL_INVALID = 'Invalid email'

export const USERNAME_VALIDATION_RULES: UseControllerProps['rules'] = {
  required: 'Username is required',
  maxLength: {
    value: MAX_USERNAME_LENGTH,
    message: `Username must be at most ${MAX_USERNAME_LENGTH} characters`,
  },
  pattern: {
    value: /^[ A-Za-z0-9.@#_!-]*$/,
    message: 'Only letters, numbers and .@#_-! are allowed',
  },
}

export const OTP_VALIDATION_RULES: UseControllerProps['rules'] = {
  required: 'OTP is required',
  maxLength: {
    value: MAX_OTP_LENGTH,
    message: `OTP must be at most ${MAX_OTP_LENGTH} characters`,
  },
  minLength: {
    value: MAX_OTP_LENGTH,
    message: `OTP must be at least ${MAX_OTP_LENGTH} characters`,
  },
  pattern: {
    value: /^[0-9\b]+$/,
    message: 'Only numbers are allowed.',
    // value: /^[a-zA-Z0-9]*$/,
    // message: 'Only alphanumeric are allowed.',
  },
}

export const PASSWORD_VALIDATION_RULES: UseControllerProps['rules'] = {
  required: 'New password is required',
}

export const RE_ENTER_PASSWORD_VALIDATION_RULES: UseControllerProps['rules'] = {
  required: 'Re-enter password is required',
}

export const OLD_PASSWORD_VALIDATION_RULES: UseControllerProps['rules'] = {
  required: 'Old password is required',
}

export const EMAIL_PHONE_VALIDATION_RULES: UseControllerProps['rules'] = {
  required: 'Email address or phone number is required',
}

export const PHONE_VALIDATION_RULES: UseControllerProps['rules'] = {
  required: 'Phone Number is required',
  maxLength: {
    value: MAX_PHONE_LENGTH,
    message: `Character length exceeded`,
  },
}

export const Email_VALIDATION_RULES: UseControllerProps['rules'] = {
  required: 'Email is required',
}
