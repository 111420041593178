import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { BiMessageSquareX, BiUpload } from 'react-icons/bi'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { Link as ReactLink, useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel as ChakraFormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  InputGroup,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  //useToast,
} from '@chakra-ui/react'
import { isEmpty, isString, times, trimStart } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'
import isEmail from 'validator/lib/isEmail'

import {
  MAX_EMAIL_LENGTH,
  MAX_OTP_LENGTH,
  MAX_PHONE_LENGTH,
} from '~shared/constants'
import { isSLMobilePhoneNumber } from '~shared/utils/phone-num-validation'

import { LOGGED_IN_KEY } from '~constants/localStorage'
import { TOC_ROUTE, UM_ROUTE } from '~constants/routes'
import { useIsMobile } from '~hooks/useIsMobile'
import { useLocalStorage } from '~hooks/useLocalStorage'
import { useToast } from '~hooks/useToast'
import {
  EMAIL_INVALID,
  Email_VALIDATION_RULES,
  OTP_VALIDATION_RULES,
  PHONE_INVALID,
  PHONE_VALIDATION_RULES,
} from '~utils/userValidation'
import { ApiService } from '~services/ApiService'
import {
  ValidateContactOtpGenerateOboarding,
  ValidateContactOtpVerifyOnboarding,
} from '~services/CitizenUserService'
import Button from '~components/Button'
//import Checkbox from '~components/Checkbox'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormFieldMessage from '~components/FormControl/FormFieldMessage'
import FormLabel from '~components/FormControl/FormLabel'
import IconButton from '~components/IconButton'
import { ModalCloseButton } from '~components/Modal'

import { convertNumberToSriLankaNumber } from '~pages/ManageUser/utils'

import { LanguageFilter } from '../../../../atom'
import Input from '../../../components/Input/index'
import {
  getMappedErrorMessage,
  handlePhoneValidation,
  PASSPORT_REGEX,
  SL_DRIVING_LICENSE_REGEX,
  SL_NIC_REGEX,
  validateImageSize,
  validateProfilePictureFormat,
} from '../utils'

import { useTitleUserPublic } from './queries'

export const OnboardingScreenPage = (): JSX.Element | null => {
  enum contactType {
    EMAIL = 'emails',
    PHONE = 'contacts',
  }

  type PublicUserRequestBody = {
    username: string
    title: string
    gender: string
    firstName: string
    lastName: string
    nameInitials?: string
    identificationType: string
    nicNo?: string
    passportNo?: string
    passportCountry?: string
    drivingLicenseNo?: string
    preferredLanguage?: string
    contact: string | null
    email?: string | null
    profPicture?: string
    signupSource: string
    iamId?: string
    phoneNoVerificationId: string
    emailVerificationId?: string
  }

  type phoneObject = {
    newContact: string
    contactType: contactType.PHONE
  }

  type DropdownData = {
    value: string
    label: string
  }

  //const toast = useToast({ isClosable: false })
  const toast = useToast()
  const navigate = useNavigate()
  const { data } = useTitleUserPublic()
  const imageUploadRef = useRef<HTMLInputElement | null>(null)
  const [, setIsAuthenticated] = useLocalStorage<boolean>(LOGGED_IN_KEY)
  const [wso2loggedUserDetails, setwso2loggedUserDetails] = useState<any>({}) //Can change according to signupSource
  const [loggedUserId, setLoggedUserId] = useState<string>('')
  const [loggedUserName, setLoggedUserName] = useState<string>('')
  const [loggedUserSignupSource, setloggedUserSignupSource] =
    useState<string>('')
  const [idType, setIdType] = useState<string>('')
  const [maxLength, setMaxLength] = useState<number>(0)
  const [minLength, setMinLength] = useState<number>(0)
  const [idNumberRegex, setIdNumberRegex] = useState<RegExp>(SL_NIC_REGEX)
  const [resendPhoneNoOtp, setResendPhoneNoOtp] = useState<boolean>(false)
  const [resendEmailOtp, setResendEmailOtp] = useState<boolean>(false)
  const [profilePicture, setProfilePicture] = useState<string>('')
  const [profilePicFile, setProfilePicFile] = useState<File | null>(null)
  const [pictureFileType, setPictureFileType] = useState<string>('image/png')
  const [picUploadResult, setPicUploadResult] = useState<string>('')
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [titleDropdownData, setTitleDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dropdownData, setDropdownData] = useState<DropdownData>()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [clickedBtn, setClickedBtn] = useState<string>('')

  //Phone Number Validation
  const [errorNewPhoneNo, setErrorNewPhoneNo] = useState<string | null>(null)
  const [successNewPhoneNo, setSuccessNewPhoneNo] = useState<string | null>(
    null,
  )
  const [validatingNewPhoneNo, setValidatingNewPhoneNo] = useState<
    string | null
  >(null)
  const [isNewPhoneNoVerified, setIsNewPhoneNoVerified] =
    useState<boolean>(false)
  const [newPhoneNumber, setNewPhoneNumber] = useState<phoneObject>({
    newContact: '',
    contactType: contactType.PHONE,
  })
  const [phoneNoVerificationId, setPhoneNoVerificationId] = useState<string>('')
  const locale: any = useRecoilValue(LanguageFilter)

  //Email Validation
  const [errorNewEmail, setErrorNewEmail] = useState<string | null>(null)
  const [successNewEmail, setSuccessNewEmail] = useState<string | null>(null)
  const [validatingNewEmail, setValidatingNewEmail] = useState<string | null>(
    null,
  )
  const [isNewEmailVerified, setIsNewEmailVerified] = useState<boolean>(false)
  const [emailVerificationId, setEmailVerificationId] = useState<string>('')
  const isMobile = useIsMobile()

  const mainFormValues = useForm({
    defaultValues: {
      title: '',
      gender: '',
      firstName: '',
      lastName: '',
      nameWithInitials: '',
      identificationType: 'N',
      identificationNo: '',
      countryOfPassport: 'SRI_LANKA',
      preferredLanguage: 'English',
      profilePicture: '',
      isChecked: false,
    },
  })

  const phoneNoSendOtpFormDefault = {
    newContact: '',
    contactType: contactType.PHONE,
  }
  const phoneNoVerifyOtpFormDefault = {
    otp: '',
    contactType: contactType.PHONE,
  }
  const phoneNoSendOtpFormValues = useForm({
    defaultValues: phoneNoSendOtpFormDefault,
  })
  const phoneNoVerifyOtpFormValues = useForm({
    defaultValues: phoneNoVerifyOtpFormDefault,
  })

  const emailSendOtpFormDefault = {
    newContact: '',
    contactType: contactType.EMAIL,
  }
  const emailVerifyOtpFormDefault = {
    otp: '',
    contactType: contactType.EMAIL,
  }
  const emailSendOtpFormValues = useForm({
    defaultValues: emailSendOtpFormDefault,
  })
  const emailVerifyOtpFormValues = useForm({
    defaultValues: emailVerifyOtpFormDefault,
  })

  const validatePhoneNo = useCallback((value: string) => {
    return isSLMobilePhoneNumber(value.trim()) || PHONE_INVALID
  }, [])
  const validateEmail = useCallback((value: string) => {
    return isEmail(value.trim()) || EMAIL_INVALID
  }, [])

  useEffect(() => {
    setErrorNewPhoneNo(null)
    setSuccessNewPhoneNo(null)
    setValidatingNewPhoneNo(null)
    setIsNewPhoneNoVerified(false)
  }, [
    phoneNoVerifyOtpFormValues.formState.errors.otp,
    phoneNoSendOtpFormValues.formState.errors.newContact,
  ])

  useEffect(() => {
    setErrorNewEmail(null)
    setSuccessNewEmail(null)
    setValidatingNewEmail(null)
    setIsNewEmailVerified(false)
  }, [
    emailSendOtpFormValues.formState.errors.newContact,
    emailVerifyOtpFormValues.formState.errors.otp,
  ])

  const onSubmitPhoneNoSendOtp = async (data: any) => {
    const { newContact, contactType } = data
    setErrorNewPhoneNo(null)
    setSuccessNewPhoneNo(null)
    setIsNewPhoneNoVerified(false)
    const phoneNumber = convertNumberToSriLankaNumber(newContact)
    try {
      // Send the OTP to verify the contact
      const sendOtpResult = await ValidateContactOtpGenerateOboarding(
        {
          phoneNo: phoneNumber,
        },
        contactType,
      )

      setSuccessNewPhoneNo(sendOtpResult.message)
      setValidatingNewPhoneNo(newContact)

      setTimeout(() => {
        setResendPhoneNoOtp(true)
      }, 10000)
    } catch (error) {
      if (error instanceof Error) setErrorNewPhoneNo(error.message)
    }
  }

  const onSubmitPhoneNoVerifyOtp = async (data: any) => {
    const { otp, contactType } = data
    setErrorNewPhoneNo(null)
    setSuccessNewPhoneNo(null)
    setIsNewPhoneNoVerified(false)

    // Text field validation ensure the value. Just an additional check.
    if (!isString(otp) || isEmpty(otp)) {
      setErrorNewPhoneNo('OTP is required.')
      return
    }

    // Text field validation ensure the value. Just an additional check.
    if (!isString(validatingNewPhoneNo) || isEmpty(validatingNewPhoneNo)) {
      setErrorNewPhoneNo('OTP has not sent to your phone number yet.')
      return
    }

    const phoneNumber = convertNumberToSriLankaNumber(validatingNewPhoneNo)

    setValidatingNewPhoneNo(phoneNumber)

    try {
      const verifyOtpResult = await ValidateContactOtpVerifyOnboarding(
        {
          phoneNo: phoneNumber,
          otp: otp,
        },
        contactType,
      )
      setPhoneNoVerificationId(verifyOtpResult._id)
      setIsNewPhoneNoVerified(true)
    } catch (error) {
      if (error instanceof Error) setErrorNewPhoneNo(error.message)
    }
  }

  const onSubmitEmailSendOtp = async (data: any) => {
    const { newContact, contactType } = data
    setErrorNewEmail(null)
    setSuccessNewEmail(null)
    setIsNewEmailVerified(false)
    try {
      // Send the OTP to verify the contact
      const sendOtpResult = await ValidateContactOtpGenerateOboarding(
        {
          newEmail: newContact,
        },
        contactType,
      )
      setSuccessNewEmail(sendOtpResult.message)
      setValidatingNewEmail(newContact)
      setTimeout(() => {
        setResendEmailOtp(true)
      }, 10000)
    } catch (error) {
      if (error instanceof Error) setErrorNewEmail(error.message)
    }
  }

  const onSubmitEmailVerifyOtp = async (data: any) => {
    const { otp, contactType } = data

    setErrorNewEmail(null)
    setSuccessNewEmail(null)
    setIsNewEmailVerified(false)

    // Text field validation ensure the value. Just an additional check.
    if (!isString(otp) || isEmpty(otp)) {
      setErrorNewEmail('OTP is required.')
      return
    }

    // Text field validation ensure the value. Just an additional check.
    if (!isString(validatingNewEmail) || isEmpty(validatingNewEmail)) {
      setErrorNewEmail('OTP has not sent to your email address yet.')

      return
    }

    try {
      const verifyOtpResult = await ValidateContactOtpVerifyOnboarding(
        {
          newEmail: validatingNewEmail,
          otp: otp,
        },
        contactType,
      )
      setEmailVerificationId(verifyOtpResult._id)
      setIsNewEmailVerified(true)
    } catch (error) {
      if (error instanceof Error) setErrorNewEmail(error.message)
    }
  }

  useEffect(() => {
    if (profilePicFile !== null) {
      setProfilePicture(URL.createObjectURL(new Blob([profilePicFile])))
    }
  }, [profilePicFile])

  const removeImage = () => {
    setProfilePicture('')
    setProfilePicFile(null)
  }

  const validateProfilePicture = (file: File): boolean => {
    const validProfilePictureFormat = validateProfilePictureFormat(file?.name)
    if (!validProfilePictureFormat) {
      toast({
        title: '',
        description: 'Profile Picture can be only of types png, jpg, jpeg',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    const isImgSizeValid = validateImageSize(file, 2000000)
    if (!isImgSizeValid) {
      toast({
        title: '',
        description: 'Image has to be less than 2MB',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    return true
  }

  const titlesDropdownData: DropdownData[] = []

  const mapDataToDropDown = () => {
    data?.map((value) => {
      titlesDropdownData.push({
        value: `${value?._id}/${value?.gender}/${value?.title}`,
        label: value?.title,
      })
    })
  }

  mapDataToDropDown()

  const enteredTitle = mainFormValues.watch('title')

  useEffect(() => {
    const titleIdArray = enteredTitle?.split('/')
    mainFormValues.setValue('gender', titleIdArray[1])
    mainFormValues.clearErrors('gender')
  }, [enteredTitle])

  const uploadToPresginedUrl = async (url: string) => {
    const headers = new Headers({ 'Content-Type': '*' })
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: profilePicFile,
    })
  }

  const profilePicUpload = async () => {
    const profilePicParams = {
      fileName: profilePicFile?.name,
      fileType: pictureFileType,
    }

    const uploadImage = await ApiService.post(
      `public/users/profileImage`,
      profilePicParams,
    )

    if (uploadImage.status === 200) {
      toast({
        title: '',
        description: 'Profile Picture uploaded',
        duration: 5000,
        isClosable: true,
        status: 'success',
        position: 'top-right',
      })
    } else {
      toast({
        title: '',
        description: 'Something went wrong',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
    }

    if (uploadImage) {
      await uploadToPresginedUrl(uploadImage?.data?.presignedPostUrl)
    }

    return uploadImage?.data?.randomizedImageId
  }

  const onSubmitMain = async (data: any) => {
    let logoKey = ''
    if (profilePicture) {
      logoKey = await profilePicUpload()
      submitData(data, logoKey)
      //console.log('UploadImage', uploadImage)
    } else {
      submitData(data)
    }
  }

  const submitData = (data: any, profPicture?: string) => {
    const titleIdArray = enteredTitle?.split('/')

    const publicUserParams: PublicUserRequestBody = {
      username: loggedUserName,
      iamId: loggedUserId,
      //title: data.title,
      title: titleIdArray[2],
      gender: data.gender,
      firstName: data.firstName,
      lastName: data.lastName,
      nameInitials: data.nameWithInitials,
      identificationType: data.identificationType,
      nicNo: '',
      passportNo: '',
      passportCountry: '',
      drivingLicenseNo: '',
      preferredLanguage: data.preferredLanguage ? data.preferredLanguage : '',
      contact: isNewPhoneNoVerified ? validatingNewPhoneNo : '',
      email: emailAddress
        ? emailAddress
        : isNewEmailVerified
        ? validatingNewEmail
        : '',
      signupSource: loggedUserSignupSource,
      profPicture: profPicture ? profPicture : '',
      phoneNoVerificationId: phoneNoVerificationId,
      emailVerificationId: emailVerificationId ? emailVerificationId : '',
    }

    if (emailAddress === '' && !isNewEmailVerified) {
      delete publicUserParams['email']
      delete publicUserParams['emailVerificationId']
    }

    if (data.identificationType === 'P') {
      publicUserParams['passportNo'] = data.identificationNo
      publicUserParams['passportCountry'] = data.countryOfPassport
      delete publicUserParams['nicNo']
      delete publicUserParams['drivingLicenseNo']
    } else if (data.identificationType === 'N') {
      publicUserParams['nicNo'] = data.identificationNo
      delete publicUserParams['passportNo']
      delete publicUserParams['passportCountry']
      delete publicUserParams['drivingLicenseNo']
    } else if (data.identificationType === 'D') {
      publicUserParams['drivingLicenseNo'] = data.identificationNo
      delete publicUserParams['nicNo']
      delete publicUserParams['passportNo']
      delete publicUserParams['passportCountry']
    }

    // if (isNewPhoneNoVerified) publicUserParams.contact = validatingNewPhoneNo
    // if (isNewEmailVerified) publicUserParams.email = validatingNewEmail

    const requestBody = {
      user: publicUserParams,
    }

    ApiService.post(`/public/users/`, requestBody)
      .then((data: any) => {
        if (data.status === 200) {
          const userDetails = {
            ...wso2loggedUserDetails,
            _doc: data.data,
          }

          localStorage.setItem('user', JSON.stringify(userDetails))

          toast({
            title: '',
            description: 'User Profile Created Successfully',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          navigate('/citizen/dashboard')
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast.closeAll()
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  useEffect(() => {
    // Populate the current user details. Taking from the local store.
    handleGetUser()
  }, [])

  const handleGetUser = async () => {
    // Find logged in user
    const loggedUser = localStorage.getItem('user')
    if (isString(loggedUser) && !isEmpty(loggedUser)) {
      const loggedUserDetails = JSON.parse(loggedUser)
      setwso2loggedUserDetails(loggedUserDetails)
      //Set IamId as loggedin Id
      if (loggedUserDetails?.iamId) {
        setLoggedUserId(loggedUserDetails?.iamId)
      }
      if (loggedUserDetails?.signupSource) {
        setloggedUserSignupSource(loggedUserDetails?.signupSource)

        //Setting Email for Facebook / Google Signup users
        if (
          loggedUserDetails?.signupSource === 'google' ||
          loggedUserDetails?.signupSource === 'facebook'
        ) {
          emailSendOtpFormValues.setValue(
            'newContact',
            loggedUserDetails?.email ? loggedUserDetails?.email : '',
          )
          setEmailAddress(loggedUserDetails?.email)
        } else {
          emailSendOtpFormValues.setValue('newContact', '')
        }
      }

      if (loggedUserDetails?._doc?._id) {
        setLoggedUserId(loggedUserDetails?._doc?._id)
      }

      //Setting Username
      if (loggedUserDetails?.username) {
        setLoggedUserName(loggedUserDetails?.username)
      } else if (loggedUserDetails?.email) {
        setLoggedUserName(loggedUserDetails?.email)
      }
    } else {
      toast.closeAll()
    }
  }

  const identificationType = mainFormValues.watch('identificationType')

  useEffect(() => {
    if (identificationType === 'P') {
      setIdType('P')
      setMaxLength(30)
      setMinLength(8)
      setIdNumberRegex(PASSPORT_REGEX)
    } else if (identificationType === 'N') {
      setIdType('N')
      setMaxLength(12)
      setMinLength(10)
      setIdNumberRegex(SL_NIC_REGEX)
    } else {
      setIdType('D')
      setMaxLength(8)
      setMinLength(7)
      setIdNumberRegex(SL_DRIVING_LICENSE_REGEX)
    }
    mainFormValues.setValue('identificationNo', '')
  }, [identificationType])

  const messages: any = {
    en: {
      personalDetails: 'Personal Details',
      title: 'Title:*',
      mr: 'Mr',
      ms: 'Ms',
      gender: 'Gender:*',
      firstName: 'First Name:*',
      lastName: 'Last Name:*',
      namewithInitials: 'Name with Initials:*',
      identificationType: 'Identification Type:*',
      identificationNumber: 'Identification Number:*',
      countryofPassport: 'Country of Passport:*',
      preferredLanguage: 'Preferred Language:',
      sinala: 'Sinhala',
      tamil: 'Tamil',
      male: 'Male',
      female: 'Female',
      nic: 'NIC',
      passport: 'Passport',
      drivingLicense: 'Driving License',
      profilePicture: 'Profile Picture',
      contactDetails: 'Contact Details',
      phoneNumber: 'Phone Number:*',
      emailAddress: 'Email Address:',
      resendOTP: 'Resend OTP',
      generateOTP: 'Generate OTP',
      enterOTP: 'Enter OTP',
      verify: 'Verify',
      verified: 'Verified',
      termsConditions: 'I Read & Agree to Terms & Conditions.',
      haveAQuestion: 'Have A Question?',
      continue: 'Continue',
      cancel: 'Cancel',
      confirm: 'Confirm',
      areyousureyouwanttocancel: 'Are you sure you want to cancel?',
      yes: 'Yes',
      no: 'No',
    },
    si: {
      personalDetails: 'පුද්ගලික තොරතුරු',
      title: 'මාතෘකාව:*',
      mr: 'මහතා.',
      ms: 'මෙනෙවිය.',
      gender: 'ස්ත්‍රි පුරුෂ බාවය:*',
      firstName: 'මුල් නම:*',
      lastName: 'අවසන් නම:*',
      namewithInitials: 'මුලකුරු සමඟ නම:*',
      identificationType: 'අනන්‍යතාවයේ වර්ගය:*',
      identificationNumber: 'හඳුනාගැනීමේ අංකය:*',
      countryofPassport: 'විදේශ ගමන් බලපත්‍රය නිකුත් කල රට:*',
      preferredLanguage: 'කැමති භාෂාව:',
      sinala: 'සිංහල',
      tamil: 'දෙමළ',
      male: 'පිරිමි',
      female: 'ගැහැණු',
      nic: 'ජාතික හැඳුනුම්පත',
      passport: 'ගමන් බලපත්ර',
      drivingLicense: 'රියදුරු බලපත්රය',
      profilePicture: 'පැතිකඩ පින්තූරය',
      contactDetails: 'ඇමතුම් විස්තර',
      phoneNumber: 'දුරකථන අංකය:*',
      emailAddress: 'විද්යුත් තැපැල් ලිපිනය:',
      resendOTP: 'OTP නැවත යවන්න',
      generateOTP: 'OTP ජනනය කරන්න',
      enterOTP: 'OTP ඇතුලත් කරන්න',
      verify: 'තහවුරු කරන්න',
      verified: 'තහවුරු කර ඇත',
      termsConditions: 'මම නියම සහ කොන්දේසි කියවා එකඟ වෙමි.',
      haveAQuestion: 'ප්රශ්නයක් තිබේද?',
      continue: 'දිගටම',
      cancel: 'අවලංගු කරන්න',
      confirm: 'තහවුරු කරන්න',
      areyousureyouwanttocancel: 'ඔබට අවලංගු කිරීමට අවශ්‍ය බව විශ්වාසද?',
      yes: 'ඔව්',
      no: 'නැත',
    },
    ta: {
      personalDetails: 'தனிப்பட்ட விவரங்கள்',
      title: 'தலைப்பு:*',
      ms: 'செல்வி.',
      gender: 'பாலினம்:*',
      firstName: 'முதல் பெயர்:*',
      lastName: 'கடைசி பெயர்:*',
      namewithInitials: 'முதலெழுத்துக்களுடன் பெயர்:*',
      identificationType: 'அடையாள வகை:*',
      identificationNumber: 'அடையாள எண்:*',
      countryofPassport: 'பாஸ்போர்ட் நாடு:*',
      preferredLanguage: 'விருப்பமான மொழி:',
      sinala: 'சிங்களம்',
      tamil: 'தமிழ்',
      male: 'ஆண்',
      female: 'பெண்',
      nic: 'தேசிய அடையாள அட்டை',
      passport: 'கடவுச்சீட்டு',
      drivingLicense: 'ஓட்டுநர் உரிமம்',
      profilePicture: 'சுயவிவர படம்',
      contactDetails: 'தொடர்பு விபரங்கள்',
      phoneNumber: 'தொலைபேசி எண்:*',
      emailAddress: 'மின்னஞ்சல் முகவரி:',
      resendOTP: 'OTP ஐ மீண்டும் அனுப்பவும்',
      generateOTP: 'OTP ஐ உருவாக்கவும்',
      enterOTP: 'OTP ஐ உள்ளிடவும்',
      verify: 'சரிபார்க்கவும்',
      verified: 'சரிபார்க்கப்பட்டது',
      termsConditions:
        'விதிமுறைகள் மற்றும் நிபந்தனைகளை நான் படித்து ஏற்றுக்கொள்கிறேன்.',
      haveAQuestion: 'ஒரு கேள்வி இருக்கிறதா?',
      continue: 'தொடரவும்',
      cancel: 'ரத்து செய்',
      confirm: 'உறுதிப்படுத்தவும்',
      areyousureyouwanttocancel: 'நிச்சயமாக ரத்துசெய்ய விரும்புகிறீர்களா?',
      yes: 'ஆம்',
      no: 'இல்லை',
    },
  }

  const mapDataToTitleDropdown = (array: any[]) => {
    const titleData: ComboboxItem<string>[] = []
    array.map((value, index) => {
      const obj = {
        key: value._id,
        value: value._id,
        label: value.title,
        gender: value.gender,
      }
      titleData.push(obj)
    })
    setTitleDropdownData(titleData)
    //setTitleArray(titleData)
  }

  return (
    <>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Box bg={'gray.50'} p={10}>
          <Box height="40px">
            <FormLabel as="h6" htmlFor="email" mb="8px">
              Welcome to Evo Forms
            </FormLabel>
          </Box>
          <Box height="40px">
            <FormLabel
              as="h2"
              fontWeight="700"
              fontSize="22px"
              htmlFor="email"
              mb="8px"
            >
              Tell us about yourself
            </FormLabel>
          </Box>
          <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
            <Box bg={'gray.100'} p={10} mt={5} pb={isMobile ? 320 : 10}>
              <Box height="40px">
                <Heading as="h4" size="md" color="#e17d0e">
                  <FormattedMessage
                    id="personalDetails"
                    values={{ locale }}
                  ></FormattedMessage>
                </Heading>
              </Box>
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
                <SimpleGrid gap={6}>
                  <SimpleGrid columns={{ sm: 1, md: 1, lg: 3 }} spacing="20px">
                    <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                      <Box height={'70px'}>
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.title
                              ? true
                              : false
                          }
                        >
                          <ChakraFormLabel htmlFor="title" mb="8px">
                            <FormattedMessage
                              id="title"
                              values={{ locale }}
                            ></FormattedMessage>
                          </ChakraFormLabel>
                          <Controller
                            name="title"
                            control={mainFormValues.control}
                            render={({ field }) => (
                              <SingleSelect
                                {...field}
                                items={titlesDropdownData}
                              />
                            )}
                            rules={{ required: 'Title is required' }}
                          />
                          {mainFormValues.formState.errors.title && (
                            <FormErrorMessage>
                              {mainFormValues.formState.errors.title.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                    </SimpleGrid>

                    <Box height={'70px'}>
                      <FormControl
                        isInvalid={
                          mainFormValues?.formState?.errors?.firstName
                            ? true
                            : false
                        }
                      >
                        <ChakraFormLabel htmlFor="firstName" mb="8px">
                          <FormattedMessage
                            id="firstName"
                            values={{ locale }}
                          ></FormattedMessage>
                        </ChakraFormLabel>
                        <Input
                          placeholder={
                            locale === 'en'
                              ? 'First Name'
                              : locale === 'si'
                              ? 'මුල් නම'
                              : 'முதல் பெயர்'
                          }
                          id="firstName"
                          maxLength={100}
                          {...mainFormValues.register('firstName', {
                            required: 'First Name is required',
                            maxLength: {
                              value: 100,
                              message: 'Max length is 100',
                            },
                          })}
                        />
                        {mainFormValues.formState.errors.firstName && (
                          <FormErrorMessage>
                            {mainFormValues.formState.errors.firstName.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>

                    <Box height={'70px'}>
                      <FormControl
                        isInvalid={
                          mainFormValues?.formState?.errors?.lastName
                            ? true
                            : false
                        }
                      >
                        <ChakraFormLabel htmlFor="lastName" mb="8px">
                          <FormattedMessage
                            id="lastName"
                            values={{ locale }}
                          ></FormattedMessage>
                        </ChakraFormLabel>
                        <Input
                          placeholder={
                            locale === 'en'
                              ? 'Last Name'
                              : locale === 'si'
                              ? 'අවසන් නම'
                              : 'கடைசி பெயர்'
                          }
                          id="lastName"
                          maxLength={100}
                          {...mainFormValues.register('lastName', {
                            required: 'Last Name is required',
                            maxLength: {
                              value: 100,
                              message: 'Max length is 100',
                            },
                          })}
                        />
                        {mainFormValues.formState.errors.lastName && (
                          <FormErrorMessage>
                            {mainFormValues.formState.errors.lastName.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </SimpleGrid>

                  <Box height={'70px'}>
                    <FormControl
                      isInvalid={
                        mainFormValues?.formState?.errors?.nameWithInitials
                          ? true
                          : false
                      }
                    >
                      <ChakraFormLabel htmlFor="nameWithInitials" mb="8px">
                        <FormattedMessage
                          id="namewithInitials"
                          values={{ locale }}
                        ></FormattedMessage>
                      </ChakraFormLabel>
                      <Input
                        placeholder={
                          locale === 'en'
                            ? 'Name with Initials'
                            : locale === 'si'
                            ? 'මුලකුරු සමඟ නම'
                            : 'முதலெழுத்துக்களுடன் பெயர்'
                        }
                        id="nameWithInitials"
                        maxLength={100}
                        {...mainFormValues.register('nameWithInitials', {
                          required: 'Name with Initials is required',
                          maxLength: {
                            value: 100,
                            message: 'Max length is 100',
                          },
                        })}
                      />
                      {mainFormValues.formState.errors.nameWithInitials && (
                        <FormErrorMessage>
                          {
                            mainFormValues.formState.errors.nameWithInitials
                              .message
                          }
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Box>

                  <SimpleGrid columns={{ sm: 1, md: 1, lg: 2 }} spacing="40px">
                    <Box height={'70px'}>
                      <FormControl
                        isInvalid={
                          mainFormValues?.formState?.errors?.gender
                            ? true
                            : false
                        }
                      >
                        <ChakraFormLabel htmlFor="gender" mb="8px">
                          <FormattedMessage
                            id="gender"
                            values={{ locale }}
                          ></FormattedMessage>
                        </ChakraFormLabel>
                        <Controller
                          name="gender"
                          control={mainFormValues.control}
                          render={({ field }) => (
                            <SingleSelect
                              {...field}
                              items={[
                                {
                                  value: 'M',
                                  label:
                                    locale === 'en'
                                      ? 'Male'
                                      : locale === 'si'
                                      ? 'පිරිමි'
                                      : 'ஆண்',
                                },
                                {
                                  value: 'F',
                                  label:
                                    locale === 'en'
                                      ? 'Female'
                                      : locale === 'si'
                                      ? 'ගැහැණු'
                                      : 'பெண்',
                                },
                              ]}
                            />
                          )}
                          rules={{ required: 'Gender is required' }}
                        />
                        {mainFormValues.formState.errors.gender && (
                          <FormErrorMessage>
                            {mainFormValues.formState.errors.gender.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>

                    <Box height={'70px'}>
                      <FormControl
                        isInvalid={
                          mainFormValues?.formState?.errors?.preferredLanguage
                            ? true
                            : false
                        }
                      >
                        <ChakraFormLabel htmlFor="preferredLanguage" mb="8px">
                          <FormattedMessage
                            id="preferredLanguage"
                            values={{ locale }}
                          ></FormattedMessage>
                        </ChakraFormLabel>
                        <Controller
                          name="preferredLanguage"
                          control={mainFormValues.control}
                          render={({ field }) => (
                            <SingleSelect
                              {...field}
                              items={[
                                {
                                  value: 'English',
                                  label: 'English',
                                },
                                {
                                  value: 'Sinhala',
                                  label: 'සිංහල',
                                },
                                {
                                  value: 'Tamil',
                                  label: 'தமிழ்',
                                },
                              ]}
                            />
                          )}
                        />
                        {mainFormValues.formState.errors.preferredLanguage && (
                          <FormErrorMessage>
                            {
                              mainFormValues.formState.errors.preferredLanguage
                                .message
                            }
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </SimpleGrid>

                  <SimpleGrid columns={{ sm: 1, md: 1, lg: 2 }} spacing="40px">
                    <Box height={'70px'}>
                      <FormControl
                        isInvalid={
                          mainFormValues?.formState?.errors?.identificationType
                            ? true
                            : false
                        }
                      >
                        <ChakraFormLabel htmlFor="identificationType" mb="8px">
                          <FormattedMessage
                            id="identificationType"
                            values={{ locale }}
                          ></FormattedMessage>
                        </ChakraFormLabel>
                        <Controller
                          name="identificationType"
                          control={mainFormValues.control}
                          render={({ field }) => (
                            <SingleSelect
                              {...field}
                              items={[
                                {
                                  value: 'N',
                                  label:
                                    locale === 'en'
                                      ? 'NIC'
                                      : locale === 'si'
                                      ? 'ජාතික හැඳුනුම්පත'
                                      : 'தேசிய அடையாள அட்டை',
                                },
                                {
                                  value: 'P',
                                  label:
                                    locale === 'en'
                                      ? 'Passport'
                                      : locale === 'si'
                                      ? 'ගමන් බලපත්ර'
                                      : 'கடவுச்சீட்டு',
                                },
                                {
                                  value: 'D',
                                  label:
                                    locale === 'en'
                                      ? 'Driving License'
                                      : locale === 'si'
                                      ? 'රියදුරු බලපත්රය'
                                      : 'ஓட்டுநர் உரிமம்',
                                },
                              ]}
                            />
                          )}
                          rules={{
                            required: 'Identification Type is required',
                          }}
                        />
                        {mainFormValues.formState.errors.identificationType && (
                          <FormErrorMessage>
                            {
                              mainFormValues.formState.errors.identificationType
                                .message
                            }
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>

                    <Box height={'70px'}>
                      <FormControl
                        isInvalid={
                          mainFormValues?.formState?.errors?.identificationNo
                            ? true
                            : false
                        }
                      >
                        <ChakraFormLabel htmlFor="identificationNo" mb="8px">
                          <FormattedMessage
                            id="identificationNumber"
                            values={{ locale }}
                          ></FormattedMessage>
                        </ChakraFormLabel>
                        <Input
                          placeholder={
                            locale === 'en'
                              ? 'Identification Number'
                              : locale === 'si'
                              ? 'හඳුනාගැනීමේ අංකය'
                              : 'அடையாள எண்'
                          }
                          id="identificationNo"
                          {...mainFormValues.register('identificationNo', {
                            required: 'Identification Number is required',
                            maxLength: {
                              value: maxLength,
                              message: `Max length is ${maxLength}`,
                            },
                            minLength: {
                              value: minLength,
                              message: `Min length is ${minLength}`,
                            },
                            pattern: {
                              value: idNumberRegex,
                              message: 'Invalid Identification Number',
                            },
                          })}
                        />
                        {mainFormValues.formState.errors.identificationNo && (
                          <FormErrorMessage>
                            {
                              mainFormValues.formState.errors.identificationNo
                                .message
                            }
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    {idType === 'P' && (
                      <Box height={'70px'}>
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.countryOfPassport
                              ? true
                              : false
                          }
                        >
                          <ChakraFormLabel htmlFor="countryOfPassport" mb="8px">
                            <FormattedMessage
                              id="countryofPassport"
                              values={{ locale }}
                            ></FormattedMessage>
                          </ChakraFormLabel>
                          <Controller
                            name="countryOfPassport"
                            control={mainFormValues.control}
                            render={({ field }) => (
                              <SingleSelect
                                {...field}
                                items={[
                                  {
                                    value: 'SRI_LANKA',
                                    label:
                                      locale === 'en'
                                        ? 'Sri Lanka'
                                        : locale === 'si'
                                        ? 'ශ්රී ලංකාව'
                                        : 'இலங்கை',
                                  },
                                ]}
                              />
                            )}
                            rules={{
                              required: 'Country of Passport is required',
                            }}
                          />
                          {mainFormValues.formState.errors
                            .countryOfPassport && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors
                                  .countryOfPassport.message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                    )}
                  </SimpleGrid>
                </SimpleGrid>

                <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
                  <Box height="80px" pt={5}>
                    <ChakraFormLabel mb="8px">
                      <FormattedMessage
                        id="profilePicture"
                        values={{ locale }}
                      ></FormattedMessage>
                    </ChakraFormLabel>
                    <Stack direction="row" spacing="0.5rem">
                      <IconButton
                        variant="outline"
                        aria-label="Profile picture upload"
                        icon={<BiUpload />}
                        mb={5}
                        ml={5}
                        onClick={() => imageUploadRef.current?.click()}
                      />
                      <IconButton
                        variant="outline"
                        aria-label="Profile picture upload"
                        icon={<BiMessageSquareX />}
                        mb={5}
                        disabled={profilePicture === ''}
                        onClick={removeImage}
                      />
                    </Stack>
                    <Input
                      name="profilePicture"
                      type="file"
                      ref={imageUploadRef}
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(e) => {
                        if (!e.target.files) return
                        const validProfilePictureFormat =
                          validateProfilePicture(e?.target?.files[0])
                        if (!validProfilePictureFormat) {
                          return
                        }
                        setProfilePicFile(e?.target?.files[0])
                        setPictureFileType(e?.target?.files[0].type)
                        if (imageUploadRef.current) {
                          imageUploadRef.current.value = ''
                        }
                      }}
                      display="none"
                    />

                    <Box bg={'gray.400'} p={5} h="250px" w="200px">
                      {profilePicture !== '' ? (
                        <Image
                          h="200px"
                          w="150px"
                          m={'2px'}
                          src={profilePicture}
                          alt=""
                        />
                      ) : null}
                    </Box>
                  </Box>
                </SimpleGrid>
              </SimpleGrid>
            </Box>
          </form>

          {/* </Box> */}
          <br />
          {/* borderRadius="5" border="1px" borderColor="black.200" */}
          <br />
          <Box bg={'gray.200'} p={5} pb={20}>
            <Box height="40px">
              <Heading as="h4" size="md" color="#e17d0e">
                <FormattedMessage
                  id="contactDetails"
                  values={{ locale }}
                ></FormattedMessage>
              </Heading>
            </Box>

            <Box marginLeft="-10px">
              {/* <Stack direction={['column', 'row']}> */}
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
                <form
                  onSubmit={phoneNoSendOtpFormValues.handleSubmit(
                    onSubmitPhoneNoSendOtp,
                  )}
                >
                  <Stack direction={['column', 'row']} spacing="13px" p={3}>
                    <Box>
                      <FormControl
                        isInvalid={
                          !!phoneNoSendOtpFormValues.formState.errors.newContact
                        }
                      >
                        <ChakraFormLabel htmlFor="newContact" mb="8px">
                          <FormattedMessage
                            id="phoneNumber"
                            values={{ locale }}
                          ></FormattedMessage>
                        </ChakraFormLabel>
                        <InputGroup size="sm">
                          <Input
                            placeholder="e.g. 0701234567"
                            id="newContact"
                            type="tel"
                            //pattern="+94[0-9]{9}"
                            maxLength={MAX_PHONE_LENGTH} // To allow if the saved value is with different country code
                            autoComplete="newContact"
                            {...phoneNoSendOtpFormValues.register(
                              'newContact',
                              {
                                ...PHONE_VALIDATION_RULES,
                                validate: handlePhoneValidation,
                              },
                            )}
                            onChange={(e) => {
                              const value = e.target.value
                              // value = `+94${trimStart(value, '+94')}`

                              setNewPhoneNumber({
                                newContact: e.target.value,
                                contactType: contactType.PHONE,
                              })
                              return (e.target.value = value)
                            }}
                          />
                        </InputGroup>
                        {phoneNoSendOtpFormValues.formState.errors
                          .newContact && (
                          <FormErrorMessage>
                            {
                              phoneNoSendOtpFormValues.formState.errors
                                .newContact.message
                            }
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>

                    <Box>
                      <ButtonGroup
                        variant="outline"
                        spacing="1"
                        size="sm"
                        marginRight="10px"
                      >
                        <Button type="submit" mt={8}>
                          {resendPhoneNoOtp ? (
                            <FormattedMessage
                              id="resendOTP"
                              values={{ locale }}
                            ></FormattedMessage>
                          ) : (
                            <FormattedMessage
                              id="generateOTP"
                              values={{ locale }}
                            ></FormattedMessage>
                          )}
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Stack>
                </form>
                <form
                  onSubmit={phoneNoVerifyOtpFormValues.handleSubmit(
                    onSubmitPhoneNoVerifyOtp,
                  )}
                >
                  <Stack direction={['column', 'row']} spacing="17px" p={3}>
                    <Box>
                      <FormControl
                        isInvalid={
                          !!phoneNoVerifyOtpFormValues.formState.errors.otp
                        }
                      >
                        <ChakraFormLabel htmlFor="otp" mb="8px">
                          <FormattedMessage
                            id="enterOTP"
                            values={{ locale }}
                          ></FormattedMessage>
                        </ChakraFormLabel>
                        <InputGroup size="sm">
                          <Input
                            placeholder="e.g. 123456"
                            id="otp"
                            type="text"
                            maxLength={MAX_OTP_LENGTH}
                            autoComplete="otp"
                            {...phoneNoVerifyOtpFormValues.register(
                              'otp',
                              OTP_VALIDATION_RULES,
                            )}
                          />
                        </InputGroup>
                        {phoneNoVerifyOtpFormValues.formState.errors.otp && (
                          <FormErrorMessage>
                            {
                              phoneNoVerifyOtpFormValues.formState.errors.otp
                                .message
                            }
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                    <Box>
                      <ButtonGroup
                        variant="outline"
                        spacing="1"
                        size="sm"
                        marginRight="10px"
                      >
                        <Button type="submit" mt={8}>
                          <FormattedMessage
                            id="verify"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Button>
                      </ButtonGroup>
                    </Box>
                    <Box>
                      <FormControl>
                        {isNewPhoneNoVerified && (
                          <FormFieldMessage variant="success">
                            <FormattedMessage
                              id="verified"
                              values={{ locale }}
                            ></FormattedMessage>
                          </FormFieldMessage>
                        )}
                      </FormControl>
                    </Box>
                  </Stack>
                </form>
              </SimpleGrid>
              {/* </Stack> */}

              <Stack direction={['column', 'row']} spacing="26px" p={3}>
                <Box>
                  <FormControl isInvalid={errorNewPhoneNo !== null}>
                    <FormErrorMessage>{errorNewPhoneNo}</FormErrorMessage>
                    {successNewPhoneNo !== null && (
                      <FormFieldMessage variant="success">
                        {successNewPhoneNo}
                      </FormFieldMessage>
                    )}
                  </FormControl>
                </Box>
              </Stack>

              {/* <Stack direction={['column', 'row']}> */}
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
                {
                  // (loggedUserSignupSource === 'google' ||
                  //   loggedUserSignupSource === 'facebook')
                  emailAddress && (
                    <div style={{ display: 'flex', marginLeft: 3 }}>
                      <dd
                        style={{
                          flex: '60%',
                          padding: '10px',
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage
                          id="emailAddress"
                          values={{ locale }}
                        ></FormattedMessage>
                      </dd>
                      <dt
                        style={{
                          flex: '50%',
                          padding: '10px',
                          maxWidth: '100%',
                        }}
                      >
                        {emailAddress}
                      </dt>
                    </div>
                  )
                }

                {!emailAddress && (
                  <form
                    onSubmit={emailSendOtpFormValues.handleSubmit(
                      onSubmitEmailSendOtp,
                    )}
                  >
                    <Stack direction={['column', 'row']} spacing="13px" p={3}>
                      <Box>
                        <FormControl
                          isInvalid={
                            !!emailSendOtpFormValues.formState.errors.newContact
                          }
                        >
                          <ChakraFormLabel htmlFor="newContact" mb="8px">
                            <FormattedMessage
                              id="emailAddress"
                              values={{ locale }}
                            ></FormattedMessage>
                          </ChakraFormLabel>
                          <InputGroup size="sm">
                            <Input
                              placeholder="e.g. sample@email.com"
                              id="newContact"
                              type="text"
                              maxLength={MAX_EMAIL_LENGTH}
                              autoComplete="newContact"
                              // readOnly={
                              //   loggedUserSignupSource === 'google' ||
                              //   loggedUserSignupSource === 'facebook'
                              //     ? true
                              //     : false
                              // }
                              {...emailSendOtpFormValues.register(
                                'newContact',
                                {
                                  ...Email_VALIDATION_RULES,
                                  validate: validateEmail,
                                },
                              )}
                            />
                          </InputGroup>
                          {emailSendOtpFormValues.formState.errors
                            .newContact && (
                            <FormErrorMessage>
                              {
                                emailSendOtpFormValues.formState.errors
                                  .newContact.message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box>
                        <ButtonGroup
                          variant="outline"
                          spacing="1"
                          size="sm"
                          marginRight="10px"
                        >
                          <Button type="submit" mt={8}>
                            {resendEmailOtp ? (
                              <FormattedMessage
                                id="resendOTP"
                                values={{ locale }}
                              ></FormattedMessage>
                            ) : (
                              <FormattedMessage
                                id="generateOTP"
                                values={{ locale }}
                              ></FormattedMessage>
                            )}
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Stack>
                  </form>
                )}
                {!emailAddress && (
                  <form
                    onSubmit={emailVerifyOtpFormValues.handleSubmit(
                      onSubmitEmailVerifyOtp,
                    )}
                  >
                    <Stack direction={['column', 'row']} spacing="17px" p={3}>
                      <Box>
                        <FormControl
                          isInvalid={
                            !!emailVerifyOtpFormValues.formState.errors.otp
                          }
                        >
                          <ChakraFormLabel htmlFor="otp" mb="8px">
                            <FormattedMessage
                              id="enterOTP"
                              values={{ locale }}
                            ></FormattedMessage>
                          </ChakraFormLabel>
                          <InputGroup size="sm">
                            <Input
                              placeholder="e.g. 123456"
                              id="otp"
                              type="text"
                              maxLength={MAX_OTP_LENGTH}
                              autoComplete="otp"
                              {...emailVerifyOtpFormValues.register(
                                'otp',
                                OTP_VALIDATION_RULES,
                              )}
                            />
                          </InputGroup>
                          {emailVerifyOtpFormValues.formState.errors.otp && (
                            <FormErrorMessage>
                              {
                                emailVerifyOtpFormValues.formState.errors.otp
                                  .message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box>
                        <ButtonGroup
                          variant="outline"
                          spacing="1"
                          size="sm"
                          marginRight="10px"
                        >
                          <Button type="submit" mt={8}>
                            <FormattedMessage
                              id="verify"
                              values={{ locale }}
                            ></FormattedMessage>
                          </Button>
                        </ButtonGroup>
                      </Box>
                      <Box>
                        <FormControl>
                          {isNewEmailVerified && (
                            <FormFieldMessage variant="success">
                              <FormattedMessage
                                id="verified"
                                values={{ locale }}
                              ></FormattedMessage>
                            </FormFieldMessage>
                          )}
                        </FormControl>
                      </Box>
                    </Stack>
                  </form>
                )}
              </SimpleGrid>
              {/* </Stack> */}

              <Stack direction={['column', 'row']} spacing="26px" p={3}>
                <Box>
                  <FormControl isInvalid={errorNewEmail !== null}>
                    <FormErrorMessage>{errorNewEmail}</FormErrorMessage>
                    {successNewEmail !== null && (
                      <FormFieldMessage variant="success">
                        {successNewEmail}
                      </FormFieldMessage>
                    )}
                  </FormControl>
                </Box>
              </Stack>
            </Box>
          </Box>
          <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
            <Box height="70px" width="auto" mb="1.25rem" marginTop={'20px'}>
              <FormControl>
                {/* <CheckboxGroup> */}
                <Stack spacing={[1, 5]} direction={['column', 'row']}>
                  <Checkbox
                    {...mainFormValues.register('isChecked')}
                    // onChange={handleEmail}
                  >
                    {locale === 'en' ? (
                      <div>
                        <span>I Read & Agree to </span>
                        {
                          <Link as={ReactLink} to={TOC_ROUTE}>
                            Terms & Conditions.
                          </Link>
                        }
                      </div>
                    ) : locale === 'si' ? (
                      <div>
                        <span>මම </span>
                        {
                          <Link as={ReactLink} to={TOC_ROUTE}>
                            නියම සහ කොන්දේසි
                          </Link>
                        }
                        <span> කියවා එකඟ වෙමි.</span>
                      </div>
                    ) : (
                      <div>
                        {
                          <Link as={ReactLink} to={TOC_ROUTE}>
                            {' '}
                            விதிமுறைகள் மற்றும் நிபந்தனைகளை{' '}
                          </Link>
                        }
                        <span> நான் படித்து ஏற்றுக்கொள்கிறேன்.</span>
                      </div>
                    )}
                  </Checkbox>
                </Stack>
                {/* </CheckboxGroup> */}
              </FormControl>
            </Box>

            <Center></Center>
            <Flex>
              <Spacer />
              <Link as={ReactLink} to={UM_ROUTE} target="_blank">
                <FormattedMessage
                  id="haveAQuestion"
                  values={{ locale }}
                ></FormattedMessage>
              </Link>
            </Flex>
            <Center>
              <ButtonGroup variant="outline" spacing="3" padding={10}>
                <Button
                  type="submit"
                  disabled={
                    !(isNewPhoneNoVerified && mainFormValues.watch().isChecked)
                  }
                >
                  <FormattedMessage
                    id="continue"
                    values={{ locale }}
                  ></FormattedMessage>
                </Button>

                <Button
                  onClick={() => {
                    setIsOpen(true)
                    setClickedBtn('Cancel')
                  }}
                >
                  <FormattedMessage
                    id="cancel"
                    values={{ locale }}
                  ></FormattedMessage>
                </Button>
              </ButtonGroup>
            </Center>
          </form>
        </Box>
        {/* </IntlProvider> */}

        {isOpen ? (
          <Modal
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false)
              setClickedBtn('')
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader>
                <FormattedMessage
                  id="confirm"
                  values={{ locale }}
                ></FormattedMessage>
              </ModalHeader>
              <ModalBody whiteSpace="pre-line">
                {clickedBtn === 'Cancel' ? (
                  <FormattedMessage
                    id="areyousureyouwanttocancel"
                    values={{ locale }}
                  ></FormattedMessage>
                ) : (
                  ''
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      if (clickedBtn === 'Cancel') {
                        setClickedBtn('')
                        setIsOpen(false)
                        navigate('/welcome/public')
                      }
                    }}
                  >
                    <FormattedMessage
                      id="yes"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOpen(false)
                      setClickedBtn('')
                    }}
                  >
                    <FormattedMessage
                      id="no"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        ) : null}
      </IntlProvider>
    </>
  )
}
