import { Opaque, PartialDeep, RequireAtLeastOne } from 'type-fest'
import { z } from 'zod'
import { AgencyDto } from './agency'

import { ErrorDto } from './core'
import { FormFieldDto, MyInfoAttribute } from './field'
import {
  FormAuthType,
  FormLanguages,
  PaymentCharges,
  PaymentChargeType,
} from './form/form'
import { DateString } from './generic'
import { EmailResponse, FieldResponse, MobileResponse } from './response'

export type SubmissionId = Opaque<string, 'SubmissionId'>
export const SubmissionId = z.string() as unknown as z.Schema<SubmissionId>

export enum SubmissionType {
  Email = 'emailSubmission',
  Encrypt = 'encryptSubmission',
}

export enum SubmissionStatus {
  Draft = 'Draft',
  Unattented = 'UnAttended',
  InProgress = 'Inprogress',
  Rejected = 'Rejected',
  Completed = 'Completed',
  PaymentPending = 'PaymentPending',
  PaymentSuccess = 'PaymentSuccess',
  PaymentFailed = 'PaymentFailed',
}

export const SubmissionBase = z.object({
  form: z.string(),
  authType: z.nativeEnum(FormAuthType),
  myInfoFields: z.array(z.nativeEnum(MyInfoAttribute)).optional(),
  submissionType: z.nativeEnum(SubmissionType),
  agencyId: AgencyDto.shape._id,
  status: z.nativeEnum(SubmissionStatus),
  toBeArchivedAt: z.date(),
  archived: z.boolean(),
  archivedDate: z.date().optional(),
  lastRestoredAt: z.date().optional(),
  createdBy: z
    .object({
      userCategoryId: z.string(),
      userId: z.string(),
    })
    .optional(),
  submittedAt: z.date().optional(),
  acceptedAt: z.date().optional(),
  acceptedBy: z
    .object({
      userCategoryId: z.string(),
      userId: z.string(),
    })
    .optional(),
  acceptedNote: z.string().optional(),
  rejectedReason: z.string().optional(),
  rejectedAt: z.date().optional(),
  rejectedBy: z
    .object({
      userCategoryId: z.string(),
      userId: z.string(),
    })
    .optional(),
  rejectedNote: z.string().optional(),
  rejectedAttachment: z.string().optional(),
  completedAt: z.date().optional(),
  completedBy: z
    .object({
      userCategoryId: z.string(),
      userId: z.string(),
    })
    .optional(),
  completedNote: z.string().optional(),
  completedAttachment: z.string().optional(),
  processedDays: z.number().optional(),
  paymentRequired: z.boolean(),
  paymentAmount: z.number().optional(),
  paymentCharges: z
    .array(
      z.object({
        name: z.string(),
        type: z.nativeEnum(PaymentChargeType),
        amount: z.number(),
        required: z.boolean(),
      }),
    )
    .optional(),
  paymentRefunded: z.boolean().optional(),
  paymentRefundedAmount: z.number().optional(),
  paymentRefundedCharges: z
    .array(
      z.object({
        name: z.string(),
        type: z.string(),
        amount: z.number(),
      }),
    )
    .optional(),
  paymentRefundedAt: z.date().optional(),
  paymentRefundedBy: z
    .object({
      userCategoryId: z.string(),
      userId: z.string(),
    })
    .optional(),
  paymentRefundedNote: z.string().optional(),
  signature: z
    .object({
      signatureType: z.string(),
      encryptedSignatureValue: z.string().optional(),
    })
    .optional(),
  signatureMeta: z.string().optional(),
  signDate: z.date().optional(),
  serialNumber: z.string().optional(),
  formLanguage: z.array(z.nativeEnum(FormLanguages)),
  portalLanguage: z.enum(['Sinhala', 'Tamil', 'English']),
  submissionRetentionPeriod: z.object({
    years: z.number().optional(),
    months: z.number().optional(),
    days: z.number().optional(),
  }),
  resubmitted: z.boolean(),
  previousSubmissionId: z.string().optional(),
  deleted: z.boolean(),
  deletedDate: z.date().optional(),
  created: z.date().optional(),
  lastModified: z.date().optional(),
})
export type SubmissionBase = z.infer<typeof SubmissionBase>

/**
 * Email mode submission typings as stored in the database.
 */
export interface EmailModeSubmissionBase extends SubmissionBase {
  submissionType: SubmissionType.Email
  recipientEmails: string[]
  responseHash: string
  responseSalt: string
  hasBounced: boolean
}

export const WebhookResponse = z.object({
  webhookUrl: z.string(),
  signature: z.string(),
  response: z.object({
    status: z.number(),
    headers: z.string(),
    data: z.string(),
  }),
})

export type WebhookResponse = z.infer<typeof WebhookResponse>

/**
 * Storage mode submission typings as stored in the database.
 */

export const StorageModeSubmissionBase = SubmissionBase.extend({
  submissionType: z.literal(SubmissionType.Encrypt),
  encryptedContent: z.string(),
  verifiedContent: z.string().optional(),
  attachmentMetadata: z.map(z.string(), z.string()).optional(),
  version: z.number(),
  webhookResponses: z.array(WebhookResponse).optional(),
})
export type StorageModeSubmissionBase = z.infer<
  typeof StorageModeSubmissionBase
>

export type SubmissionPatchDto = PartialDeep<StorageModeSubmissionBase>

export type StorageModeSubmissionDto = {
  refNo: SubmissionId
  submissionTime: string
  content: string
  verified?: string
  attachmentMetadata: Record<string, string>
  version: number
  submissionStatus?: string
  paymentRefunded?: boolean
  agencyId?: string
  agencyName?: string
  formName?: string
  formDescription?: string
  formLanguage?: string
  submittedAt?: string
  submittedBy?: any
  submittedByWithoutTitle?: any
  paymentAmount?: number
  paymentRefundedAt?: string
  paymentRefundedBy?: any
  paymentRefundedNote?: string
  paymentRequired?: boolean
  paymentCharges?: PaymentCharges[]
  acceptedAt?: Date
  acceptedBy?: any
  acceptedNote?: string
  rejectedAt?: Date
  rejectedBy?: any
  rejectedReason?: string
  rejectedNote?: string
  rejectedAttachment?: string
  completedAt?: Date
  completedBy?: any
  completedNote?: string
  completedAttachment?: string
  signature?: { value: string; signatureType: string }
  serialNumber?: string
  archivedDate?: Date
  isFormArchived?: boolean
}

export type DraftDto = {
  submissionId: string
  createdAt: any
  formId: string
  formName: any
  description: any
  agencyId: string
  agencyName: any
}

export const StorageModeSubmissionStreamDto = StorageModeSubmissionBase.pick({
  encryptedContent: true,
  verifiedContent: true,
  version: true,
}).extend({
  attachmentMetadata: z.record(z.string()),
  _id: SubmissionId,
  created: DateString,
})

export type StorageModeSubmissionStreamDto = z.infer<
  typeof StorageModeSubmissionStreamDto
>

export type StorageModeSubmissionMetadata = {
  number: number
  refNo: SubmissionId
  /** Not a DateString, format is `Do MMM YYYY, h:mm:ss a` */
  submissionTime: string
}

export type StorageModeSubmissionMetadataList = {
  metadata: StorageModeSubmissionMetadata[]
  count: number
}

export type SubmissionResponseDto = {
  message: string
  submissionId: string
  status: string
  paymentRequired: boolean
  paymentCharges: any
  paymentAmount: number
}

export type SubmissionErrorDto = ErrorDto & { spcpSubmissionFailure?: true }

export type SubmissionCountQueryDto =
  | {
      startDate: DateString
      endDate: DateString
    }
  | undefined

export type FormSubmissionMetadataQueryDto = RequireAtLeastOne<
  {
    page: number
    submissionId: string
  },
  'page' | 'submissionId'
>

export type ReportQueryDto = RequireAtLeastOne<
  {
    formId: string
    createdFrom: Date
    createdTo: Date
    page?: number
    page_size?: number
    agencyId?: string
  },
  'formId' | 'createdFrom' | 'createdTo'
>

export type ProgressReportSubmissionsQueryDto = RequireAtLeastOne<
  {
    submittedFrom: Date
    submittedTo: Date
    status: string
    page?: number
    page_size?: number
  },
  'submittedFrom' | 'submittedTo' | 'status'
>

export type ArchivedReportQueryDto = RequireAtLeastOne<
  {
    agencyId: string
    formId: string
    createdFrom: Date
    createdTo: Date
    page: number
    page_size: number
  },
  'agencyId' | 'formId' | 'createdFrom' | 'createdTo'
>

export type ReconciliationReportQueryDto = RequireAtLeastOne<
  {
    formId: string
    submittedFrom: Date
    sumittedTo: Date
    refunded: string
    paymentStatus: string
    page: number
    page_size: number
  },
  'refunded' | 'formId' | 'submittedFrom' | 'sumittedTo' | 'paymentStatus'
>

export type ArchivedReportSubmissionsQueryDto = RequireAtLeastOne<
  {
    submittedFrom: Date
    submittedTo: Date
    status: string
  },
  'submittedFrom' | 'submittedTo' | 'status'
>

/**
 * Shape of email form submissions
 */
export type EmailModeSubmissionContentDto = {
  responses: FieldResponse[]
}

export type StorageModeAttachment = {
  encryptedFile?: {
    binary: string
    nonce: string
    submissionPublicKey: string
  }
}

export type StorageModeAttachmentType = {
  encryptedFile: {
    file: string
  }
}

export type StorageModeAttachmentsMap = Record<
  FormFieldDto['_id'],
  StorageModeAttachment
>

export type StorageModeAttachmentsTypeMap = Record<
  FormFieldDto['_id'],
  StorageModeAttachmentType
>

export type StorageModeSubmissionContentDto = {
  // Storage mode only allows
  // 1. verifiable responses in order to validate signatures.
  // 2. email fields with autoreply to send form fillers their response.
  responses: Pick<
    EmailResponse | MobileResponse,
    'fieldType' | '_id' | 'answer' | 'signature'
  >[]
  encryptedContent: string
  attachments?: StorageModeAttachmentsMap
  version: number
  draftId?: string
  signature?: {
    signatureType: string
    value: string
  }
  signDate?: Date
  signatureMeta?: string
  contentForEmail: string
  attachmentsForEmail: StorageModeAttachmentsTypeMap
}

export type StorageModeSubmissionContentDraftsDto = {
  // Storage mode only allows
  // 1. verifiable responses in order to validate signatures.
  // 2. email fields with autoreply to send form fillers their response.
  responses: Pick<
    EmailResponse | MobileResponse,
    'fieldType' | '_id' | 'answer' | 'signature'
  >[]
  encryptedContent: string
  attachments?: StorageModeAttachmentsTypeMap
  version: number
  draftId?: string
  signature?: {
    signatureType: string
    value: string
  }
}

export type ProgressReportsDto = {
  formId: string
  formTitle?: string
  agencyId: string
  agencyName?: string
  receivedCount: number
  unattendedCount: number
  inProgressCount: number
  rejectedCount: number
  completedCount: number
  created?: Date
  createdBy?: any
}

export type ProgressReportSubmissionDto = {
  submissionId: string
  serialNumber?: string
  formId: string
  submittedAt?: Date
  submittedBy?: string
  status?: string
  statusAt?: Date
  lastModifiedBy?: string
  created?: Date
  createdBy?: any
}

export type UnpublishedReportDto = {
  formId: string
  formName: string
  agencyId: string
  agencyName?: string
  submissionCount: number
  createdAt?: Date
  createdBy?: any
}
export type ArchivedReportsDto = {
  formId: string
  formTitle?: string
  archived?: any
  agencyId?: any
  agencyName?: string
  archivedSubmissionCount: number
}

export type ArchivedReportSubmissionDto = {
  submissionId: string
  serialNumber?: string
  formId: string
  submittedAt?: Date
  submittedBy?: string
  status?: string
  statusAt?: Date
}

export type ReconciliationReportsDto = {
  submissionId: string
  serialNumber?: string
  formId: string
  formName?: string
  submittedAt?: Date
  submittedBy: string
  status: string
  statusAt?: Date
  paymentStatus?: string
  amount?: number
  paymentTxnId?: string
  refunded?: boolean
  refundedAt?: Date
}

export type PaymentChargesType = {
  type: string
  name: string
  amount: number
}

export type EncryptedSubmissionDtoResponse = {
  refNo: SubmissionId
  submissionTime: string
  content: string
  verified?: string
  attachmentMetadata: Record<string, string>
  version: number
  submittedAt?: Date
  submittedBy?: any
  submittedByWithoutTitle?: any
  submissionStatus?: string
  agencyName?: string
  agencyId?: string
  formName?: string
  formDescription?: string
  acceptedAt?: Date
  acceptedBy?: any
  acceptedNote?: string
  rejectedAt?: Date
  rejectedBy?: any
  rejectedReason?: string
  rejectedNote?: string
  rejectedAttachment?: string
  completedAt?: Date
  completedBy?: any
  completedNote?: string
  completedAttachment?: string
  archivedDate?: Date
  previousSubmissionId?: string
  formLanguage?: FormLanguages[]
  portalLanguage?: string
  signature?: any
  signDate?: Date
  signatureMeta?: string
  paymentRequired?: boolean
  paymentAmount?: number
  paymentCharges?: PaymentCharges[]
  paymentRefunded?: boolean
  paymentRefundedAmount?: number
  paymentRefundedNote?: string
  paymentRefundedBy?: any
  paymentRefundedAt?: Date
  serialNumber?: string
  isFormArchived?: boolean
  created?: Date
  createdBy?: any
}

export type SubmissionMetaDtoResponse = {
  encryptedContent: string
  attachmentMetadata: Record<string, string>
  version: number
  submittedAt?: Date
  submittedBy?: any
  submittedByWithoutTitle?: any
  submissionStatus?: string
  agencyId?: any
  form?:any
  acceptedAt?: Date
  acceptedBy?: any
  acceptedNote?: string
  rejectedAt?: Date
  rejectedBy?: any
  rejectedReason?: string
  rejectedNote?: string
  rejectedAttachment?: string
  completedAt?: Date
  completedBy?: any
  completedNote?: string
  completedAttachment?: string
  created?: Date
  createdBy?: any
  previousSubmissionId?: string
  formLanguage?: FormLanguages[]
  portalLanguage?: string
  signature?: any
  paymentRequired?: boolean
  paymentAmount?: number
  paymentCharges?: PaymentCharges[]
  paymentRefunded?: boolean
  paymentRefundedAmount?: number
  paymentRefundedNote?: string
  paymentRefundedBy?: any
  paymentRefundedAt?: Date
  serialNumber?: string
  _id: string 
}
