import React, { ReactNode, ReactText, useEffect, useState } from 'react'
import { IconType } from 'react-icons'
import {
  FiActivity,
  FiAirplay,
  FiFile,
  FiHome,
  FiMenu,
  FiServer,
  FiSettings,
  FiUsers,
} from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  BoxProps,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { isBoolean, isEmpty, isString } from 'lodash'

import EFLogo from '~assets/images/evoforms-logo.svg'
import { useUser } from '~contexts/userContext'
import {
  LOGGED_IN_KEY,
  ROLLOUT_ANNOUNCEMENT_KEY_PREFIX,
} from '~constants/localStorage'
import {
  ACTIVITY_LOG_PERMISSION,
  ADMIN_ARCHIVED_REPORT_PERMISSION,
  ADMIN_PROGRESS_REPORT_PERMISSION,
  ADMIN_REPORT_PERMISSION,
  ADMIN_UNPUBLISHED_REPORT_PERMISSION,
  AGENCY_ARCHIVED_REPORT_PERMISSION,
  AGENCY_DASHBOARD_PERMISSION,
  AGENCY_MANAGE_PERMISSION,
  AGENCY_PROGRESS_REPORT_PERMISSION,
  AGENCY_REPORT_PERMISSION,
  AGENCY_SETTINGS_PERMISSION,
  AGENCY_UNPUBLISHED_REPORT_PERMISSION,
  AGENCY_USER_MANAGE_PERMISSION,
  MANAGE_FORMS_PERMISSION,
  MANAGE_REJECT_REASON_PERMISSION,
  MANAGE_TEMPLATES_PERMISSION,
  NOTIFICATION_PERMISSION,
  RECONCILIATION_REPORT_PERMISSION,
  ROOT_ROUTE_PERMISSION,
  SETTINGS_MANAGE_PERMISSION,
  USER_MANAGE_PERMISSION,
  VIEW_AGENCY_PERMISSION,
} from '~constants/permission'
import {
  ACTIVITY_LOG,
  ADMIN_ARCHIVED_REPORT,
  ADMIN_PROGRESS_REPORT,
  ADMIN_UNPUBLISHED_REPORT,
  AGENCY_ARCHIVED_REPORT,
  AGENCY_DASHBOARD_ROUTE,
  AGENCY_MANAGE,
  AGENCY_PROGRESS_REPORT,
  AGENCY_RECONCILlIATION_REPORT,
  AGENCY_UNPUBLISHED_REPORT,
  AGENCY_USER_MANAGE,
  AGENT_MANAGE_FORMS,
  CITIZEN_ONBOARDING_SCREEN,
  CITIZEN_VIEW_GOVERMENT_AGENCY,
  DASHBOARD_ROUTE,
  ITCA_SETTINGS,
  LOGIN_ROUTE,
  LOGOUT_ROUTE_IAS_WSO2,
  MANAGE_REJECT_REASONS,
  MANAGE_TEMPLATES,
  NOTIFICATION,
  ROOT_ROUTE,
  UM_ROUTE,
  USER_MANAGE,
  VIEW_AGENCY_DETAILS,
  VIEW_USER_PROFILE,
} from '~constants/routes'
import { useLocalStorage } from '~hooks/useLocalStorage'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import { getWso2LogoutSettings, logout } from '~services/AuthService'

interface LinkItemProps {
  name: string
  icon: IconType
  code: string
  url: string
  permission: string
  sub: any
}

export const AdminLayout = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box minH="100vh">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4" position="sticky">
        {children}
      </Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const agencyRoles = ['DF_AGENCY_USER', 'DF_AGENCY_ADMIN']

  const [userDetail, setSetUserDetail] = useState<any>({})

  useEffect(() => {
    const user = localStorage.getItem('user')
    setSetUserDetail(user ? JSON.parse(user) : {})
  }, [])

  const LinkItems: Array<LinkItemProps> = []

  if (agencyRoles.includes(userDetail?._doc?.userRole?.code)) {
    LinkItems.push(
      {
        name: 'Dashboard',
        icon: FiHome,
        code: 'dashboard',
        url: AGENCY_DASHBOARD_ROUTE,
        permission: AGENCY_DASHBOARD_PERMISSION,
        sub: [],
      },
      {
        name: 'Manage Users',
        icon: FiUsers,
        code: 'users',
        url: AGENCY_USER_MANAGE,
        permission: AGENCY_USER_MANAGE_PERMISSION,
        sub: [],
      },
      {
        name: 'Agency Details',
        icon: FiAirplay,
        code: 'agency',
        url: VIEW_AGENCY_DETAILS,
        permission: VIEW_AGENCY_PERMISSION,
        sub: [],
      },
      {
        name: 'Manage Forms',
        icon: FiServer,
        code: 'forms',
        url: AGENT_MANAGE_FORMS,
        permission: MANAGE_FORMS_PERMISSION,
        sub: [],
      },
      {
        name: 'Manage Templates',
        icon: FiServer,
        code: 'templates',
        url: MANAGE_TEMPLATES,
        permission: MANAGE_TEMPLATES_PERMISSION,
        sub: [],
      },
      {
        name: 'Settings',
        icon: FiSettings,
        code: 'agencySettings',
        url: '*',
        permission: AGENCY_SETTINGS_PERMISSION,
        sub: [
          {
            name: 'Manage Notifications',
            icon: FiServer,
            code: 'noticications',
            url: NOTIFICATION,
            permission: NOTIFICATION_PERMISSION,
            index: 0,
          },
          {
            name: 'Manage Reject Reasons',
            icon: FiServer,
            code: 'reject',
            url: MANAGE_REJECT_REASONS,
            permission: MANAGE_REJECT_REASON_PERMISSION,
            index: 0,
          },
        ],
      },
      {
        name: 'Reports',
        icon: FiFile,
        code: 'agencyReports',
        url: '*',
        permission: AGENCY_REPORT_PERMISSION,
        sub: [
          {
            name: 'Agency Unpublished Report',
            icon: FiFile,
            code: 'AgencyUppublishedReport',
            url: AGENCY_UNPUBLISHED_REPORT,
            permission: AGENCY_UNPUBLISHED_REPORT_PERMISSION,
            index: 1,
          },
          {
            name: 'Agency Progress Reports',
            icon: FiUsers,
            code: 'agencyProgressReports',
            url: AGENCY_PROGRESS_REPORT,
            permission: AGENCY_PROGRESS_REPORT_PERMISSION,
            index: 1,
          },
          {
            name: 'Agency Archived Reports',
            icon: FiFile,
            code: 'agencyArchivedReports',
            url: AGENCY_ARCHIVED_REPORT,
            permission: AGENCY_ARCHIVED_REPORT_PERMISSION,
            index: 1,
          },
          {
            name: 'Reconciliation Report',
            icon: FiFile,
            code: 'ReconciliationReport',
            url: AGENCY_RECONCILlIATION_REPORT,
            permission: RECONCILIATION_REPORT_PERMISSION,
            index: 1,
          },
        ],
      },
    )
  } else {
    LinkItems.push(
      {
        name: 'Dashboard',
        icon: FiHome,
        code: 'dashboard',
        url: DASHBOARD_ROUTE,
        permission: ROOT_ROUTE_PERMISSION,
        sub: [],
      },
      {
        name: 'Manage Users',
        icon: FiUsers,
        code: 'users',
        url: USER_MANAGE,
        permission: USER_MANAGE_PERMISSION,
        sub: [],
      },
      {
        name: 'Manage Agencies',
        icon: FiServer,
        code: 'agency',
        url: AGENCY_MANAGE,
        permission: AGENCY_MANAGE_PERMISSION,
        sub: [],
      },
      {
        name: 'Settings',
        icon: FiSettings,
        code: 'settings',
        url: ITCA_SETTINGS,
        permission: SETTINGS_MANAGE_PERMISSION,
        sub: [],
      },
      {
        name: 'Reports',
        icon: FiFile,
        code: 'adminReports',
        url: '*',
        permission: ADMIN_REPORT_PERMISSION,
        sub: [
          {
            name: 'Admin Progress Reports',
            icon: FiUsers,
            code: 'adminProgressReports',
            url: ADMIN_PROGRESS_REPORT,
            permission: ADMIN_PROGRESS_REPORT_PERMISSION,
            index: 0,
          },
          {
            name: 'Admin Archived Reports',
            icon: FiFile,
            code: 'adminArchivedReports',
            url: ADMIN_ARCHIVED_REPORT,
            permission: ADMIN_ARCHIVED_REPORT_PERMISSION,
            index: 0,
          },
          {
            name: 'Admin Unpublished Report',
            icon: FiFile,
            code: 'adminUppublishedReport',
            url: ADMIN_UNPUBLISHED_REPORT,
            permission: ADMIN_UNPUBLISHED_REPORT_PERMISSION,
            index: 0,
          },
        ],
      },
      {
        name: 'Activity',
        icon: FiActivity,
        code: 'activity',
        url: ACTIVITY_LOG,
        permission: ACTIVITY_LOG_PERMISSION,
        sub: [],
      },
    )
  }

  const [permissionList, setPermissionList] = useState<any>([])
  const menu = localStorage.getItem('menu')
  const current = menu ? menu : 'dashboard'
  useEffect(() => {
    const permissionUser = localStorage.getItem('user')
    if (permissionUser && permissionUser.length > 0) {
      const permissionUserData = permissionUser
        ? JSON.parse(permissionUser)
        : {}
      setPermissionList(permissionUserData.permissions)
    }
  }, [])

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      overflowY="scroll"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="center" mb="3">
        <Image h="20" src={EFLogo} />
      </Flex>
      {LinkItems.map(
        (link) =>
          permissionList?.filter(function (el: any) {
            return el.permissionCode === link.permission
          }).length > 0 && (
            <NavItem
              key={link.name}
              icon={link.icon}
              current={current}
              code={link.code}
              sub={link.sub}
              permission={permissionList}
              url={link.url}
              onClick={() => {
                localStorage.setItem('menu', link.code)
              }}
              my={3}
            >
              {link.name}
            </NavItem>
          ),
      )}
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  code: string
  icon: IconType
  children: ReactText
  current: string
  url: string
  sub: any
  permission: any
}
const NavItem = ({
  code,
  icon,
  children,
  current,
  url,
  sub,
  permission,
  ...rest
}: NavItemProps) => {
  const selectedMain =
    sub?.filter(function (el: any) {
      return current === el.code
    }).length > 0
      ? code
      : false
  let defaultIndex = 1
  if (selectedMain == code) {
    defaultIndex = 0
  }
  if (sub.length > 0) {
    return (
      <Accordion defaultIndex={[defaultIndex]} allowToggle py="0">
        <AccordionItem style={{ border: 'none' }}>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Link
                style={{ textDecoration: 'none' }}
                _focus={{ boxShadow: 'none' }}
              >
                <Flex
                  align="center"
                  p="4"
                  mx="4"
                  borderRadius="lg"
                  role="group"
                  cursor="pointer"
                  color="primary.800"
                  ml="0"
                  _hover={{
                    bg: 'primary.800',
                    color: 'white',
                  }}
                  {...rest}
                >
                  {icon && (
                    <Icon
                      mr="4"
                      fontSize="16"
                      _groupHover={{
                        color: 'white',
                      }}
                      as={icon}
                    />
                  )}
                  {children}
                  <AccordionIcon />
                </Flex>
              </Link>
            </Box>
          </AccordionButton>
          <AccordionPanel pb={1}>
            {sub.map(
              (link: any) =>
                permission?.filter(function (el: any) {
                  return el.permissionCode === link.permission
                }).length > 0 && (
                  <Link
                    href={link.url}
                    style={{ textDecoration: 'none' }}
                    _focus={{ boxShadow: 'none' }}
                    onClick={() => {
                      localStorage.setItem('menu', link.code)
                    }}
                  >
                    {link.code === current ? (
                      <Flex
                        align="center"
                        p="4"
                        mx="4"
                        borderRadius="lg"
                        role="group"
                        cursor="pointer"
                        background="primary.800"
                        color="white"
                        {...rest}
                      >
                        {link.name}
                      </Flex>
                    ) : (
                      <Flex
                        align="center"
                        p="4"
                        mx="4"
                        borderRadius="lg"
                        role="group"
                        cursor="pointer"
                        color="primary.800"
                        _hover={{
                          bg: 'primary.800',
                          color: 'white',
                        }}
                        {...rest}
                      >
                        {link.name}
                      </Flex>
                    )}
                  </Link>
                ),
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    )
  } else {
    return (
      <Link
        href={url}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        {code === current ? (
          <Flex
            align="center"
            p="4"
            mx="4"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            background="primary.800"
            color="white"
            {...rest}
          >
            {icon && (
              <Icon
                mr="4"
                fontSize="16"
                _groupHover={{
                  color: 'white',
                }}
                as={icon}
              />
            )}
            {children}
          </Flex>
        ) : (
          <Flex
            align="center"
            p="4"
            mx="4"
            borderRadius="lg"
            role="group"
            cursor="pointer"
            color="primary.800"
            _hover={{
              bg: 'primary.800',
              color: 'white',
            }}
            {...rest}
          >
            {icon && (
              <Icon
                mr="4"
                fontSize="16"
                _groupHover={{
                  color: 'white',
                }}
                as={icon}
              />
            )}
            {children}
          </Flex>
        )}
      </Link>
    )
  }
}

interface MobileProps extends FlexProps {
  onOpen: () => void
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const [, setIsAuthenticated] = useLocalStorage<boolean>(LOGGED_IN_KEY)
  const navigate = useNavigate()

  const makeLogoutWithWso2 = async () => {
    try {
      // Get logout options
      const result = await getWso2LogoutSettings()
      if (
        result &&
        isString(result.endpoint) &&
        !isEmpty(result.endpoint) &&
        isString(result.wso2UserIdToken) &&
        !isEmpty(result.wso2UserIdToken) &&
        isString(result.logoutRedirectUrl) &&
        !isEmpty(result.logoutRedirectUrl) &&
        isBoolean(result.clientLogoutOnly) &&
        result.clientLogoutOnly === false &&
        isBoolean(result.logoutWithExtraTab)
      ) {
        const logoutUrl = `${result.endpoint}/oidc/logout?id_token_hint=${result.wso2UserIdToken}&post_logout_redirect_uri=${result.logoutRedirectUrl}`
        if (result.logoutWithExtraTab === true) {
          // Open the logout in a separate tab
          window.open(logoutUrl, '_blank', 'noopener,noreferrer')
        } else {
          window.location.href = logoutUrl
        }
      }
      // Malformed url, perform client logout
    } catch (error) {
      // Error for settings. Ignore
    } finally {
      // Perform client logout
      try {
        await logout()
      } catch (error) {
        // If logout failed from backend. Cookies may not have cleared. Ignore
      }
      setIsAuthenticated(false)
    }
  }

  const toast = useToast()
  const [agencyName, setAgencyName] = useState<string>('')
  const [avatarUrl, setAvatarUrl] = useState<string>('')
  const user = localStorage.getItem('user')
  const userData = user ? JSON.parse(user)._doc : {}

  useEffect(() => {
    getAvatar()
    if (userData?.userCategory.code == 'AGENCY') {
      getAgency()
    }
  }, [])

  async function getAgency() {
    ApiService.get(`/user/session-user`)
      .then((data: any) => {
        if (data?.status === 200) {
          setAgencyName(data?.data.agency.fullName)
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong!',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  async function getAvatar() {
    ApiService.get(`/user/session-user`)
      .then((data: any) => {
        if (data?.status === 200) {
          setAvatarUrl(data?.data.profPicture)
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong!',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Box height="40px" pl="1rem">
        <Heading as="h1" fontSize="24px" color="#37474F" fontWeight="600">
          {agencyName}
        </Heading>
      </Box>
      <HStack spacing={{ base: '0', md: '6' }} borderRadius="5px">
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              px={4}
              borderRadius="10px"
              transition="all 0.3s"
              onDoubleClick={() => navigate(VIEW_USER_PROFILE)}
              _focus={{
                boxShadow: 'md',
              }}
              _active={{
                boxShadow: 'md',
                border: 'none',
              }}
              _hover={{
                boxShadow: 'md',
              }}
            >
              <HStack>
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="2px"
                  ml="2"
                  mr="2"
                >
                  <a onDoubleClick={() => navigate(VIEW_USER_PROFILE)}>
                    <Text
                      fontSize="md"
                      align="right"
                      fontWeight="600"
                      color="#37474F"
                    >
                      {userData?.title} {userData?.firstName}{' '}
                      {userData?.lastName}
                    </Text>
                    <Text fontSize="sm" color="primary.800" align="right">
                      {userData?.userRole?.name}
                    </Text>
                  </a>
                </VStack>
                <Avatar
                  size={'md'}
                  name={`${userData?.firstName} ${userData?.lastName}`}
                  src={avatarUrl}
                />
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              width="200%"
              borderRadius={'5px'}
            >
              <MenuItem as="a" href={VIEW_USER_PROFILE}>
                Profile
              </MenuItem>
              <MenuItem as="a" href={UM_ROUTE} target="_blank">
                Help
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={(e) => {
                  makeLogoutWithWso2()
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}
