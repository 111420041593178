import { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ButtonGroup,
  Center,
  FormControl,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import moment from 'moment-timezone'

import { ActivityDto, ActivityType } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import DateInput from '~components/DatePicker'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input/index'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'

export const ActivityLogPage = (): JSX.Element | null => {
  const idTypes = {
    N: 'NIC',
    P: 'Passport',
    D: 'Driving License',
  }

  type activityTable = {
    rowNumber: number
    activityAt: string
    activityBy: string
    activityType: string
    userIdentificationType: string
    userIdNo: string
    memberNo: string
    agencyName: string
    activityId: string
  }
  const toast = useToast()
  const navigate = useNavigate()
  const csvRef = useRef<any>(null)
  const today = new Date()
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('25')
  const [totalCount, setTotalCount] = useState<number>(0)
  const [activityTableData, setActivityTableData] = useState<activityTable[]>(
    [],
  )
  const [activityTableCsvData, setActivityTableCsvData] = useState<
    activityTable[]
  >([])
  const [queryObject, setQueryObject] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [agencyList, setAgencyList] = useState<any[]>([])

  const mapDataToTable = (array: ActivityDto[], fullList?: boolean) => {
    const currentActivityData: activityTable[] = []

    array.map((value, index) => {
      let userId = ''
      if (value?.data?.user?.identificationType) {
        if (value?.data?.user?.identificationType === 'D') {
          userId = value?.data?.user?.drivingLicenseNo ?? ''
        } else if (value?.data?.user?.identificationType === 'P') {
          userId = value?.data?.user?.passportNo ?? ''
        } else {
          userId = value?.data?.user?.nicNo ?? ''
        }
      }

      const obj = {
        rowNumber: fullList
          ? index + 1
          : parseInt(rowsPerPage) * (currentPage - 1) + index + 1,
        activityAt: moment(value?.createdAt).format('DD MMM, YYYY HH:mm'),
        activityBy: value?.executedBy.username,
        activityType: value?.activityType,
        userIdentificationType:
          value?.data?.user?.identificationType !== undefined
            ? idTypes[value?.data?.user?.identificationType]
            : '',
        userIdNo: userId,
        memberNo:
          value?.data?.user?.memberNo !== undefined
            ? value?.data?.user?.memberNo
            : '',
        agencyName:
          value?.data?.agency?.fullName !== undefined
            ? value?.data?.agency?.fullName
            : '',
        activityId: value?._id,
      }
      currentActivityData.push(obj)
    })
    return currentActivityData
  }

  const initialFromDate = new Date()
  initialFromDate.setDate(initialFromDate.getDate() - 30)
  const mainFormValues = useForm({
    defaultValues: {
      _id: '',
      startDate: format(initialFromDate, 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      activityType: '',
      data: {
        submission: {
          _id: '',
        },
        agency: {
          fullName: '',
        },
        form: {
          _id: '',
        },
        user: {
          memberNo: '',
          identificationType: '',
          identificationNo: '',
        },
      },
    },
  })

  const fromDateVal = mainFormValues.watch('startDate')

  useEffect(() => {
    mainFormValues.handleSubmit(onSubmitMain)
  }, [])

  async function getAgencyList() {
    ApiService.get(`/agency/`)
      .then((data: any) => {
        if (data?.status === 200) {
          mapAgencyList(data?.data?.agencies)
          setIsLoading(false)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const mapAgencyList = (agencies: any[]) => {
    setAgencyList(
      agencies.map((value) => {
        return value.fullName
      }),
    )
  }

  useEffect(() => {
    const response = getAgencyList()
  }, [])

  const callApi = (query = '') => {
    setIsLoading(true)

    ApiService.get(
      `/activity/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.activity)) {
            setActivityTableData(mapDataToTable(data?.data?.activity))
          }
          setTotalCount(data?.data?.meta?.totalItems)
        } else if (data !== 401) {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const getFullData = (query = '') => {
    setIsLoading(true)

    ApiService.get(`/activity/?${query}&page_size=${totalCount}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.activity)) {
            setActivityTableCsvData(mapDataToTable(data?.data?.activity, true))
            csvRef.current.link.click()
          }
        } else if (data !== 401) {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    setCurrentPage(currentPage)
    callApi(queryObject)
  }, [currentPage, rowsPerPage])

  const onSubmitMain = (data: any) => {
    setCurrentPage(1)

    if (data?.data?.user?.identificationNo !== '') {
      if (
        data?.data?.user?.identificationType === 'N' ||
        data?.data?.user?.identificationType === ''
      ) {
        data.data.user.nicNo = data.data.user.identificationNo
      } else if (data?.data?.user?.identificationType === 'P') {
        data.data.user.passportNo = data.data.user.identificationNo
      } else {
        data.data.user.drivingLicenseNo = data.data.user.identificationNo
      }
    }
    if (data?.endDate) {
      data.endDate = moment(data.endDate).endOf('day').toString()
    }
    delete data.data.user.identificationNo

    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObject(queryString)
    callApi(queryString)
  }

  const resetForm = () => {
    setCurrentPage(1)
    setQueryObject('')
    callApi()
    mainFormValues.reset({
      _id: '',
      startDate: format(initialFromDate, 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      activityType: '',
      data: {
        submission: {
          _id: '',
        },
        agency: {
          fullName: '',
        },
        form: {
          _id: '',
        },
        user: {
          memberNo: '',
          identificationType: '',
          identificationNo: '',
        },
      },
    })
  }

  const headers = [
    { label: 'No', key: 'rowNumber' },
    { label: 'Activity At', key: 'activityAt' },
    { label: 'Activity By', key: 'activityBy' },
    { label: 'Activity Type', key: 'activityType' },
    { label: 'User ID Type', key: 'userIdentificationType' },
    { label: 'User ID No.', key: 'userIdNo' },
    { label: 'Member No.', key: 'memberNo' },
  ]

  const csvReport = {
    data: activityTableCsvData,
    headers: headers,
    filename: 'Activity_Log.csv',
  }

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box height="40px">
          <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
            Activity
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.startDate ? true : false
                }
              >
                <FormLabel htmlFor="startDate" mb="8px">
                  Activity From Date
                </FormLabel>
                <Controller
                  control={mainFormValues.control}
                  name={'startDate'}
                  rules={{
                    required: 'Activity From Date is required',
                    validate: {
                      // GET IT?
                      validDate: (val) => {
                        if (!val) return
                        const dateVal = new Date(val)
                        if (isNaN(dateVal.getTime())) {
                          return 'Please enter a valid date'
                        }
                        return true
                      },
                    },
                  }}
                  render={({ field }) => (
                    <DateInput excludeFuture={true} {...field} />
                  )}
                />
                {mainFormValues.formState.errors.startDate && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.startDate.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.endDate ? true : false
                }
              >
                <FormLabel htmlFor="endDate" mb="8px">
                  Activity To Date
                </FormLabel>
                <Controller
                  control={mainFormValues.control}
                  name={'endDate'}
                  rules={{
                    required: 'Activity To Date is required',
                    validate: {
                      // GET IT?
                      validDate: (val) => {
                        if (!val) return
                        const dateVal = new Date(val)
                        const fromDate = new Date(fromDateVal)
                        if (isNaN(dateVal.getTime())) {
                          return 'Please enter a valid date'
                        } else if (dateVal < fromDate) {
                          return 'To date cannot be lesser than From date.'
                        }
                        return true
                      },
                    },
                  }}
                  render={({ field }) => (
                    <DateInput excludeFuture={true} {...field} />
                  )}
                />
                {mainFormValues.formState.errors.endDate && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.endDate.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.activityType ? true : false
                }
              >
                <FormLabel htmlFor="activityType" mb="8px">
                  Activity Type
                </FormLabel>
                <Controller
                  name="activityType"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      items={Object.values(ActivityType).map((activity) => {
                        return {
                          value: activity,
                          label: activity,
                        }
                      })}
                    />
                  )}
                />
                {mainFormValues.formState.errors.activityType && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.activityType.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.data?.agency?.fullName
                    ? true
                    : false
                }
              >
                <FormLabel htmlFor="data.agency.fullName" mb="8px">
                  Agency Name
                </FormLabel>
                <Controller
                  name="data.agency.fullName"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      items={Object.values(agencyList).map((agency) => {
                        return {
                          value: agency,
                          label: agency,
                        }
                      })}
                    />
                  )}
                />
                {mainFormValues.formState.errors.data?.agency?.fullName && (
                  <FormErrorMessage>
                    {
                      mainFormValues.formState.errors.data?.agency?.fullName
                        .message
                    }
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <GridItem colSpan={2}>
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Activity Data
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.data?.user
                              ?.identificationType
                              ? true
                              : false
                          }
                        >
                          <FormLabel
                            htmlFor="data.user.identificationType"
                            mb="8px"
                          >
                            User ID Type
                          </FormLabel>
                          <Controller
                            name="data.user.identificationType"
                            control={mainFormValues.control}
                            render={({ field }) => (
                              <SingleSelect
                                {...field}
                                items={[
                                  {
                                    value: 'N',
                                    label: 'NIC',
                                  },
                                  {
                                    value: 'P',
                                    label: 'Passport',
                                  },
                                  {
                                    value: 'D',
                                    label: 'Driving License',
                                  },
                                ]}
                              />
                            )}
                          />
                          {mainFormValues.formState.errors.data?.user
                            ?.identificationType && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors.data?.user
                                  ?.identificationType.message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.data?.user
                              ?.identificationNo
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="userIdNo" mb="8px">
                            User Identification No.
                          </FormLabel>
                          <Input
                            placeholder={'User Identification No.'}
                            id="userIdNo"
                            maxLength={100}
                            {...mainFormValues.register(
                              'data.user.identificationNo',
                              {
                                maxLength: {
                                  value: 100,
                                  message: 'Max length is 100',
                                },
                              },
                            )}
                          />
                          {mainFormValues.formState.errors.data?.user
                            ?.identificationNo && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors.data?.user
                                  ?.identificationNo.message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.data?.submission
                              ?._id
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="submissionId" mb="8px">
                            Activity ID
                          </FormLabel>
                          <Input
                            placeholder={'Activity ID'}
                            id="submissionId"
                            maxLength={100}
                            {...mainFormValues.register('_id', {
                              maxLength: {
                                value: 100,
                                message: 'Max length is 100',
                              },
                            })}
                          />
                          {mainFormValues.formState.errors.data?.submission
                            ?._id && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors.data?.submission
                                  ?._id.message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.data?.user
                              ?.memberNo
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="data.user.memberNo" mb="8px">
                            Member No.
                          </FormLabel>
                          <Input
                            placeholder={'Member No.'}
                            id="data.user.memberNo"
                            maxLength={100}
                            {...mainFormValues.register('data.user.memberNo', {
                              maxLength: {
                                value: 100,
                                message: 'Max length is 100',
                              },
                            })}
                          />
                          {mainFormValues.formState.errors.data?.user
                            ?.memberNo && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors.data?.user
                                  ?.memberNo.message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </GridItem>

            <GridItem colSpan={2} pt={2} pb={2}>
              <Center>
                <ButtonGroup variant="outline" spacing="6">
                  <Button type="submit">Search</Button>
                  <Button type="reset" onClick={resetForm}>
                    Reset
                  </Button>
                </ButtonGroup>
              </Center>
            </GridItem>
          </SimpleGrid>
        </form>

        <Box
          bg={'white'}
          px={10}
          py={5}
          overflowX="auto"
          maxWidth="100Vw"
          borderRadius="12px"
        >
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
            <Box></Box>
          </SimpleGrid>
          <SimpleGrid
            columns={{ sm: 1, md: 1 }}
            spacingX="70px"
            spacingY="20px"
          >
            <DataTable
              heading="Activities"
              rowValues={activityTableData ? activityTableData : []}
              cols={[
                {
                  Header: 'No',
                  accessor: 'rowNumber',
                  disableSortBy: true,
                  maxWidth: 50,
                  minWidth: 25,
                },
                {
                  Header: 'Activity At',
                  accessor: 'activityAt',
                  disableSortBy: true,
                  maxWidth: 100,
                  minWidth: 70,
                },
                {
                  Header: 'Activity By',
                  accessor: 'activityBy',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 70,
                },
                {
                  Header: 'Activity Type',
                  accessor: 'activityType',
                  disableSortBy: true,
                  maxWidth: 100,
                  minWidth: 70,
                },
                {
                  Header: 'Activity ID',
                  accessor: 'activityId',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 100,
                },
                {
                  Header: 'User ID Type',
                  accessor: 'userIdentificationType',
                  disableSortBy: true,
                  maxWidth: 100,
                  minWidth: 25,
                },
                {
                  Header: 'User ID No.',
                  accessor: 'userIdNo',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 50,
                },
                {
                  Header: 'Member No.',
                  accessor: 'memberNo',
                  disableSortBy: true,
                  maxWidth: 100,
                  minWidth: 50,
                },
                {
                  Header: 'Agency Name',
                  accessor: 'agencyName',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 70,
                },
                {
                  Header: 'Action',
                  Cell: (props: any) => (
                    <ButtonGroup variant="outline" spacing="1" padding={2}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/activity-log/${props?.row?.values?.activityId}`,
                          )
                        }
                      >
                        More
                      </Button>
                    </ButtonGroup>
                  ),
                },
              ]}
            />
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : null}
            {activityTableData.length === 0 ? (
              <Center>No results found</Center>
            ) : null}
            <SimpleGrid
              columns={{ sm: 1, md: 2 }}
              spacingX="70px"
              spacingY="20px"
            >
              <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                <GridItem w="100%" h="10" colSpan={2} pt={2}>
                  Rows per page
                </GridItem>
                <GridItem w="100%" h="10" colSpan={1}>
                  <SingleSelect
                    value={rowsPerPage}
                    onChange={(value) => {
                      setCurrentPage(1)
                      setRowsPerPage(value)
                    }}
                    name={'rowsPerPage'}
                    isClearable={false}
                    items={[
                      {
                        value: '5',
                        label: '5',
                      },
                      {
                        value: '10',
                        label: '10',
                      },
                      {
                        value: '25',
                        label: '25',
                      },
                      {
                        value: '50',
                        label: '50',
                      },
                      {
                        value: '100',
                        label: '100',
                      },
                    ]}
                  />
                </GridItem>
              </Grid>
              <Pagination
                currentPage={currentPage}
                pageSize={parseInt(rowsPerPage, 10)}
                totalCount={totalCount}
                onPageChange={setCurrentPage}
              />
              <ButtonGroup variant="outline" spacing="1" padding={2}>
                <Button onClick={() => getFullData(queryObject)}>
                  Download CSV
                </Button>
                <CSVLink
                  {...csvReport}
                  style={{ display: 'none' }}
                  ref={csvRef}
                ></CSVLink>
              </ButtonGroup>
            </SimpleGrid>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  )
}

const fromObjectToQueryString = (obj: any): string => {
  let queryString = ''

  function getQueryString(path: string | null, value: any): string {
    if (value === '') return ''
    if (typeof value === 'object') {
      const newPath = path == null ? '' : path + '.'
      return Object.keys(value)
        .map((k) => getQueryString(newPath + k, value[k]))
        .filter((value) => value !== '')
        .join('&')
    }
    return path == null ? '' + value : path + '=' + value
  }

  queryString = getQueryString(null, obj)

  if (queryString.substr(queryString.length - 1) === '&') {
    queryString = queryString.substring(0, queryString.length - 1)
  }
  return queryString
}
