import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { useQueryClient } from 'react-query'
import {
  ButtonGroup,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react'
import { isArray, map } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'

import { ConversationTypeCode } from '~shared/types'

import { useToast } from '~hooks/useToast'
import {
  getConversationMessageTypes,
  getConversationMessageTypesForCitizen,
} from '~services/ConversationMessageTypeService'
import {
  createConversationBySubmission,
  createConversationBySubmissionForCitizen,
} from '~services/ConversationsService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'

import { LanguageFilter } from '../../../../../atom'

type ConversationMessageModelProps = {
  formId: string
  agencyId: string | undefined
  agencyName: string | undefined
  submissionId: string
  formCollaborators: any
  submissionStatus: string | undefined
}

export const ConversationMessageModel = ({
  formId,
  agencyId,
  agencyName,
  submissionId,
  formCollaborators,
  submissionStatus,
}: ConversationMessageModelProps): JSX.Element => {
  const MAX_MESSAGE_LENGTH = 250
  const [showingModel, setShowingModel] = useState<boolean>(false)
  const [messageTypes, setMessageTypes] = useState<string[]>([])
  const locale: any = useRecoilValue(LanguageFilter)

  type whichShowingMessageTypeForm = {
    messageType: string
    messageContent: string
  }

  const toast = useToast({ duration: 5000, isClosable: true })
  const queryClient = useQueryClient()

  // Get initial data to populate the table
  const getInitialData = async () => {
    try {
      const responseData = await getConversationMessageTypes('CQ')
      setMessageTypes(
        isArray(responseData)
          ? map(responseData, 'conversationMessageType')
          : [],
      )
    } catch (error) {
      // Ignore error
      setMessageTypes([])
    }
  }
  useEffect(() => {
    getInitialData()
  }, [])

  const { handleSubmit, register, formState, control, reset } =
    useForm<whichShowingMessageTypeForm>()

  // Create a conversation message
  const onSubmitForm = async (inputs: whichShowingMessageTypeForm) => {
    toast.closeAll()
    try {
      // Create record
      const conversationWithOptions = {
        formId: formId,
        submissionId: submissionId,
        agency: {
          agencyId: `${agencyId}`,
          name: `${agencyName}`,
        },
        archived: false,
        conversationType: {
          code: 'CQ',
          name: ConversationTypeCode.CQ,
        },
        // recipientPublicUser: This is extracted at the backend using submissionId
        message: {
          type: inputs.messageType,
          content: inputs.messageContent,
          deleted: false,
        },
      }

      let newConversation = null
      try {
        newConversation = await createConversationBySubmissionForCitizen(
          conversationWithOptions,
        )
      } catch (error) {
        // Ignore error
      }

      if (isArray(newConversation)) {
        queryClient.cancelQueries('submissionConversationList')
        queryClient.setQueryData('submissionConversationList', newConversation)

        toast({
          status: 'success',
          description: 'Inquiry Sent Successfully',
        })
      } else {
        toast({
          status: 'danger',
          description: 'Something went wrong',
        })
      }

      setShowingModel(false)
      reset({})
      // Reload the updated data
    } catch (error) {
      toast({
        status: 'danger',
        description:
          error instanceof Error ? error.message : 'Something went wrong',
      })
    }
  }

  // Check the UI render criteria with form status, otherwise return
  if (submissionStatus !== 'UnAttended' && submissionStatus !== 'Inprogress') {
    return <></>
  }

  const messages: any = {
    en: {
      inquiry: 'Send Inquiry',
    },
    si: {
      inquiry: 'විමසීම් යවන්න',
    },
    ta: {
      inquiry: 'விசாரணையை அனுப்பவும்',
    },
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {/* UI button for the parent */}

      {/* Show add or update modal */}
      <Modal
        isOpen={showingModel}
        onClose={() => {
          setShowingModel(false)
          reset({})
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <ModalCloseButton />
            <ModalHeader>Send Inquiries</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              <FormControl isInvalid={!!formState.errors.messageType} mb="1rem">
                <FormLabel isRequired htmlFor="messageType">
                  Message Type: *
                </FormLabel>
                <Controller
                  name="messageType"
                  control={control}
                  render={({ field }) => (
                    <SingleSelect
                      isClearable={false}
                      {...register('messageType', {
                        required: 'Message Type is required',
                      })}
                      {...field}
                      items={messageTypes}
                    />
                  )}
                />
                {formState.errors.messageType && (
                  <FormErrorMessage>
                    {formState.errors.messageType.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl
                isInvalid={!!formState.errors.messageContent}
                mb="1rem"
              >
                <FormLabel isRequired htmlFor="messageContent">
                  Message: *
                </FormLabel>
                <Textarea
                  maxLength={MAX_MESSAGE_LENGTH}
                  inputMode="text"
                  autoComplete="messageContent"
                  autoFocus
                  placeholder="e.g. Inquiry message"
                  {...register('messageContent', {
                    required: 'Message is required',
                    maxLength: {
                      value: MAX_MESSAGE_LENGTH,
                      message: 'Character length should not exceed',
                    },
                    pattern: {
                      value: /^[ A-Za-z0-9.,?$!@#%^&*()_]*$/,
                      message:
                        'Message should be alphanumeric and/or one of .,?$!@#%^&*()_ characters.',
                    },
                  })}
                />
                {formState.errors.messageContent && (
                  <FormErrorMessage>
                    {formState.errors.messageContent.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button type="submit">Send Inquiry</Button>
                <Button
                  onClick={() => {
                    setShowingModel(false)
                    reset({})
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </IntlProvider>
  )
}
