import { useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  FormControl,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import { times } from 'lodash'

import { BasicField } from '~shared/types'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'
import { TableField, TableFieldSchema } from '~templates/Field'

import { useAgencyDashboard } from '~pages/Dashboard/queries'

import Input from '../../../components/Input/index'
import { fromObjectToQueryString, getMappedErrorMessage } from '../utils'

export const ManageAgencyPage = (): JSX.Element | null => {
  type agencyTable = {
    rowNumber: number
    fullName: string
    _id: string
    description?: string
    status?: string
    createdAt?: string
  }
  const toast = useToast()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('25')
  const [agencyTableData, setAgencyTableData] = useState<agencyTable[]>([])
  const [queryObject, setQueryObject] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [agenciesCount, setAgenciesCount] = useState<number>(0)
  const { data } = useAgencyDashboard()

  const agencyDropdownData: any = []

  const mapDataToDropDown = () => {
    data?.map((value) => {
      agencyDropdownData.push({
        value: value?.fullName,
        label: value?.fullName,
      })
    })
  }

  mapDataToDropDown()

  const formatDate = (value: string): string => {
    const date = new Date(value)
    const day = date.toLocaleString('default', { day: '2-digit' })
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.toLocaleString('default', { year: 'numeric' })
    const time = date.toLocaleTimeString()
    return day + '/' + month + '/' + year + ' ' + time
  }

  const mapDataToTable = (array: any[]) => {
    const currentAgencyData: agencyTable[] = []
    array.map((value, index) => {
      const obj = {
        rowNumber: index + 1,
        no:
          currentPage == 1
            ? index + 1
            : (currentPage - 1) * parseInt(rowsPerPage) + index + 1,
        fullName: value?.fullName,
        _id: value?._id,
        description: value.description,
        status: value?.status,
        createdAt: formatDate(value?.created),
      }
      currentAgencyData.push(obj)
    })
    setAgencyTableData(currentAgencyData)
  }

  const mainFormValues = useForm({
    defaultValues: {
      agencyId: '',
      status: 'ALL',
      fullName: '',
    },
    mode: 'onChange',
  })

  const callApi = (query = '') => {
    setIsLoading(true)
    // const requestOptions = {
    //   method: 'GET',
    //   headers: { 'Content-Type': 'application/json' },
    // }
    // fetch(
    //   `http://forms.lk/api/v3/agency/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    //   requestOptions,
    // )
    ApiService.get(
      `/agency/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.agencies)) {
            mapDataToTable(data?.data?.agencies)
            setAgenciesCount(data?.data?.meta?.totalItems)
          }
        } else {
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        toast.closeAll()
        toast({
          description: getMappedErrorMessage(error),
          status: 'danger',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      })
  }

  // useEffect(() => {
  //   callApi()
  // }, [])
  useEffect(() => {
    setCurrentPage(currentPage)
    callApi(queryObject)
  }, [currentPage, rowsPerPage])

  const onSubmitMain = (data: any) => {
    setCurrentPage(1)
    if (data?.status === 'ALL' || data?.status === '') {
      delete data['status']
    }
    if (data?.agencyId === '') {
      delete data['agencyId']
    }
    if (data?.fullName === '') {
      delete data['fullName']
    }

    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObject(queryString)
    callApi(queryString)
  }

  const resetForm = () => {
    setCurrentPage(1)
    setQueryObject('')
    callApi()
    mainFormValues.reset({
      status: 'ALL',
      agencyId: '',
      fullName: '',
    })
  }

  const currentStatus = mainFormValues.watch('status')
  const currentEnteredFullName = mainFormValues.watch('fullName')

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box height="40px">
          <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
            Manage Organization Agencies
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.fullName ? true : false
                }
              >
                <FormLabel htmlFor="fullName" mb="8px">
                  Organization Agency
                </FormLabel>
                <Controller
                  name="fullName"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect {...field} items={agencyDropdownData} />
                  )}
                />
                {mainFormValues.formState.errors.fullName && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.fullName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.status ? true : false
                }
              >
                <FormLabel htmlFor="status" mb="8px">
                  Status
                </FormLabel>
                <Controller
                  name="status"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      items={[
                        {
                          value: 'ALL',
                          label: 'All',
                        },
                        {
                          value: 'Active',
                          label: 'Active',
                        },
                        {
                          value: 'Inactive',
                          label: 'Inactive',
                        },
                      ]}
                    />
                  )}
                />
                {mainFormValues.formState.errors.status && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.status.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </SimpleGrid>
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="10px" mt={10}>
            <Center>
              <ButtonGroup variant="outline" spacing="6">
                <Button
                  type="submit"
                  disabled={
                    currentStatus === '' && currentEnteredFullName === ''
                  }
                >
                  Search
                </Button>
                <Button type="reset" onClick={resetForm}>
                  Reset
                </Button>
                <Button onClick={() => navigate('/agency/create')}>
                  Create New Agency
                </Button>
              </ButtonGroup>
            </Center>
          </SimpleGrid>
        </form>

        <Box
          bg={'white'}
          px={10}
          py={5}
          mt={10}
          overflowX="auto"
          maxWidth="100Vw"
          borderRadius="12px"
        >
          <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="10px">
            <DataTable
              heading="Agency Details"
              rowValues={agencyTableData ? agencyTableData : []}
              cols={[
                {
                  Header: 'No.',
                  accessor: 'no',
                  disableSortBy: true,
                  maxWidth: 100,
                  minWidth: 80,
                  width: 80,
                },
                {
                  Header: 'Agency Name',
                  accessor: 'fullName',
                  Cell: (props: any) => (
                    <>
                      {props?.row?.original?.status === 'Inactive' ? (
                        <Box bg="red.200" p={1}>
                          {props?.row?.values?.fullName}
                        </Box>
                      ) : (
                        <Box p={1}>{props?.row?.values?.fullName}</Box>
                      )}
                    </>
                  ),
                  disableSortBy: false,
                  maxWidth: 400,
                  minWidth: 190,
                },
                {
                  Header: 'Description',
                  accessor: 'description',
                  disableSortBy: true,
                  maxWidth: 300,
                  minWidth: 170,
                },
                {
                  Header: 'Created at',
                  accessor: 'createdAt',
                  disableSortBy: true,
                  maxWidth: 300,
                  minWidth: 170,
                },
                {
                  Header: () => <Text ml={12}>Action</Text>,
                  accessor: 'Action',
                  disableSortBy: true,
                  Cell: (props: any) => (
                    <ButtonGroup variant="outline" spacing="1">
                      <Button
                        onClick={() =>
                          navigate(`/agency/view/${props?.row?.original?._id}`)
                        }
                      >
                        View
                      </Button>
                      <Button
                        disabled={props?.row?.original?.status === 'Inactive'}
                        onClick={() =>
                          navigate(
                            `/agency/update/${props?.row?.original?._id}`,
                          )
                        }
                      >
                        Update
                      </Button>
                    </ButtonGroup>
                  ),
                },
              ]}
            />
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : null}
            {agencyTableData.length === 0 ? (
              <Center mt={5}>No results found</Center>
            ) : null}
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
              <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                <GridItem w="100%" h="10" colSpan={1} pt={2}>
                  Rows per page
                </GridItem>
                <GridItem w="100%" h="10" colSpan={1}>
                  <SingleSelect
                    value={rowsPerPage}
                    onChange={(value) => {
                      setCurrentPage(1)
                      setRowsPerPage(value)
                    }}
                    name={'rowsPerPage'}
                    isClearable={false}
                    items={[
                      {
                        value: '5',
                        label: '5',
                      },
                      {
                        value: '10',
                        label: '10',
                      },
                      {
                        value: '25',
                        label: '25',
                      },
                      {
                        value: '50',
                        label: '50',
                      },
                      {
                        value: '100',
                        label: '100',
                      },
                    ]}
                  />
                </GridItem>
              </Grid>
              <Box p={2}>
                <Center>
                  <Tag variant="subtle" colorScheme="red">
                    <TagLabel>Inactive Agencies</TagLabel>
                  </Tag>
                </Center>
              </Box>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="70px">
              {agencyTableData.length !== 0 ? (
                <Pagination
                  currentPage={currentPage}
                  pageSize={parseInt(rowsPerPage, 10)}
                  totalCount={agenciesCount}
                  onPageChange={setCurrentPage}
                />
              ) : null}
            </SimpleGrid>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  )
}
