import { memo, useCallback } from 'react'
import { BiDownload } from 'react-icons/bi'
import {
  Box,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

import { BasicField } from '~shared/types'

import Button from '~components/Button'
import FormLabel from '~components/FormControl/FormLabel'
import Spinner from '~components/Spinner'

import { AugmentedDecryptedResponse } from '~features/admin-form/responses/augmentDecryptedResponses'

import { useMutateDownloadAttachments } from './mutations'

export interface PrintDecryptedRowBaseProps {
  row: AugmentedDecryptedResponse
}
type PrintDecryptedRowProps = PrintDecryptedRowBaseProps & {
  secretKey: string
}

const standardFontSize = '12pt'
const printBorderStyle = '2px solid #172c51'

const DecryptedQuestionLabel = ({ row }: PrintDecryptedRowBaseProps) => {
  return (
    <FormLabel
      questionNumber={`${row.questionNumber}.`}
      fontSize={standardFontSize}
      isRequired
      fontWeight="bold"
      mb="0"
      mt={3}
    >
      {`${row.signature ? '[verified] ' : ''}${row.question}`}
    </FormLabel>
  )
}

const DecryptedHeaderRow = ({
  row,
}: PrintDecryptedRowBaseProps): JSX.Element => {
  return (
    <Text
      textStyle="h2"
      as="h2"
      _notFirst={{ mt: '2.5rem' }}
      fontSize="14pt"
      fontWeight="400"
    >
      {row.question}
    </Text>
  )
}

const DecryptedTableRow = ({
  row,
}: PrintDecryptedRowBaseProps): JSX.Element => {
  return (
    <Stack>
      <DecryptedQuestionLabel row={row} />
      <Table
        variant="column-stripe"
        sx={{ tableLayout: 'fixed' }}
        fontSize={standardFontSize}
        // maxW="52%"
        style={{
          border: printBorderStyle,
        }}
      >
        <Thead
          style={{
            border: printBorderStyle,
          }}
          bg="gray.200"
        >
          <Tr
            style={{
              border: printBorderStyle,
            }}
          >
            {row.columnTitles?.map((title) => (
              <Th
                style={{
                  border: printBorderStyle,
                }}
                fontSize={standardFontSize}
                p={3}
                textTransform="none"
              >
                <Text as="b">{title}</Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody
          style={{
            border: printBorderStyle,
          }}
        >
          {row.answerArray?.map((row, idx) => (
            <Tr
              style={{
                border: printBorderStyle,
              }}
              fontSize={standardFontSize}
              key={idx}
            >
              {Array.isArray(row) ? (
                row.map((col, cidx) => (
                  <Td
                    style={{
                      border: printBorderStyle,
                    }}
                    key={cidx}
                    p={3}
                  >
                    {col}
                  </Td>
                ))
              ) : (
                <Td
                  style={{
                    border: printBorderStyle,
                  }}
                  fontSize={standardFontSize}
                >
                  {row}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  )
}

const DecryptedAttachmentRow = ({ row, secretKey }: PrintDecryptedRowProps) => {
  const { downloadAttachmentMutation } = useMutateDownloadAttachments()

  const handleDownload = useCallback(() => {
    if (!row.downloadUrl || !row.answer) return
    return downloadAttachmentMutation.mutate({
      url: row.downloadUrl,
      secretKey,
      fileName: row.answer,
    })
  }, [downloadAttachmentMutation, secretKey, row])

  return (
    <Stack w="100%">
      <DecryptedQuestionLabel row={row} />
      <Text fontSize={standardFontSize} textStyle="body-1" ml={4}>
        <Text as="span" ml={6}>
          Filename:{' '}
        </Text>
        {row.answer && (
          <Button
            variant="link"
            aria-label="Download file"
            isDisabled={downloadAttachmentMutation.isLoading}
            onClick={handleDownload}
            rightIcon={
              downloadAttachmentMutation.isLoading ? (
                <Spinner fontSize="1.5rem" />
              ) : (
                <BiDownload fontSize="1.5rem" />
              )
            }
          >
            {row.answer}
          </Button>
        )}
      </Text>
    </Stack>
  )
}

export const PrintDecryptedRow = memo(
  ({ row, secretKey }: PrintDecryptedRowProps): JSX.Element => {
    switch (row.fieldType) {
      case BasicField.Section:
        return <DecryptedHeaderRow row={row} />
      case BasicField.Attachment:
        return <DecryptedAttachmentRow row={row} secretKey={secretKey} />
      case BasicField.Table:
        return <DecryptedTableRow row={row} />
      default:
        return (
          <Stack>
            {row.answer !== '' ? (
              <>
                <DecryptedQuestionLabel row={row} />
                {/* <Box pl={4} mt="0 !important"> */}
                <Box w="100%" py={1} px={3} border={printBorderStyle}>
                  {row.answer && (
                    <Text
                      fontSize={standardFontSize}
                      pb={1}
                      //   borderWidth="1px"
                      //   borderColor="gray.400"
                      //   borderStyle="hidden hidden dashed hidden"
                    >
                      {row.answer}
                    </Text>
                  )}
                  {row.answerArray && (
                    <Text
                      fontSize={standardFontSize}
                      pb={1}
                      // borderWidth="1px"
                      // borderColor="gray.400"
                      // borderStyle="hidden hidden dashed hidden"
                    >
                      {row.answerArray.join(', ')}
                    </Text>
                  )}
                </Box>
              </>
            ) : null}
          </Stack>
        )
    }
  },
)
