import { useEffect, useState } from 'react'
import {
  BiBadge,
  BiBadgeCheck,
  BiCheckCircle,
  BiLinkExternal,
  BiListCheck,
  BiLoaderCircle,
  BiXCircle,
} from 'react-icons/bi'
import { useQueryClient } from 'react-query'
import { Link as ReactLink, useParams } from 'react-router-dom'
import {
  Badge,
  Box,
  ButtonGroup,
  CloseButton,
  FormLabel as ChakraFormLabel,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { isArray, isEmpty, isString, orderBy } from 'lodash'
import moment from 'moment-timezone'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import Link from '~components/Link'
import Spinner from '~components/Spinner'
import { StatBox } from '~components/StatBox'
import { DataTable } from '~components/Table'
import Tile from '~components/Tile'

import { useAgencyDashboard, useNotificationDashboard } from './queries'

export const AgencyDashboardPage = (): JSX.Element => {
  type AgeAnalysisTableType = {
    oneToFourteen?: string
    fifteenToTwentyEight?: string
    twentyEightPlus?: string
  }

  const fromObjectToQueryString = (obj: any): string => {
    let queryString = ''

    if (obj?.agencyId === 'ALL' || obj?.agencyId === '') {
      delete obj['agencyId']
    }

    Object.keys(obj).forEach((key) => {
      queryString = queryString + `${key}=${obj[key]}&`
    })

    if (queryString.substr(queryString.length - 1) === '&') {
      queryString = queryString.substring(0, queryString.length - 1)
    }
    return queryString
  }

  const toast = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [completionRate, setCompletionRate] = useState<string>('-')
  const [formsPublished, setFormsPublished] = useState<string>('-')
  const [submitted, setSubmitted] = useState<string>('-')
  const [unAttended, setUnAttended] = useState<string>('-')
  const [inProgress, setInProgress] = useState<string>('-')
  const [completed, setCompleted] = useState<string>('-')
  const [rejected, setRejected] = useState<string>('-')
  const [ageAnalysisTable, setAgeAnalysisTable] =
    useState<AgeAnalysisTableType[]>()
  const [userLastAccessed, setUserLastAccessed] = useState<Date | null>(null)
  const [deletingConversation, setDeletingConversation] = useState<any>(null)
  const queryClient = useQueryClient()
  const [agencyName, setAgencyName] = useState<string>('Dashboard')

  const notificationsForDashboard = useNotificationDashboard()
  const notifications = isArray(notificationsForDashboard?.data)
    ? orderBy(notificationsForDashboard?.data, ['createdAt'], ['desc'])
    : []

  const mapDataToAgeTable = (array: any[]) => {
    const currentAgeData: AgeAnalysisTableType[] = []
    array.map((value, index) => {
      const obj = {
        oneToFourteen: value?.statisticValue['1_14'],
        fifteenToTwentyEight: value?.statisticValue['15_28'],
        twentyEightPlus: value?.statisticValue['29_PLUS'],
      }
      currentAgeData.push(obj)
    })
    setAgencyName(array[0]?.agency?.agencyName)
    setAgeAnalysisTable(currentAgeData)
  }

  const callApi = (query: string) => {
    setIsLoading(true)
    //stat list API
    ApiService.get(`/agency-statistics?statisticType=AA&${query}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          mapDataToAgeTable(data.data)
        } else if (data !== 401) {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
        setIsLoading(false)
      })

    //stat summary API.
    ApiService.get(`/agency-statistics/summary?${query}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          setCompletionRate(data?.data?.completedSubmissionsPercentage)
          setFormsPublished(data?.data?.totalPublishedFormsCount)
          setSubmitted(data?.data?.totalSubmissionsCount)
          setUnAttended(data?.data?.totalUnAttendedSubmissionsCount)
          setInProgress(data?.data?.totalInprogressSubmissionsCount)
          setCompleted(data?.data?.totalCompletedSubmissionsCount)
          setRejected(data?.data?.totalRejectedSubmissionsCount)
        } else if (data !== 401) {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        console.log('error', error)
        setIsLoading(false)
      })
  }

  const loadAgency = () => {
    const user = localStorage.getItem('user')
    if (isString(user) && !isEmpty(user)) {
      const userData = user ? JSON.parse(user) : {}
      if (userData?._doc?.agency) {
        const data = {
          agencyId: userData?._doc?.agency,
        }
        // call API passing the Data and set to result.
        const returnedQuery = fromObjectToQueryString(data)
        callApi(returnedQuery)
      } else {
        callApi('')
      }
    }
  }

  useEffect(() => {
    loadAgency()
  }, [])

  useEffect(() => {
    const loggedUser = localStorage.getItem('user')
    if (isString(loggedUser) && !isEmpty(loggedUser)) {
      const loggedUserDetails = JSON.parse(loggedUser)

      if (loggedUserDetails?._doc?.lastAccessed) {
        setUserLastAccessed(loggedUserDetails?._doc?.lastAccessed)
      }
    }
  }, [])

  const getFormattedMessage = (createdAt: Date, name: string) => {
    const message = `${moment(createdAt).format(
      'DD/MM/YY',
    )} - Message received from, ${name}`
    return (
      <Text
        style={
          createdAt && moment(userLastAccessed).isAfter(createdAt)
            ? { color: 'black' }
            : { color: 'blue' }
        }
      >
        {message}
      </Text>
    )
  }

  const updateNotificationAsRead = (id: string | undefined) => {
    ApiService.put(`notifications/push-notification/${id}`, {
      notification: { read: true },
    }).then((data: any) => data)
  }

  // Delete notifications
  const deleteNotifications = async (selectedNotifications: any[]) => {
    if (!isArray(selectedNotifications) || isEmpty(selectedNotifications)) {
      return
    }

    const selectedNotificationToDelete = [...selectedNotifications]
    setDeletingConversation(null)

    // Delete notifications one by one
    for (const selectedNotification of selectedNotificationToDelete) {
      setIsLoading(true)
      await ApiService.delete(
        `/notifications/push-notifications/${selectedNotification?._id}`,
      )
        .then((data: any) => {
          if (data?.status === 200) {
            setIsLoading(false)
          } else {
            setIsLoading(false)
            toast({
              title: '',
              description: 'Something went wrong!',
              duration: 5000,
              isClosable: true,
              status: 'danger',
              position: 'top-right',
            })
          }
        })
        .catch((error) => {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        })
    }

    // Refresh the notification table
    await queryClient.refetchQueries(['dashboardConversationList'], {
      active: true,
    })
  }

  return (
    <Box bg={'#ECEFF1'} borderRadius="12px" p={5}>
      <Box height="40px" m={3}>
        <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
          Dashboard
        </Heading>
      </Box>

      <SimpleGrid
        columns={{ sm: 2, md: 4 }}
        spacing="40px"
        bg="white"
        p={5}
        mb={3}
        mx={3}
        borderRadius="12px"
      >
        <Box borderRadius="12px" p={3} height="150px" bg="primary.50">
          <Box fontSize="4rem">
            {completionRate === null || completionRate === '-'
              ? '0%'
              : `${Math.round(parseFloat(completionRate))}%`}
          </Box>
          <Box>of Completion</Box>
        </Box>
        <Box borderRadius="8px" p={3} height="150px" bg="primary.50">
          <Box fontSize="4rem">{formsPublished}</Box>
          <Box>Forms Published</Box>
        </Box>
      </SimpleGrid>
      <SimpleGrid
        columns={{ sm: 2, md: 5 }}
        spacing="40px"
        bg="white"
        p={5}
        mb={3}
        mx={3}
        borderRadius="12px"
      >
        <Box borderRadius="8px" p={3} height="150px" bg="primary.50">
          <Box fontSize="4rem">{submitted}</Box>
          <Box>Submitted</Box>
        </Box>
        <Box borderRadius="8px" p={3} height="150px" bg="primary.50">
          <Box fontSize="4rem">{unAttended}</Box>
          <Box>Unattended</Box>
        </Box>
        <Box borderRadius="8px" p={3} height="150px" bg="primary.50">
          <Box fontSize="4rem">{inProgress}</Box>
          <Box>In Progress</Box>
        </Box>
        <Box borderRadius="8px" p={3} height="150px" bg="primary.50">
          <Box fontSize="4rem">{completed}</Box>
          <Box>Completed</Box>
        </Box>
        <Box borderRadius="8px" p={3} height="150px" bg="primary.50">
          <Box fontSize="4rem">{rejected}</Box>
          <Box>Rejected</Box>
        </Box>
      </SimpleGrid>
      <Box bg="white" borderRadius="12px" m={3} p={3}>
        <Box height="40px">
          <Tooltip
            hasArrow
            label="Number of days from Form Submission to Form Completion"
            bg="white"
            color="black"
            placement="bottom-start"
          >
            <Heading as="h4" size="md" p={3}>
              Age Analysis
            </Heading>
          </Tooltip>
        </Box>
        <hr />
        {ageAnalysisTable?.map((ageAnalysis, idx) => (
          <SimpleGrid
            columns={{ sm: 2, md: 3 }}
            spacing="40px"
            bg="white"
            p={5}
            mb={3}
            mx={3}
          >
            <Box borderRadius="8px" p={3} height="150px" bg="gray.200">
              <Tooltip
                hasArrow
                label="Forms completed within the first two weeks after submission."
                bg="white"
                color="black"
                placement="bottom-start"
              >
                <Box fontSize="4rem">
                  {ageAnalysis ? ageAnalysis.oneToFourteen : 0}
                </Box>
              </Tooltip>
              <Box>1 - 14 days</Box>
            </Box>
            <Box borderRadius="8px" p={3} height="150px" bg="gray.200">
              <Tooltip
                hasArrow
                label="Forms completed within 15 - 28 days after submission."
                bg="white"
                color="black"
                placement="bottom-start"
              >
                <Box fontSize="4rem">
                  {ageAnalysis ? ageAnalysis.fifteenToTwentyEight : 0}
                </Box>
              </Tooltip>
              <Box>15 - 28 days</Box>
            </Box>
            <Box borderRadius="8px" p={3} height="150px" bg="gray.200">
              <Tooltip
                hasArrow
                label="Forms completed after 29 days after submission."
                bg="white"
                color="black"
                placement="bottom-start"
              >
                <Box fontSize="4rem">
                  {ageAnalysis ? ageAnalysis.twentyEightPlus : 0}
                </Box>
              </Tooltip>
              <Box>29 days or more</Box>
            </Box>
          </SimpleGrid>
        ))}
      </Box>

      <SimpleGrid
        columns={{ sm: 1, md: 1 }}
        spacing="40px"
        backgroundColor={'gray.100'}
        p={3}
        mb={10}
      >
        <Stack bg="white" p={5} borderRadius="12px">
          <Text as={'b'} textStyle="h2">
            Inquiries
          </Text>

          {isEmpty(notifications) ? (
            <Text pt="10px">No inquiries to show</Text>
          ) : (
            <SimpleGrid
              columns={{ sm: 1, md: 1 }}
              maxH={300}
              overflowY="scroll"
            >
              <SimpleGrid columns={{ sm: 1, md: 6 }} spacing="5px">
                <GridItem colSpan={4}></GridItem>
                <GridItem>Remove All</GridItem>
                <GridItem>
                  <CloseButton
                    bg={'red.500'}
                    color={'white'}
                    size="md"
                    onClick={() => {
                      setDeletingConversation(notifications)
                    }}
                  />
                </GridItem>
              </SimpleGrid>

              {notifications.map((notification, i) => {
                return (
                  <Box
                    key={i}
                    bg={notification.read ? '#CFD8DC' : '#FFF3E0'}
                    margin={'5px'}
                    borderRadius="5px"
                  >
                    <SimpleGrid
                      columns={{ sm: 1, md: 6 }}
                      spacing="5px"
                      p={'20px'}
                      alignItems={'center'}
                    >
                      <GridItem colSpan={3}>
                        {getFormattedMessage(
                          notification?.createdAt,
                          notification?.senderPublicUser?.name,
                        )}
                      </GridItem>
                      <GridItem>
                        <Tooltip label={notification?.notificationMore}>
                          More...
                        </Tooltip>
                      </GridItem>
                      <GridItem>
                        <Link
                          as={ReactLink}
                          to={`/${notification?.formId}/submission/${notification?.submissionId}`}
                        >
                          <Button
                            onClick={() => {
                              updateNotificationAsRead(notification?._id)
                            }}
                          >
                            View Form
                          </Button>
                        </Link>
                      </GridItem>
                      <GridItem>
                        <CloseButton
                          bg={'red.400'}
                          color={'white'}
                          size="md"
                          onClick={() => {
                            setDeletingConversation([notification])
                          }}
                        />
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                )
              })}
            </SimpleGrid>
          )}
        </Stack>
      </SimpleGrid>

      {/* Delete confirmation */}
      <Modal
        isOpen={isArray(deletingConversation) && !isEmpty(deletingConversation)}
        onClose={() => {
          setDeletingConversation(null)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody whiteSpace="pre-line">
            Are you sure you want to delete?
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={() => deleteNotifications(deletingConversation)}>
                Yes
              </Button>
              <Button
                onClick={() => {
                  setDeletingConversation(null)
                }}
              >
                No
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
