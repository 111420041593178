import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Spacer,
} from '@chakra-ui/react'
import moment from 'moment-timezone'

import { PublicUserDto } from '~shared/types/public_user'

import DefaultUserImage from '~assets/images/default-user.png'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'

export const ViewCitizenProfilePage = (): JSX.Element | null => {
  const idTypes = {
    N: 'NIC',
    P: 'Passport',
    D: 'Driving License',
  }

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const { citizenId } = useParams()
  const toast = useToast()
  const navigate = useNavigate()

  const [result, setResult] = useState<PublicUserDto>()

  async function getUser() {
    ApiService.get(`/user/public/${citizenId}`)
      .then((data: any) => {
        setResult(data?.data?.user)
        setIsLoaded(true)
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const response = getUser()
  }, [])

  const mainFormValues = useForm({
    defaultValues: {
      title: '-',
      gender: '-',
      firstName: '-',
      lastName: '-',
      nameWithInitials: '-',
      identificationType: '-',
      identificationNo: '-',
      countryOfPassport: '-',
      preferredLanguage: '-',
      profPicture: '-',
      contactNo: '-',
      email: '-',
      signUpVia: '-',
      lastLogged: '-',
    },
  })

  const onSubmitMain = (data: any) => {
    console.log('Form Values', data)
  }

  useEffect(() => {
    mainFormValues.setValue('title', result?.title ? result?.title : '-')
    mainFormValues.setValue(
      'gender',
      result?.gender
        ? result?.gender === 'M'
          ? 'Male'
          : result?.gender === 'F'
          ? 'Female'
          : result?.gender
        : '-',
    )
    mainFormValues.setValue(
      'firstName',
      result?.firstName ? result?.firstName : '-',
    )
    mainFormValues.setValue(
      'lastName',
      result?.lastName ? result?.lastName : '-',
    )
    mainFormValues.setValue(
      'nameWithInitials',
      result?.nameInitials ? result?.nameInitials : '-',
    )
    mainFormValues.setValue(
      'identificationType',
      result?.identificationType
        ? result?.identificationType === 'N'
          ? 'NIC'
          : result?.identificationType === 'P'
          ? 'Passport'
          : 'Driving License'
        : '-',
    )
    mainFormValues.setValue(
      'identificationNo',
      result?.identificationType === 'N'
        ? result?.nicNo
          ? result?.nicNo
          : '-'
        : result?.identificationType === 'P'
        ? result?.passportNo
          ? result?.passportNo
          : '-'
        : result?.drivingLicenseNo
        ? result?.drivingLicenseNo
        : '-',
    )
    mainFormValues.setValue(
      'countryOfPassport',
      result?.passportCountry ? result?.passportCountry : '-',
    )
    mainFormValues.setValue(
      'preferredLanguage',
      result?.preferredLanguage ? result?.preferredLanguage : '-',
    )
    mainFormValues.setValue('profPicture', result?.profPicture ?? '-')
    mainFormValues.setValue(
      'contactNo',
      result?.contact ? result?.contact : '-',
    )
    mainFormValues.setValue('email', result?.email ? result?.email : '-')
    mainFormValues.setValue(
      'signUpVia',
      result?.signupSource ? result?.signupSource : '-',
    )
    mainFormValues.setValue(
      'lastLogged',
      result?.lastModifiedAt !== undefined
        ? moment(result?.lastModifiedAt).format('DD/MMM/YYYY HH:mm:ss A')
        : '-',
    )
  }, [result])

  const title = mainFormValues.getValues('title')
  const gender = mainFormValues.getValues('gender')
  const firstName = mainFormValues.getValues('firstName')
  const lastName = mainFormValues.getValues('lastName')
  const nameWithInitials = mainFormValues.getValues('nameWithInitials')
  const identificationType = mainFormValues.getValues('identificationType')
  const identificationNo = mainFormValues.getValues('identificationNo')
  const countryOfPassport = mainFormValues.getValues('countryOfPassport')
  const preferredLanguage = mainFormValues.getValues('preferredLanguage')
  const profPicture = mainFormValues.getValues('profPicture')
  const contactNo = mainFormValues.getValues('contactNo')
  const email = mainFormValues.getValues('email')
  const signUpVia = mainFormValues.getValues('signUpVia')
  const lastLogged = mainFormValues.getValues('lastLogged')

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Flex mb={5}>
          <Box>
            <Box height="40px" margin="10px">
              <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
                View Citizen Profile
              </Heading>
            </Box>
          </Box>
          <Spacer />
        </Flex>

        <Box borderRadius="12px" border="none" bg="white" p={10}>
          <Heading
            as="h2"
            fontSize="22px"
            color="#37474F"
            fontWeight="500"
            padding="10px"
          >
            Personal Details
          </Heading>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="60px" px={5}>
            <dl>
              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Title:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {title}
                </dt>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Gender:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {gender}
                </dt>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  First Name:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {firstName}
                </dt>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Last Name:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {lastName}
                </dt>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Name with Initials:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {nameWithInitials}
                </dt>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Identification Type:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {identificationType}
                </dt>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Identification Number:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {identificationNo}
                </dt>
              </div>

              {identificationType === 'Passport' && (
                <div style={{ display: 'flex' }}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Country of Passport:
                  </dd>
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {countryOfPassport}
                  </dt>
                </div>
              )}

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Preferred Language:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {preferredLanguage}
                </dt>
              </div>
            </dl>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <Box></Box>
              <Box h="200px" w="200px">
                <Box bg={'#ECEFF1'} borderRadius="50%">
                  <Image
                    h="200px"
                    w="200px"
                    borderRadius="full"
                    src={profPicture}
                    fallbackSrc={DefaultUserImage}
                  />
                </Box>
              </Box>
            </SimpleGrid>
          </SimpleGrid>
        </Box>
        <br />
        <Box borderRadius="12px" border="none" bg="white" p={10}>
          <Heading
            as="h2"
            fontSize="22px"
            color="#37474F"
            fontWeight="500"
            padding="10px"
          >
            Contact Details
          </Heading>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px" px={5}>
            <div style={{ display: 'flex' }}>
              <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                Phone Number:
              </dd>
              <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                {contactNo}
              </dt>
            </div>

            <div style={{ display: 'flex' }}>
              <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                Email Address:
              </dd>
              <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                {email}
              </dt>
            </div>
          </SimpleGrid>
        </Box>
        <br />
        <Box borderRadius="12px" border="none" bg="white" p={10}>
          <Heading
            as="h2"
            fontSize="22px"
            color="#37474F"
            fontWeight="500"
            padding="10px"
          >
            Other Details
          </Heading>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px" px={5}>
            <dl>
              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Sign up via:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {signUpVia}
                </dt>
              </div>
            </dl>

            <dl>
              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Last Logged at:
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {lastLogged}
                </dt>
              </div>
            </dl>
          </SimpleGrid>
        </Box>
        <br />
        <Center>
          <ButtonGroup spacing="1rem" padding={10}>
            <Button type="submit" onClick={() => navigate(-1)}>
              Back
            </Button>
          </ButtonGroup>
        </Center>
      </Box>
    </>
  )
}
