import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  FormControl,
  Grid,
  GridItem,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Pagination from '~components/Pagination'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'
import { TableField, TableFieldSchema } from '~templates/Field'

import Input from '../../../../components/Input/index'
import {
  convertNumberToSriLankaNumber,
  fromObjectToQueryString,
  GENERIC_ID_REGEX,
  PASSPORT_REGEX,
  SL_DRIVING_LICENSE_REGEX,
  SL_NIC_REGEX,
} from '../../utils'

export const AgencyManageUserPage = (): JSX.Element | null => {
  type userTable = {
    _id: string
    rowNumber: number
    memberNo: string
    title: string
    firstName: string
    lastName: string
    nameInitials: string
    userCategory: string
    userAgency: string
    userRole: string
    identificationType: string
    nicNo: string
    contact: string
  }
  const toast = useToast()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('25')
  const [totalCount, setTotalCount] = useState<number>(0)
  const [userTableData, setUserTableData] = useState<userTable[]>([])
  const [queryObject, setQueryObject] = useState<string>('')
  const [idType, setIdType] = useState<string>('')
  const [maxLength, setMaxLength] = useState<number>(0)
  const [minLength, setMinLength] = useState<number>(0)
  const [idNumberRegex, setIdNumberRegex] = useState<RegExp>(SL_NIC_REGEX)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const mapDataToTable = (array: any[]) => {
    const currentUserDta: userTable[] = []
    array.map((value, index) => {
      const obj = {
        _id: value?._id,
        rowNumber: parseInt(rowsPerPage) * (currentPage - 1) + index + 1,
        memberNo: value?.memberNo,
        title: value?.title,
        firstName: value?.firstName,
        lastName: value?.lastName,
        nameInitials: value?.nameInitials,
        userCategory: value?.userCategory.name,
        userAgency: value?.userCategory.code,
        userRole: value?.userRole.name,
        identificationType:
          value?.identificationType === 'N'
            ? 'NIC'
            : value?.identificationType === 'P'
            ? 'Passport'
            : value?.identificationType === 'D'
            ? 'Driving Licence'
            : value?.identificationType,
        nicNo:
          value?.identificationType === 'N'
            ? value?.nicNo
            : value?.identificationType === 'P'
            ? value?.passportNo
            : value?.identificationType === 'D'
            ? value?.drivingLicenseNo
            : '-',
        contact: value?.contact,
      }
      currentUserDta.push(obj)
    })
    setUserTableData(currentUserDta)
  }

  const mainFormValues = useForm({
    defaultValues: {
      agencyId: '',
      status: 'ALL',
      userCategory: 'ALL',
      userRole: 'ALL',
      agency: '',
      name: '',
      memberNo: '',
      contact: '',
      email: '',
      nicNo: '',
      identificationType: 'ALL',
    },
  })

  const [agency, setAgency] = useState<any>([])

  async function getAgency() {
    ApiService.get(`/agency`)
      .then((data: any) => {
        if (data?.status === 200) {
          setAgency(data?.data)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    getAgency()
  }, [])

  const agencyArr: any[] = []

  useEffect(() => {
    if (agency?.agencies) {
      for (const element of agency.agencies) {
        agencyArr.push({ value: element._id, label: element.fullName })
      }
    }
  }, [agency])

  const callApi = (query = '') => {
    setIsLoading(true)
    // const requestOptions = {
    //   method: 'GET',
    //   headers: { 'Content-Type': 'application/json' },
    // }
    // fetch(
    //   `http://forms.lk/api/v3/agency/?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    //   requestOptions,
    // )
    ApiService.get(
      `/user/filter?${query}&page_size=${rowsPerPage}&page=${currentPage}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          if (Array.isArray(data?.data?.users)) {
            mapDataToTable(data?.data?.users)
          }
          setTotalCount(data?.data?.meta?.totalItems)
        } else if (data !== 401) {
          setIsLoading(false)
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  // useEffect(() => {
  //   callApi()
  // }, [])
  useEffect(() => {
    setCurrentPage(currentPage)
    callApi(queryObject)
  }, [currentPage, rowsPerPage])

  const onSubmitMain = (data: any) => {
    setCurrentPage(1)
    if (data?.status === 'ALL' || data?.status === '') {
      delete data['status']
    }
    data['userCategory.code'] = 'AGENCY'
    if (data?.userRole !== 'ALL') {
      data['userRole.code'] = data['userRole']
    }
    if (data?.agency === '') {
      delete data['agency']
    }
    if (data?.agencyId === '') {
      delete data['agencyId']
    }
    if (data?.identificationType === 'ALL') {
      delete data['identificationType']
    }
    if (data?.name === '') {
      delete data['name']
    }
    if (data?.memberNo === '') {
      delete data['memberNo']
    }
    if (data?.contact === '') {
      delete data['contact']
    }
    if (data?.email === '') {
      delete data['email']
    }
    if (data?.nicNo === '') {
      delete data['nicNo']
    }
    delete data['userCategory']
    delete data['userRole']

    if (data?.contact !== undefined && data?.contact !== '') {
      const phoneNumber = convertNumberToSriLankaNumber(data.contact)
      data.contact = encodeURIComponent(phoneNumber)
    }

    let queryString = ''

    queryString = fromObjectToQueryString(data)
    setQueryObject(queryString)
    callApi(queryString)
  }

  const resetForm = () => {
    setCurrentPage(1)
    setQueryObject('')
    callApi()
    mainFormValues.reset({
      status: 'ALL',
      agencyId: '',
      userCategory: 'ALL',
      userRole: 'ALL',
      agency: '',
      name: '',
      memberNo: '',
      contact: '',
      email: '',
      nicNo: '',
      identificationType: 'ALL',
    })
  }

  const identificationType = mainFormValues.watch('identificationType')

  useEffect(() => {
    if (identificationType === 'P') {
      setIdType('P')
      setMaxLength(30)
      setMinLength(0)
      setIdNumberRegex(PASSPORT_REGEX)
    } else if (identificationType === 'N') {
      setIdType('N')
      setMaxLength(12)
      setMinLength(10)
      setIdNumberRegex(SL_NIC_REGEX)
    } else if (identificationType === 'D') {
      setIdType('D')
      setMaxLength(8)
      setMinLength(0)
      setIdNumberRegex(SL_DRIVING_LICENSE_REGEX)
    } else {
      setIdType('')
      setMaxLength(30)
      setMinLength(0)
      setIdNumberRegex(GENERIC_ID_REGEX)
    }
    // mainFormValues.setValue('identificationNo', '')
  }, [identificationType])

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box height="40px">
          <Heading as="h2" fontSize="22px" color="#37474F" fontWeight="500">
            Manage Users
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.memberNo ? true : false
                }
              >
                <FormLabel htmlFor="memberNo" mb="8px">
                  Member No
                </FormLabel>
                <Input
                  placeholder={'Member No'}
                  id="memberNo"
                  maxLength={10}
                  {...mainFormValues.register('memberNo', {
                    maxLength: { value: 10, message: 'Max lenght is 200' },
                  })}
                />
                {mainFormValues.formState.errors.memberNo && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.memberNo.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.name ? true : false
                }
              >
                <FormLabel htmlFor="name" mb="8px">
                  Name
                </FormLabel>
                <Input
                  placeholder={'Name'}
                  id="name"
                  maxLength={100}
                  {...mainFormValues.register('name', {
                    maxLength: { value: 100, message: 'Max lenght is 100' },
                  })}
                />
                {mainFormValues.formState.errors.name && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.name.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.userCategory ? true : false
                }
              >
                <FormLabel htmlFor="userRole:" mb="8px">
                  User Role
                </FormLabel>
                <Controller
                  name="userRole"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      items={[
                        {
                          value: 'ALL',
                          label: 'All',
                        },
                        {
                          value: 'DF_AGENCY_USER',
                          label: ' Agency User',
                        },
                        {
                          value: 'DF_AGENCY_ADMIN',
                          label: 'Agency Administrator',
                        },
                      ]}
                    />
                  )}
                />
                {mainFormValues.formState.errors.userRole && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.userRole.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.identificationType
                    ? true
                    : false
                }
              >
                <FormLabel htmlFor="identificationType" mb="8px">
                  Identification Type
                </FormLabel>
                <Controller
                  name="identificationType"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      items={[
                        {
                          value: 'ALL',
                          label: 'All',
                        },
                        {
                          value: 'N',
                          label: 'NIC',
                        },
                        {
                          value: 'P',
                          label: 'Passport',
                        },
                        {
                          value: 'D',
                          label: 'Driving License',
                        },
                      ]}
                    />
                  )}
                />
                {mainFormValues.formState.errors.identificationType && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.identificationType.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.nicNo ? true : false
                }
              >
                <FormLabel htmlFor="nicNo" mb="8px">
                  {idType === 'N'
                    ? 'NIC Number'
                    : idType === 'D'
                    ? 'Driving License Number'
                    : idType === 'P'
                    ? 'Passport Number'
                    : 'Identification Number'}
                </FormLabel>
                <Input
                  placeholder={
                    idType === 'N'
                      ? 'NIC Number'
                      : idType === 'D'
                      ? 'Driving License Number'
                      : idType === 'P'
                      ? 'Passport Number'
                      : 'Identification Number'
                  }
                  id="nicNo"
                  maxLength={30}
                  {...mainFormValues.register('nicNo', {
                    maxLength: {
                      value: maxLength,
                      message:
                        idType === 'N'
                          ? 'Max length is 12'
                          : idType === 'D'
                          ? 'Max length is 8'
                          : 'Max length is 30',
                    },
                    minLength: {
                      value: minLength,
                      message: 'Min length is 10',
                    },
                    pattern: {
                      value: idNumberRegex,
                      message: 'Invalid Identification Number',
                    },
                  })}
                />
                {mainFormValues.formState.errors.nicNo && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.nicNo.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.contact ? true : false
                }
              >
                <FormLabel htmlFor="contact" mb="8px">
                  Phone Number
                </FormLabel>
                <Input
                  placeholder={'Phone Number'}
                  id="contact"
                  maxLength={200}
                  {...mainFormValues.register('contact', {
                    maxLength: { value: 200, message: 'Max lenght is 200' },
                  })}
                />
                {mainFormValues.formState.errors.contact && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.contact.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.email ? true : false
                }
              >
                <FormLabel htmlFor="email" mb="8px">
                  Email
                </FormLabel>
                <Input
                  placeholder={'Email'}
                  id="email"
                  maxLength={200}
                  {...mainFormValues.register('email', {
                    maxLength: { value: 200, message: 'Max lenght is 200' },
                  })}
                />
                {mainFormValues.formState.errors.email && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.email.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.status ? true : false
                }
              >
                <FormLabel htmlFor="status" mb="8px">
                  Status
                </FormLabel>
                <Controller
                  name="status"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      items={[
                        {
                          value: 'ALL',
                          label: 'All',
                        },
                        {
                          value: 'Active',
                          label: 'Active',
                        },
                        {
                          value: 'Inactive',
                          label: 'Inactive',
                        },
                      ]}
                    />
                  )}
                />
                {mainFormValues.formState.errors.status && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.status.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Center mt="1rem" ml="25rem">
              <ButtonGroup variant="outline" spacing="6" mb="2rem">
                <Button type="submit" mr="1rem">
                  Search
                </Button>
                <Button type="reset" onClick={resetForm} mr="1rem">
                  Reset
                </Button>
                <Button onClick={() => navigate('/agent/user/create')}>
                  Create New User
                </Button>
              </ButtonGroup>
            </Center>
          </SimpleGrid>
        </form>

        <Box
          bg={'white'}
          p={10}
          overflowX="auto"
          maxWidth="100Vw"
          borderRadius="12px"
        >
          <SimpleGrid
            columns={{ sm: 1, md: 1 }}
            spacing="15px"
            overflowX="auto"
          >
            <DataTable
              heading="User Details"
              rowValues={userTableData ? userTableData : []}
              cols={[
                {
                  Header: 'No.',
                  accessor: 'rowNumber',
                  disableSortBy: true,
                  maxWidth: 100,
                  minWidth: 50,
                },
                {
                  Header: 'Member No',
                  accessor: 'memberNo',
                  disableSortBy: true,
                  maxWidth: 100,
                  minWidth: 80,
                },
                {
                  Header: 'First Name',
                  accessor: 'firstName',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 100,
                },
                {
                  Header: 'Last Name',
                  accessor: 'lastName',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 100,
                },
                {
                  Header: 'User Category',
                  accessor: 'userCategory',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 100,
                },
                {
                  Header: 'Agency Type',
                  accessor: 'userAgency',
                  disableSortBy: true,
                  maxWidth: 150,
                  minWidth: 100,
                },
                {
                  Header: 'User Role',
                  accessor: 'userRole',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 140,
                },
                {
                  Header: 'Identification Type',
                  accessor: 'identificationType',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 140,
                },
                {
                  Header: 'Identification Number',
                  accessor: 'nicNo',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 140,
                },
                {
                  Header: 'Phone Number',
                  accessor: 'contact',
                  disableSortBy: true,
                  maxWidth: 200,
                  minWidth: 140,
                  Cell: ({ value }: { value: string }) => `0${value.slice(3)}`,
                },
                {
                  Header: 'Action',
                  Cell: (props: any) => (
                    <ButtonGroup variant="outline" spacing="1" padding={2}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/agent/user/view/${props?.row?.original?._id}`,
                          )
                        }
                      >
                        View
                      </Button>
                      <Button
                        onClick={() =>
                          navigate(
                            `/agent/user/update/${props?.row?.original?._id}`,
                          )
                        }
                      >
                        Update
                      </Button>
                    </ButtonGroup>
                  ),
                },
              ]}
            />
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : null}
            {userTableData.length === 0 ? (
              <Center mt={5}>No results found</Center>
            ) : null}
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
              <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                <GridItem w="100%" h="10" colSpan={2} pt={2}>
                  Rows per page:
                </GridItem>
                <GridItem w="100%" h="10" colSpan={1}>
                  <SingleSelect
                    value={rowsPerPage}
                    onChange={(value) => {
                      setCurrentPage(1)
                      setRowsPerPage(value)
                    }}
                    name={'rowsPerPage'}
                    isClearable={false}
                    items={[
                      {
                        value: '5',
                        label: '5',
                      },
                      {
                        value: '10',
                        label: '10',
                      },
                      {
                        value: '25',
                        label: '25',
                      },
                      {
                        value: '50',
                        label: '50',
                      },
                      {
                        value: '100',
                        label: '100',
                      },
                    ]}
                  />
                </GridItem>
              </Grid>
              <Pagination
                currentPage={currentPage}
                pageSize={parseInt(rowsPerPage, 10)}
                totalCount={totalCount} //from BE the total number of record should also be returned.
                onPageChange={setCurrentPage}
              />
            </SimpleGrid>
          </SimpleGrid>
        </Box>
      </Box>
    </>
  )
}
