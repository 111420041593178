import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  CheckboxGroup,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react'
import { setDefaultResultOrder } from 'dns'
import { chunk, times } from 'lodash'

import { AgencyDto, BasicField } from '~shared/types'

import DefaultAgencyImage from '~assets/images/default-agency.png'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Badge from '~components/Badge'
import Button from '~components/Button'
import Checkbox from '~components/Checkbox'
import { SingleSelect } from '~components/Dropdown'
import FormLabel from '~components/FormControl/FormLabel'
import Pagination from '~components/Pagination'
import { DataTable } from '~components/Table'
import { TableField, TableFieldSchema } from '~templates/Field'

import Input from '../../../components/Input/index'
import { arrayToString, convertToSriLankaNumber } from '../utils'

export const ViewAgencyPage = (): JSX.Element | null => {
  type ContactPerson = {
    personName: string
    designation: string
    mobile: string[]
    email: string[]
    _id: string
  }
  type ContactPersonTable = {
    rowNumber: number
    personName: string
    designation: string
    mobile: string
    email: string
  }

  const formatDate = (value: string): string => {
    const date = new Date(value)
    const day = date.toLocaleString('default', { day: '2-digit' })
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.toLocaleString('default', { year: 'numeric' })
    const time = date.toLocaleTimeString()
    return day + '/' + month + '/' + year + ' ' + time
  }

  const navigate = useNavigate()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [error, setError] = useState()
  const [contactDetail, setContactDetail] = useState<ContactPersonTable[]>([])
  const { agencyId } = useParams()
  const toast = useToast()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [paginatedContactData, setPaginatedContactData] = useState<
    ContactPersonTable[]
  >([])
  const ROWS_PER_PAGE = 25

  const mapContactDetails = () => {
    const array: ContactPersonTable[] = []

    if (result?.contactPerson?.length !== 0) {
      result?.contactPerson?.map((value: ContactPerson, index) => {
        array.push({
          rowNumber: ROWS_PER_PAGE * (currentPage - 1) + index + 1,
          personName: value.personName,
          designation: value.designation === '' ? '-' : value.designation,
          mobile: arrayToString(value.mobile),
          email: value.email?.length === 0 ? '-' : arrayToString(value.email),
        })
      })
    }

    setContactDetail(array)
  }

  const [result, setResult] = useState({
    emailDomain: [''],
    mobile: [],
    email: [],
    _id: '',
    shortName: '',
    fullName: '',
    logo: '',
    description: '',
    agencyCategory: {
      code: '',
      name: '',
    },
    website: '',
    paymentMerchantId: '',
    paymentServiceId: '',
    paymentEncryptionKey: '',
    district: '',
    province: '',
    address: {
      line1: '',
      line2: '',
      line3: '',
      city: '',
    },
    status: '',
    contactPerson: [
      {
        mobile: [],
        email: [],
        _id: '',
        personName: '',
        designation: '',
      },
    ],
    smsServiceEnabled: false,
    emailServiceEnabled: false,
    created: '',
    lastModified: '',
    createdBy: { userName: '' },
    lastModifiedBy: { userName: '' },
    __v: 0,
    note: '',
  })

  async function getAgency() {
    ApiService.get(`/agency/${agencyId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setResult(data?.data) //result.agency
          setIsLoaded(true)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const response = getAgency()
  }, [])

  const mainFormValues = useForm({
    defaultValues: {
      governemtAgencyId: '-',
      fullName: '-',
      shortName: '-',
      description: '-',
      addressLine1: '-',
      addressLine2: '-',
      city: '-',
      district: '-',
      province: '-',
      website: '-',
      merchantId: '-',
      serviceId: '-',
      paymentEncryptionKey: '-',
      phoneNumber: '-',
      email: '-',
      smsServiceEnable: false,
      emailServiceEnable: false,
      logo: '',
      status: 'Inactive',
      contactPersonDetails: [
        {
          personName: '-',
          designation: '-',
          mobile: [],
          email: [],
        },
      ],
      note: '-',
      createdAt: '-',
      createdBy: '-',
      updatedAt: '-',
      updatedBy: '-',
    },
  })

  const onSubmitMain = (data: any) => {
    console.log('Form Values', data)
  }

  useEffect(() => {
    const phoneNumberString = arrayToString(
      convertToSriLankaNumber(result?.mobile),
    )
    const emailString = arrayToString(result?.email)
    mapContactDetails()

    mainFormValues.setValue(
      'governemtAgencyId',
      result?._id ? result?._id : '-',
    )
    mainFormValues.setValue(
      'fullName',
      result?.fullName ? result?.fullName : '-',
    )
    mainFormValues.setValue(
      'shortName',
      result?.shortName ? result?.shortName : '-',
    )
    mainFormValues.setValue(
      'description',
      result?.description ? result.description : '-',
    )
    mainFormValues.setValue(
      'addressLine1',
      result?.address?.line1 ? result?.address?.line1 : '-',
    )
    mainFormValues.setValue(
      'addressLine2',
      result?.address?.line2 ? result?.address?.line2 : '-',
    )
    mainFormValues.setValue(
      'city',
      result?.address?.city ? result?.address?.city : '-',
    )
    mainFormValues.setValue(
      'district',
      result?.district ? result?.district : '-',
    )
    mainFormValues.setValue(
      'province',
      result?.province ? result?.province : '-',
    )
    mainFormValues.setValue('website', result?.website ? result?.website : '-')
    mainFormValues.setValue(
      'merchantId',
      result?.paymentMerchantId ? result?.paymentMerchantId : '-',
    )
    mainFormValues.setValue(
      'serviceId',
      result?.paymentServiceId ? result?.paymentServiceId : '-',
    )
    mainFormValues.setValue(
      'smsServiceEnable',
      result?.smsServiceEnabled ? result?.smsServiceEnabled : false,
    )
    mainFormValues.setValue(
      'emailServiceEnable',
      result?.emailServiceEnabled ? result?.emailServiceEnabled : false,
    )
    mainFormValues.setValue(
      'phoneNumber',
      phoneNumberString.replace(/^(\+\d{1,2})/, '0'),
    )
    mainFormValues.setValue('email', emailString)
    mainFormValues.setValue('logo', result?.logo ? result?.logo : '')
    mainFormValues.setValue('status', result?.status ? result?.status : '-')
    mainFormValues.setValue('note', result?.note ? result?.note : '-')
    mainFormValues.setValue(
      'createdAt',
      result?.created ? result?.created : '-',
    )
    mainFormValues.setValue('createdBy', result?.createdBy?.userName)
    mainFormValues.setValue(
      'updatedAt',
      result?.lastModified ? result?.lastModified : '-',
    )
    mainFormValues.setValue('updatedBy', result?.lastModifiedBy?.userName)
  }, [result])

  const governemtAgencyId = mainFormValues.getValues('governemtAgencyId')
  const fullName = mainFormValues.getValues('fullName')
  const shortName = mainFormValues.getValues('shortName')
  const description = mainFormValues.getValues('description')
  const addressLine1 = mainFormValues.getValues('addressLine1')
  const addressLine2 = mainFormValues.getValues('addressLine2')
  const city = mainFormValues.getValues('city')
  const district = mainFormValues.getValues('district')
  const province = mainFormValues.getValues('province')
  const website = mainFormValues.getValues('website')
  const merchantId = mainFormValues.getValues('merchantId')
  const serviceId = mainFormValues.getValues('serviceId')
  const smsServiceEnable = mainFormValues.getValues('smsServiceEnable')
  const emailServiceEnable = mainFormValues.getValues('emailServiceEnable')
  const phoneNumber = mainFormValues.getValues('phoneNumber')
  const email = mainFormValues.getValues('email')
  const logo = mainFormValues.getValues('logo')
  const status = mainFormValues.getValues('status')
  const contactPersonDetails = mainFormValues.getValues('contactPersonDetails')
  const note = mainFormValues.getValues('note')
  const createdAt = mainFormValues.getValues('createdAt')
  const createdBy = mainFormValues.getValues('createdBy')
  const updatedAt = mainFormValues.getValues('updatedAt')
  const updatedBy = mainFormValues.getValues('updatedBy')

  useEffect(() => {
    setCurrentPage(currentPage)
    const tempData = chunk(contactDetail, ROWS_PER_PAGE)
    console.log('contactDetail?!', contactDetail)
    console.log('here?!', tempData)
    if (currentPage < 1 || currentPage > tempData.length) {
      setPaginatedContactData([])
    }
    setPaginatedContactData(tempData[currentPage - 1])
  }, [contactDetail, currentPage])

  return (
    <>
      <Box bg={'#ECEFF1'} p={10} borderRadius="12px">
        <Box
          bg={'white'}
          p={10}
          overflowX="auto"
          maxWidth="100Vw"
          borderRadius="12px"
        >
          <Heading
            as="h2"
            fontSize="22px"
            color="#37474F"
            fontWeight="500"
            mb={5}
          >
            View Agency
          </Heading>

          <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Organization Agency ID
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {governemtAgencyId}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Organization Agency Name
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {fullName}
                  </dt>
                </Box>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Short Name
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {shortName}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Description
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {description}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Address Line 1
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {addressLine1}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Address Line 2
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {addressLine2}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Divisional Secretariat
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {city}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  District
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {district}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Province
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {province}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Website
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {website}
                  </dt>
                </Box>

                {/* <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Phone Number
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {phoneNumber}
                </dt>
              </div> */}

                {/* <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Merchant ID
                </dd>
                <dt style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}>
                  {merchantId}
                </dt> */}

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Service ID
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {serviceId}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Phone Numbers
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {phoneNumber === ''
                      ? '-'
                      : phoneNumber.replace(/^(\+\d{1,2})/, '0')}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Emails
                </dd>
                <Box w={['60%', '100%', '160%']} overflowX="auto">
                  <dt
                    style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                  >
                    {email === '' ? '-' : email}
                  </dt>
                </Box>

                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Enable Services
                </dd>
                {/* <CheckboxGroup> */}
                <Stack spacing={[1, 5]} direction={['column', 'row']}>
                  <Checkbox value="SMS" isDisabled isChecked={smsServiceEnable}>
                    SMS
                  </Checkbox>
                  <Checkbox
                    value="Email"
                    isDisabled
                    isChecked={emailServiceEnable}
                  >
                    Email
                  </Checkbox>
                </Stack>
                {/* </CheckboxGroup> */}
              </SimpleGrid>

              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px" h={400}>
                <Box></Box>
                <Box>
                  {status === 'Active' ? (
                    <Center>
                      <Tag
                        size="lg"
                        variant="subtle"
                        colorScheme="cyan"
                        w="100%"
                        p={3}
                        justifyContent="center"
                      >
                        ACTIVE
                      </Tag>
                    </Center>
                  ) : (
                    <Center>
                      <Tag
                        size="lg"
                        variant="subtle"
                        colorScheme="red"
                        w="100%"
                        p={3}
                        justifyContent="center"
                      >
                        INACTIVE
                      </Tag>
                    </Center>
                  )}
                </Box>
                <Box></Box>
                <Box h="400px">
                  <Center>
                    <Box bg={'white'} h="200px" w="200px">
                      <Image
                        h="200px"
                        w="200px"
                        src={logo}
                        fallbackSrc={DefaultAgencyImage}
                        objectFit="cover"
                        alt="agency.logo"
                      />
                    </Box>
                  </Center>
                </Box>
                <Box></Box>
              </SimpleGrid>
            </SimpleGrid>

            <Box bg={'#ECEFF1'} p={5} mt={10} borderRadius="12px">
              <DataTable
                heading="Contact Person Details"
                rowValues={paginatedContactData ? paginatedContactData : []}
                cols={[
                  {
                    Header: 'No.',
                    accessor: 'rowNumber',
                    disableSortBy: true,
                    minWidth: 80,
                    width: 80,
                    maxWidth: 100,
                  },
                  {
                    Header: 'Contact Person',
                    accessor: 'personName',
                    disableSortBy: true,
                    maxWidth: 400,
                    minWidth: 140,
                  },
                  {
                    Header: 'Designation',
                    accessor: 'designation',
                    disableSortBy: true,
                    maxWidth: 400,
                    minWidth: 140,
                  },
                  {
                    Header: 'Phone Number',
                    accessor: 'mobile',
                    disableSortBy: true,
                    maxWidth: 150,
                    minWidth: 140,
                    Cell: ({ value }: { value: string }) =>
                      `0${value.slice(3)}`,
                  },
                  {
                    Header: 'Email',
                    accessor: 'email',
                    disableSortBy: true,
                    maxWidth: 400,
                    minWidth: 140,
                  },
                ]}
              />
              {contactDetail.length === 0 ? (
                <Center mt={5}>No results found</Center>
              ) : null}
              {contactDetail.length !== 0 ? (
                <Center mt={5}>
                  <Pagination
                    currentPage={currentPage}
                    pageSize={ROWS_PER_PAGE}
                    totalCount={contactDetail.length}
                    onPageChange={setCurrentPage}
                  />
                </Center>
              ) : null}
            </Box>

            <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px" mt={10}>
              <Grid h="100px" templateColumns="repeat(4, 1fr)" gap={10}>
                <GridItem colSpan={1}>
                  <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                    Note
                  </dd>
                </GridItem>
                <GridItem colSpan={3}>
                  {' '}
                  <dt
                    style={{
                      flex: '50%',
                      maxWidth: '100%',
                    }}
                  >
                    {note}
                  </dt>
                </GridItem>
              </Grid>
              <SimpleGrid
                columns={{ sm: 1, md: 2 }}
                spacing="10px"
              ></SimpleGrid>
            </SimpleGrid>

            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="20px">
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Created at
                </dd>
                <dt style={{ flex: '50%', padding: '10px' }}>
                  {formatDate(createdAt)}
                </dt>
              </SimpleGrid>

              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Updated at
                </dd>
                <dt style={{ flex: '50%', padding: '10px' }}>
                  {formatDate(updatedAt)}
                </dt>
              </SimpleGrid>

              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Created by
                </dd>
                <dt style={{ flex: '50%', padding: '10px' }}>{createdBy}</dt>
              </SimpleGrid>

              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="10px">
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  Updated by
                </dd>
                <dt style={{ flex: '50%', padding: '10px' }}>{updatedBy}</dt>
              </SimpleGrid>
            </SimpleGrid>
            <Center>
              <ButtonGroup variant="outline" spacing="2" padding={10}>
                <Button onClick={() => navigate('/agency')}>Back</Button>
                <Button onClick={() => navigate(`/agency/update/${agencyId}`)}>
                  Update
                </Button>
              </ButtonGroup>
            </Center>
          </form>
        </Box>
      </Box>
    </>
  )
}
