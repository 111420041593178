import { memo, useCallback } from 'react'
import { BiDownload } from 'react-icons/bi'
import {
  Box,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'

import { BasicField } from '~shared/types'

import Button from '~components/Button'
import FormLabel from '~components/FormControl/FormLabel'
import Spinner from '~components/Spinner'

import { AugmentedDecryptedResponse } from '~features/admin-form/responses/augmentDecryptedResponses'

import { useMutateDownloadAttachments } from './mutations'

export interface DecryptedRowBaseProps {
  row: AugmentedDecryptedResponse
}
type DecryptedRowProps = DecryptedRowBaseProps & {
  secretKey: string
}

const standardFontSize = '12pt'

const DecryptedQuestionLabel = ({ row }: DecryptedRowBaseProps) => {
  return (
    <FormLabel
      questionNumber={`${row.questionNumber}.`}
      fontSize={standardFontSize}
      maxW="30rem"
      isRequired
      fontWeight="bold"
      mb="0"
    >
      {`${row.signature ? '[verified] ' : ''}${row.question}`}
    </FormLabel>
  )
}

const DecryptedHeaderRow = ({ row }: DecryptedRowBaseProps): JSX.Element => {
  return (
    <Text
      textStyle="h2"
      as="h2"
      mb="0.5rem"
      _notFirst={{ mt: '2.5rem' }}
      fontSize={standardFontSize}
      fontWeight="bold"
    >
      {row.question}
    </Text>
  )
}

const DecryptedTableRow = ({ row }: DecryptedRowBaseProps): JSX.Element => {
  return (
    <Stack>
      <DecryptedQuestionLabel row={row} />
      <Table
        variant="column-stripe"
        sx={{ tableLayout: 'fixed' }}
        fontSize={standardFontSize}
        maxW="52%"
        style={{
          border: '1px solid rgb(0, 0, 0)',
        }}
      >
        <Thead
          style={{
            border: '1px solid rgb(0, 0, 0)',
          }}
        >
          <Tr
            style={{
              border: '1px solid rgb(0, 0, 0)',
            }}
          >
            {row.columnTitles?.map((title) => (
              <Th
                style={{
                  border: '1px solid rgb(0, 0, 0)',
                }}
                fontSize={standardFontSize}
              >
                <Text as="b">{title}</Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody
          style={{
            border: '1px solid rgb(0, 0, 0)',
          }}
        >
          {row.answerArray?.map((row, idx) => (
            <Tr
              style={{
                border: '1px solid rgb(0, 0, 0)',
              }}
              fontSize={standardFontSize}
              key={idx}
            >
              {Array.isArray(row) ? (
                row.map((col, cidx) => (
                  <Td
                    style={{
                      border: '1px solid rgb(0, 0, 0)',
                    }}
                    key={cidx}
                  >
                    {col}
                  </Td>
                ))
              ) : (
                <Td
                  style={{
                    border: '1px solid rgb(0, 0, 0)',
                  }}
                  fontSize={standardFontSize}
                >
                  {row}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  )
}

const DecryptedAttachmentRow = ({ row, secretKey }: DecryptedRowProps) => {
  const { downloadAttachmentMutation } = useMutateDownloadAttachments()

  const handleDownload = useCallback(() => {
    if (!row.downloadUrl || !row.answer) return
    return downloadAttachmentMutation.mutate({
      url: row.downloadUrl,
      secretKey,
      fileName: row.answer,
    })
  }, [downloadAttachmentMutation, secretKey, row])

  return (
    <Stack>
      <DecryptedQuestionLabel row={row} />
      <Text fontSize={standardFontSize} textStyle="body-1">
        <Text as="b">Filename: </Text>
        {row.answer && (
          <Button
            variant="link"
            aria-label="Download file"
            isDisabled={downloadAttachmentMutation.isLoading}
            onClick={handleDownload}
            rightIcon={
              downloadAttachmentMutation.isLoading ? (
                <Spinner fontSize="1.5rem" />
              ) : (
                <BiDownload fontSize="1.5rem" />
              )
            }
          >
            {row.answer}
          </Button>
        )}
      </Text>
    </Stack>
  )
}

export const DecryptedRow = memo(
  ({ row, secretKey }: DecryptedRowProps): JSX.Element => {
    switch (row.fieldType) {
      case BasicField.Section:
        return <DecryptedHeaderRow row={row} />
      case BasicField.Attachment:
        return <DecryptedAttachmentRow row={row} secretKey={secretKey} />
      case BasicField.Table:
        return <DecryptedTableRow row={row} />
      default:
        return (
          <Stack>
            {row.answer !== '' ? (
              <>
                <DecryptedQuestionLabel row={row} />
                <Box pl={4} mt="0 !important">
                  {row.answer && (
                    <Text
                      fontSize={standardFontSize}
                      maxW="30rem"
                      pb="5px"
                      borderWidth="1px"
                      borderColor="gray.400"
                      borderStyle="hidden hidden dashed hidden"
                    >
                      {row.answer}
                    </Text>
                  )}
                  {row.answerArray && (
                    <Text
                      fontSize={standardFontSize}
                      maxW="30rem"
                      pb="5px"
                      borderWidth="1px"
                      borderColor="gray.400"
                      borderStyle="hidden hidden dashed hidden"
                    >
                      {row.answerArray.join(', ')}
                    </Text>
                  )}
                </Box>
              </>
            ) : null}
          </Stack>
        )
    }
  },
)
