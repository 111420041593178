import { z } from 'zod'
import { PublicUserDto, UserDto } from '../user'
import { FormField, FormFieldDto } from '../field'

import { FormLogo } from './form_logo'
import { Merge, Opaque, PartialDeep } from 'type-fest'
import {
  ADMIN_FORM_META_FIELDS,
  EMAIL_FORM_SETTINGS_FIELDS,
  EMAIL_PUBLIC_FORM_FIELDS,
  STORAGE_FORM_SETTINGS_FIELDS,
  STORAGE_PUBLIC_FORM_FIELDS,
} from '../../constants/form'
import { DateString } from '../generic'
import { FormLogic, LogicDto } from './form_logic'
import { AgencyDto } from '../agency'
import { NotificationType } from '../agency_notification'

export type FormId = Opaque<string, 'FormId'>
export const FormId = z.string() as unknown as z.Schema<FormId>

export enum FormColorTheme {
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
  Orange = 'orange',
  Brown = 'brown',
  Grey = 'grey',
}

export type FormPermission = {
  id?: string
  email: string
  write: boolean
}

export type FormStartPage = {
  paragraph?: string
  estTimeTaken?: number
  colorTheme: FormColorTheme
  logo: FormLogo
}

export type FormEndPage = {
  title: string
  paragraph?: string
  buttonLink?: string
  buttonText: string
}

// Note in Digital Government Forms, NIL and WSO2 are used
export enum FormAuthType {
  NIL = 'NIL',
  SP = 'SP',
  CP = 'CP',
  MyInfo = 'MyInfo',
  SGID = 'SGID',
  WSO2 = 'WSO2',
}

export enum FormStatus {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Archived = 'ARCHIVED',
}

export enum FormStatusTypes {
  Draft = 'Draft',
  Published = 'Published',
  Unpublished = 'Unpublished',
}

export enum AfterExpierTypeCode {
  UPB = 'UPB',
  ACH = 'ACH',
}

export enum AfterExpierTypeName {
  UnpublishedAfterExpiry = 'Unpublished After Expiry',
  ArchivedAfterExpiry = 'Archived After Expiry',
}

export type AfterExpierType = {
  code: AfterExpierTypeCode
  name: AfterExpierTypeName
}

export type SubmissionRetentionPeriodType = {
  years: number
  months: number
  days: number
}

export enum NotificationValueForSms {
  Default = 'Default',
  Yes = 'Yes',
  No = 'No',
}

export type SmsNotificationEnableType = {
  notificationType: NotificationType
  value: NotificationValueForSms
}

export enum NotificationValueForEmail {
  Default = 'Default',
  Yes = 'Yes',
  No = 'No',
}

export type EmailNotificationEnableType = {
  notificationType: NotificationType
  value: NotificationValueForEmail
}

// enum userCategoryIdType {
//   P = 'Public User',
//   A = 'Agency User',
//   I = 'ITCA User',
// }

export type CreatedByType = {
  userCategoryId: string
  userId: UserDto['_id']
}

export type FormWebhook = {
  url: string
  isRetryEnabled: boolean
}

export enum PaymentChargeType {
  FORM_CHARGE = 'FORM_CHARGE',
}

export type PaymentCharges = {
  type: PaymentChargeType
  name: string
  amount: number
  required: boolean
}

export enum FormResponseMode {
  Encrypt = 'encrypt',
  Email = 'email',
}
export enum FormLanguages {
  English = 'English',
  Sinhala = 'Sinhala',
  Tamil = 'Tamil',
}

export interface FormBase {
  title: string
  admin: UserDto['_id']
  agencyId: AgencyDto['_id']

  form_fields: FormField[]
  form_logics: FormLogic[]
  permissionList: FormPermission[]

  startPage: FormStartPage
  endPage: FormEndPage

  hasCaptcha: boolean
  template: boolean
  templateName?: string
  templateDescription?: string
  authType: FormAuthType

  status: FormStatusTypes

  inactiveMessage: string
  submissionLimit: number | null
  isListed: boolean

  esrvcId?: string

  msgSrvcName?: string

  webhook: FormWebhook

  responseMode: FormResponseMode
  description?: string
  archived: boolean
  archivedDate?: Date
  restoredAt?: Date
  paymentRequired: boolean
  paymentCharges?: PaymentCharges[]
  formExpireDate?: Date
  afterExpier?: AfterExpierType
  displayCitizenPortal: boolean
  authPublicUrl: boolean
  formLanguage?: FormLanguages[]
  submissionRetentionPeriod?: SubmissionRetentionPeriodType
  smsNotificationEnable?: SmsNotificationEnableType[]
  emailNotificationEnable?: EmailNotificationEnableType[]
  // smsServiceEnable: boolean
  // emailServiceEnable: boolean
  deleted: boolean
  deletedDate?: Date
  createdBy?: CreatedByType
  lastModifiedBy?: CreatedByType
  enableSerialNumber: boolean
  serialNumberPrefix?: string
  serialNumberMinLength?: number
  runningSerialNumber: number
  signatureRequired?: boolean
  signatureTypes?: SignatureType[]
}

export interface EmailFormBase extends FormBase {
  responseMode: FormResponseMode.Email
  emails: string[]
  encryptedKey: string
}

export interface StorageFormBase extends FormBase {
  responseMode: FormResponseMode.Encrypt
  publicKey: string
  encryptedKey: string
}

/**
 * Additional props to be added/replaced when tranformed into DTO.
 */
type FormDtoBase = {
  _id: FormId
  form_fields: FormFieldDto[]
  form_logics: LogicDto[]
  created: DateString
  lastModified: DateString
}

export type DashboardFormDto = {
  formId: string
  formName: string
  description?: string
  agencyId: string
  agencyName: string
}

export type DashboardFormSubmissionDto = {
  formId: string
  formName: string
  agencyId: string
  agencyName: string
  submissionId: string
  serialNumber?: string
  submissionDateTime: any
  submissionStatus: string
  paymentStatus?: string
}

export type StorageFormDto = Merge<StorageFormBase, FormDtoBase>

export type EmailFormDto = Merge<EmailFormBase, FormDtoBase>

export type FormDto = StorageFormDto | EmailFormDto

export type AdminStorageFormDto = Merge<StorageFormDto, { admin: UserDto }>
export type AdminEmailFormDto = Merge<EmailFormDto, { admin: UserDto }>
export type AdminFormDto = AdminStorageFormDto | AdminEmailFormDto

type PublicFormBase = {
  admin: PublicUserDto
}

export type PublicStorageFormDto = Merge<
  Pick<
    StorageFormDto,
    // Arrays like typeof list have numeric index signatures, so their number key
    // yields the union of all numerically-indexed properties.
    typeof STORAGE_PUBLIC_FORM_FIELDS[number]
  >,
  PublicFormBase
>

export type PublicEmailFormDto = Merge<
  Pick<
    EmailFormDto,
    // Arrays like typeof list have numeric index signatures, so their number key
    // yields the union of all numerically-indexed properties.
    typeof EMAIL_PUBLIC_FORM_FIELDS[number]
  >,
  PublicFormBase
>

export type PublicFormDto = PublicStorageFormDto | PublicEmailFormDto

export type EmailFormSettings = Merge<
  Pick<
    EmailFormDto,
    // Arrays like typeof list have numeric index signatures, so their number key
    // yields the union of all numerically-indexed properties.
    typeof EMAIL_FORM_SETTINGS_FIELDS[number]
  >,
  { admin: UserDto }
>

export type StorageFormSettings = Merge<
  Pick<
    StorageFormDto,
    // Arrays like typeof list have numeric index signatures, so their number key
    // yields the union of all numerically-indexed properties.
    typeof STORAGE_FORM_SETTINGS_FIELDS[number]
  >,
  { admin: UserDto }
>

export type FormSettings = EmailFormSettings | StorageFormSettings

export type SettingsUpdateDto = PartialDeep<FormSettings>

/**
 * Misnomer. More of a public form auth session.
 */
export interface SpcpSession {
  userName: string
  iat?: number // Optional as these are not returned for MyInfo forms
  rememberMe?: boolean
  exp?: number
}

export type PublicFormViewDto = {
  form: PublicFormDto
  spcpSession?: SpcpSession
  isIntranetUser?: boolean
  myInfoError?: true
}

export type PreviewFormViewDto = Pick<PublicFormViewDto, 'form' | 'spcpSession'>

export type SmsCountsDto = {
  quota: number
  freeSmsCounts: number
}

export type AdminFormViewDto = {
  form: AdminFormDto
}

export type AdminDashboardFormMetaDto = Pick<
  AdminFormDto,
  typeof ADMIN_FORM_META_FIELDS[number]
>

export type DuplicateFormBodyDto = {
  title: string
  description?: string
  formLanguage?: FormLanguages[]
  template: boolean
  templateName?: string
  templateDescription?: string
  enableSerialNumber: boolean
  serialNumberPrefix?: string
  serialNumberMinLength?: number
} & (
  | {
      responseMode: FormResponseMode.Email
      emails: string | string[]
    }
  | {
      responseMode: FormResponseMode.Encrypt
      publicKey: string
      encryptedKey: string
    }
)

export type CreateEmailFormBodyDto = Pick<
  EmailFormDto,
  | 'emails'
  | 'responseMode'
  | 'title'
  | 'formLanguage'
  | 'enableSerialNumber'
  | 'serialNumberPrefix'
  | 'serialNumberMinLength'
>
export type CreateStorageFormBodyDto = Pick<
  StorageFormDto,
  | 'publicKey'
  | 'responseMode'
  | 'title'
  | 'formLanguage'
  | 'enableSerialNumber'
  | 'serialNumberPrefix'
  | 'serialNumberMinLength'
>

export type CreateTemplateBodyDto = Pick<
  StorageFormDto,
  | 'publicKey'
  | 'responseMode'
  | 'title'
  | 'formLanguage'
  | 'template'
  | 'enableSerialNumber'
  | 'serialNumberPrefix'
  | 'serialNumberMinLength'
>

export type CreateFormBodyDto =
  | CreateEmailFormBodyDto
  | CreateStorageFormBodyDto
  | CreateTemplateBodyDto

export type PublicPortalUrl = {
  url: string
}

export type EndPageUpdateDto = FormEndPage
export type StartPageUpdateDto = FormStartPage
export type FormPermissionsDto = FormPermission[]
export type PermissionsUpdateDto = FormPermission[]

export enum SignatureType {
  FullName = 'FullName',
  Touchpad = 'Touchpad',
  Image = 'Image',
}

export type UpdateFormBodyDto = Pick<StorageFormDto, 'runningSerialNumber'>
