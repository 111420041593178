import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { Link } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Spacer,
} from '@chakra-ui/react'
import { isEmpty, isString, trimStart } from 'lodash'
import { useRecoilState, useRecoilValue } from 'recoil'

import DefaultUserImage from '~assets/images/default-user.png'
import {
  CITIZEN_DASHBOARD_ROUTE,
  CITIZEN_UPDATE_USER_PROFILE,
} from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'

import { LanguageFilter } from '../../../../atom'

export const CitizenViewUserProfile = (): JSX.Element | null => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const toast = useToast()
  const [isloggedIdSet, setIsloggedIdSet] = useState<boolean>(false)
  const [loggedUserId, setLoggedUserId] = useState<string>('')
  const formatDate = (value: string): string => {
    const date = new Date(value)
    const day = date.toLocaleString('default', { day: '2-digit' })
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.toLocaleString('default', { year: 'numeric' })
    const time = date.toLocaleTimeString()
    return day + '/' + month + '/' + year + ' ' + time
  }

  const [result, setResult] = useState({
    _id: '',
    username: '',
    title: '',
    gender: '',
    firstName: '',
    lastName: '',
    identificationType: '',
    preferredLanguage: '',
    signupSource: '',
    nameInitials: '',
    nicNo: '',
    passportNo: '',
    drivingLicenseNo: '',
    passportCountry: '',
    contact: '',
    email: '',
    profPicture: '',
    userCategory: {
      code: '',
      name: '',
    },
    agency: {
      emailDomain: [],
      phoneNo: [],
      email: [],
      _id: '',
      shortName: '',
      fullName: '',
      logo: '',
      contactPerson: [],
    },
    userRole: {
      code: '',
      name: '',
    },
    createdBy: {
      userCategoryCode: '',
      userCategoryName: '',
      userId: '',
      userName: '',
    },
    lastModifiedBy: {
      userId: '',
      userName: '',
      userCategoryCode: '',
      userCategoryName: '',
    },
    createdAt: '',
    lastModifiedAt: '',
    _v: 0,
    lastAccessed: '',
    previouslyAccessed: '',
  })

  useEffect(() => {
    // Populate the current user details. Taking from the local store.
    handleGetUser()
  }, [])

  const handleGetUser = async () => {
    // Find logged in user
    const loggedUser = localStorage.getItem('user')
    if (isString(loggedUser) && !isEmpty(loggedUser)) {
      const loggedUserDetails = JSON.parse(loggedUser)

      if (loggedUserDetails?._doc?._id) {
        setLoggedUserId(loggedUserDetails?._doc?._id)
        setIsloggedIdSet(true)
      }
    } else {
      // TODO: Should not proceed if user is unavailable
      toast.closeAll()
      toast({
        status: 'danger',
        description: 'User details not found',
        position: 'top',
      })
    }
  }

  async function getUserData() {
    if (isloggedIdSet) {
      ApiService.get(`/public/users/${loggedUserId}`)
        .then((data: any) => {
          if (data?.status === 200) {
            setResult(data?.data?.user)
            setIsLoaded(true)
          }
        })
        .catch((error) => {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        })
    }
  }

  useEffect(() => {
    const response = getUserData()
  }, [loggedUserId, isloggedIdSet])

  const mainFormValues = useForm({
    defaultValues: {
      userRole: '-',
      userActive: '-',
      userName: '-',
      memberNo: '-',
      title: '-',
      gender: '-',
      firstName: '-',
      lastName: '-',
      nameInitials: '-',
      idType: '-',
      idNo: '-',
      passportCountry: '-',
      preferredLanguage: '-',
      phoneNumber: '-',
      signupSource: '-',
      email: '-',
      createdAt: '-',
      createdBy: '-',
      updatedAt: '-',
      updatedBy: '-',
      lastLogged: '-',
      previouslyAccessed: '-',
      profPicture: '-',
    },
  })

  useEffect(() => {
    mainFormValues.setValue(
      'userRole',
      result?.userRole?.name ? result?.userRole?.name : '-',
    )
    mainFormValues.setValue(
      'userActive',
      result?.userRole?.name === 'A' ? 'Active' : 'Inactive',
    )
    mainFormValues.setValue(
      'userName',
      result?.username ? result?.username : '-',
    )
    mainFormValues.setValue('memberNo', result?._id ? result?._id : '-')
    mainFormValues.setValue('title', result?.title ? result?.title : '-')
    mainFormValues.setValue('gender', result?.gender ? result?.gender : '-')
    mainFormValues.setValue(
      'firstName',
      result?.firstName ? result?.firstName : '-',
    )
    mainFormValues.setValue(
      'lastName',
      result?.lastName ? result?.lastName : '-',
    )
    mainFormValues.setValue(
      'nameInitials',
      result?.nameInitials ? result?.nameInitials : '-',
    )
    mainFormValues.setValue(
      'idType',
      result?.identificationType
        ? result?.identificationType === 'N'
          ? 'NIC'
          : result?.identificationType === 'P'
          ? 'Passport'
          : 'Driving License'
        : '-',
    )
    // mainFormValues.setValue('idNo', result?.nicNo ? result?.nicNo : '-')
    mainFormValues.setValue(
      'idNo',
      result?.identificationType === 'N'
        ? result?.nicNo
          ? result?.nicNo
          : '-'
        : result?.identificationType === 'P'
        ? result?.passportNo
          ? result?.passportNo
          : '-'
        : result?.drivingLicenseNo
        ? result?.drivingLicenseNo
        : '-',
    )
    mainFormValues.setValue(
      'passportCountry',
      result?.passportCountry ? result?.passportCountry : '-',
    )
    mainFormValues.setValue(
      'preferredLanguage',
      result?.preferredLanguage ? result?.preferredLanguage : '-',
    )
    mainFormValues.setValue(
      'phoneNumber',
      result?.contact ? result?.contact : '-',
    )
    mainFormValues.setValue('email', result?.email ? result?.email : '-')
    mainFormValues.setValue(
      'profPicture',
      result?.profPicture ? result?.profPicture : '-',
    )

    mainFormValues.setValue(
      'createdAt',
      result?.createdAt ? result?.createdAt : '-',
    )
    mainFormValues.setValue(
      'createdBy',
      result?.createdBy?.userName ? result?.createdBy?.userName : '-',
    )
    mainFormValues.setValue(
      'updatedAt',
      result?.lastModifiedAt ? result?.lastModifiedAt : '-',
    )
    mainFormValues.setValue(
      'signupSource',
      result?.signupSource ? result?.signupSource : '-',
    )
    mainFormValues.setValue(
      'createdBy',
      result?.lastModifiedBy?.userName ? result?.lastModifiedBy?.userName : '-',
    )
    mainFormValues.setValue(
      'lastLogged',
      result?.lastAccessed ? result?.lastAccessed : '-',
    )
    mainFormValues.setValue(
      'previouslyAccessed',
      result?.previouslyAccessed
        ? result?.previouslyAccessed
        : new Date().toISOString(),
    )
  }, [result])

  const userName = mainFormValues.getValues('userName')
  const title = mainFormValues.getValues('title')
  const gender = mainFormValues.getValues('gender')
  const firstName = mainFormValues.getValues('firstName')
  const lastName = mainFormValues.getValues('lastName')
  const nameInitials = mainFormValues.getValues('nameInitials')
  const idType = mainFormValues.getValues('idType')
  const idNo = mainFormValues.getValues('idNo')
  const signupSource = mainFormValues.getValues('signupSource')
  const passportCountry = mainFormValues.getValues('passportCountry')
  const preferredLanguage = mainFormValues.getValues('preferredLanguage')
  const phoneNumber = mainFormValues.getValues('phoneNumber')
  const email = mainFormValues.getValues('email')
  const profPicture = mainFormValues.getValues('profPicture')
  const lastLogged = mainFormValues.getValues('lastLogged')
  const previouslyAccessed = mainFormValues.getValues('previouslyAccessed')
  const locale: any = useRecoilValue(LanguageFilter)

  const messages: any = {
    en: {
      viewUserProfile: 'View User Profile',
      personalDetails: 'Personal Details',
      username: 'Username:',
      title: 'Title:',
      gender: 'Gender:',
      firstName: 'First Name:',
      lastName: 'Last Name:',
      namewithInitials: 'Name with Initials:',
      identificationType: 'Identification Type:',
      identificationNumber: 'Identification Number:',
      countryofPassport: 'Country of Passport:',
      preferredLanguage: 'Preferred Language:',
      contactDetails: 'Contact Details',
      phoneNumber: 'Phone Number:',
      emailAddress: 'Email Address:',
      otherDetails: 'Other Details',
      signUpvia: 'Sign Up via:',
      lastLoggedat: 'Last Logged at:',
      back: 'Back',
      update: 'Update',
      signupSelf: 'self',
    },
    si: {
      viewUserProfile: 'පරිශීලක පැතිකඩ බලන්න',
      personalDetails: 'පුද්ගලික තොරතුරු',
      username: 'පරිශීලක නාමය:',
      title: 'මාතෘකාව:',
      gender: 'ස්ත්‍රි පුරුෂ බාවය:',
      firstName: 'මුල් නම:',
      lastName: 'අවසන් නම:',
      namewithInitials: 'මුලකුරු සමඟ නම:',
      identificationType: 'අනන්‍යතාවයේ වර්ගය:',
      identificationNumber: 'හඳුනාගැනීමේ අංකය:',
      countryofPassport: 'විදේශ ගමන් බලපත්‍රය නිකුත් කල රට:',
      preferredLanguage: 'කැමති භාෂාව:',
      contactDetails: 'ඇමතුම් විස්තර',
      phoneNumber: 'දුරකථන අංකය:',
      emailAddress: 'විද්යුත් තැපැල් ලිපිනය:',
      otherDetails: 'වෙනත් විස්තර',
      signUpvia: 'හරහා ලියාපදිංචි වන්න:',
      lastLoggedat: 'අවසන් වරට ලොග් විය:',
      back: 'ආපසු',
      update: 'යාවත්කාලීන කරන්න',
      signupSelf: 'ස්වයං',
    },
    ta: {
      viewUserProfile: 'பயனர் சுயவிவரத்தைக் காண்க',
      personalDetails: 'தனிப்பட்ட விவரங்கள்',
      username: 'பயனர் பெயர்:',
      title: 'தலைப்பு:',
      gender: 'பாலினம்:',
      firstName: 'முதல் பெயர்:',
      lastName: 'கடைசி பெயர்:',
      namewithInitials: 'முதலெழுத்துக்களுடன் பெயர்:',
      identificationType: 'அடையாள வகை:',
      identificationNumber: 'அடையாள எண்:',
      countryofPassport: 'பாஸ்போர்ட் நாடு:',
      preferredLanguage: 'விருப்பமான மொழி:',
      contactDetails: 'தொடர்பு விபரங்கள்',
      phoneNumber: 'தொலைபேசி எண்:',
      emailAddress: 'மின்னஞ்சல் முகவரி:',
      otherDetails: 'வேறு தகவல்கள்',
      signUpvia: 'மூலம் பதிவு செய்யவும்:',
      lastLoggedat: 'கடைசியாக உள்நுழைந்தது:',
      back: 'மீண்டும்',
      update: 'புதுப்பிக்கவும்',
      signupSelf: 'சுய',
    },
  }

  return (
    <>
      <Box bg={'gray.50'} p={10}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <Flex>
            <Box height="40px">
              <Heading as="h4" size="md">
                <FormattedMessage
                  id="viewUserProfile"
                  values={{ locale }}
                ></FormattedMessage>
              </Heading>
            </Box>
            <Spacer />
            <Box p="4">
              <Button
                variant="solid"
                colorScheme="primary"
                as={Link}
                to={CITIZEN_UPDATE_USER_PROFILE}
              >
                <FormattedMessage
                  id="update"
                  values={{ locale }}
                ></FormattedMessage>
              </Button>
            </Box>
          </Flex>

          <Box
            borderRadius="5"
            border={{ xs: '0px', md: '1px' }}
            borderColor="black.200"
          >
            <Heading
              as="h4"
              size="md"
              padding="10px"
              color={{ xs: 'orange', md: 'black' }}
            >
              <FormattedMessage
                id="personalDetails"
                values={{ locale }}
              ></FormattedMessage>
            </Heading>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="60px">
              <dl>
                {result?.signupSource === 'self' ? (
                  <div style={{ display: 'flex' }}>
                    <dd
                      style={{ flex: '20%', padding: '10px', fontWeight: 500 }}
                    >
                      <FormattedMessage
                        id="username"
                        values={{ locale }}
                      ></FormattedMessage>
                    </dd>
                    <dt
                      style={{ flex: '50%', padding: '10px', maxWidth: '100%' }}
                    >
                      {userName}
                    </dt>
                  </div>
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <SimpleGrid
                    columns={{ xs: 1, md: 2 }}
                    spacing="0px"
                    width="50%"
                  >
                    <Box
                      maxWidth="100%"
                      overflowX="auto"
                      color={{ xs: 'gray', md: 'black' }}
                      fontSize={{ xs: 'sm', md: 'md' }}
                    >
                      <dd
                        style={{
                          flex: '20%',
                          padding: '10px',
                          fontWeight: 500,
                          width: '100%',
                        }}
                      >
                        <FormattedMessage
                          id="title"
                          values={{ locale }}
                        ></FormattedMessage>
                      </dd>
                    </Box>
                    <Box
                      w="100%"
                      overflowX="auto"
                      mt={{ xs: '-15px', md: '0px' }}
                      paddingLeft={{ xs: '0', md: '54' }}
                    >
                      <dt
                        style={{
                          flex: '50%',
                          padding: '10px',
                          maxWidth: '100%',
                        }}
                      >
                        {title}
                      </dt>
                    </Box>
                  </SimpleGrid>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <SimpleGrid
                    columns={{ xs: 1, md: 2 }}
                    spacing="0px"
                    width="50%"
                  >
                    <Box
                      overflowX="auto"
                      color={{ xs: 'gray', md: 'black' }}
                      fontSize={{ xs: 'sm', md: 'md' }}
                      maxWidth="100%"
                    >
                      <dd
                        style={{
                          flex: '20%',
                          padding: '10px',
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage
                          id="gender"
                          values={{ locale }}
                        ></FormattedMessage>
                      </dd>
                    </Box>
                    <Box
                      mt={{ xs: '-15px', md: '0px' }}
                      paddingLeft={{ xs: '0', md: '54' }}
                    >
                      <dt
                        style={{
                          flex: '50%',
                          padding: '10px',
                          maxWidth: '200%',
                          width: '200px',
                        }}
                      >
                        {gender == 'M' ? 'Male' : 'Female'}
                      </dt>
                    </Box>
                  </SimpleGrid>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <SimpleGrid
                    columns={{ xs: 1, md: 2 }}
                    spacing="0px"
                    width="50%"
                  >
                    <Box
                      maxWidth="100%"
                      overflowX="auto"
                      color={{ xs: 'gray', md: 'black' }}
                      fontSize={{ xs: 'sm', md: 'md' }}
                    >
                      <dd
                        style={{
                          flex: '20%',
                          padding: '10px',
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage
                          id="firstName"
                          values={{ locale }}
                        ></FormattedMessage>
                      </dd>
                    </Box>
                    <Box
                      mt={{ xs: '-15px', md: '0px' }}
                      paddingLeft={{ xs: '0', md: '54' }}
                    >
                      <dt
                        style={{
                          flex: '50%',
                          padding: '10px',
                          maxWidth: '200%',
                          width: '200px',
                        }}
                      >
                        {firstName}
                      </dt>
                    </Box>
                  </SimpleGrid>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <SimpleGrid
                    columns={{ xs: 1, md: 2 }}
                    spacing="0px"
                    width="50%"
                  >
                    <Box
                      maxWidth="100%"
                      overflowX="auto"
                      color={{ xs: 'gray', md: 'black' }}
                      fontSize={{ xs: 'sm', md: 'md' }}
                    >
                      <dd
                        style={{
                          flex: '20%',
                          padding: '10px',
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage
                          id="lastName"
                          values={{ locale }}
                        ></FormattedMessage>
                      </dd>
                    </Box>
                    <Box
                      mt={{ xs: '-15px', md: '0px' }}
                      paddingLeft={{ xs: '0', md: '54' }}
                    >
                      <dt
                        style={{
                          flex: '50%',
                          padding: '10px',
                          maxWidth: '200%',
                          width: '200px',
                        }}
                      >
                        {lastName}
                      </dt>
                    </Box>
                  </SimpleGrid>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <SimpleGrid
                    columns={{ xs: 1, md: 2 }}
                    spacing="0px"
                    width="50%"
                  >
                    <Box
                      maxWidth="100%"
                      overflowX="auto"
                      color={{ xs: 'gray', md: 'black' }}
                      fontSize={{ xs: 'sm', md: 'md' }}
                    >
                      <dd
                        style={{
                          flex: '20%',
                          padding: '10px',
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage
                          id="namewithInitials"
                          values={{ locale }}
                        ></FormattedMessage>
                      </dd>
                    </Box>
                    <Box
                      mt={{ xs: '-15px', md: '0px' }}
                      paddingLeft={{ xs: '0', md: '54' }}
                    >
                      <dt
                        style={{
                          flex: '50%',
                          padding: '10px',
                          maxWidth: '200%',
                          width: '200px',
                        }}
                      >
                        {nameInitials}
                      </dt>
                    </Box>
                  </SimpleGrid>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <SimpleGrid
                    columns={{ xs: 1, md: 2 }}
                    spacing="0px"
                    width="50%"
                  >
                    <Box
                      maxWidth="100%"
                      overflowX="auto"
                      color={{ xs: 'gray', md: 'black' }}
                      fontSize={{ xs: 'sm', md: 'md' }}
                    >
                      <dd
                        style={{
                          flex: '20%',
                          padding: '10px',
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage
                          id="identificationType"
                          values={{ locale }}
                        ></FormattedMessage>
                      </dd>
                    </Box>
                    <Box
                      mt={{ xs: '-15px', md: '0px' }}
                      paddingLeft={{ xs: '0', md: '54' }}
                    >
                      <dt
                        style={{
                          flex: '50%',
                          padding: '10px',
                          maxWidth: '200%',
                          width: '200px',
                        }}
                      >
                        {idType}
                      </dt>
                    </Box>
                  </SimpleGrid>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <SimpleGrid
                    columns={{ xs: 1, md: 2 }}
                    spacing="0px"
                    width="50%"
                  >
                    <Box
                      maxWidth="100%"
                      overflowX="auto"
                      color={{ xs: 'gray', md: 'black' }}
                      fontSize={{ xs: 'sm', md: 'md' }}
                    >
                      <dd
                        style={{
                          flex: '20%',
                          padding: '10px',
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage
                          id="identificationNumber"
                          values={{ locale }}
                        ></FormattedMessage>
                      </dd>
                    </Box>
                    <Box
                      mt={{ xs: '-15px', md: '0px' }}
                      paddingLeft={{ xs: '0', md: '54' }}
                    >
                      <dt
                        style={{
                          flex: '50%',
                          padding: '10px',
                          maxWidth: '200%',
                          width: '200px',
                        }}
                      >
                        {idNo}
                      </dt>
                    </Box>
                  </SimpleGrid>
                </div>
                <div>
                  {idType == 'Passport' ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      <SimpleGrid
                        columns={{ xs: 1, md: 2 }}
                        spacing="0px"
                        width="50%"
                      >
                        <Box
                          maxWidth="100%"
                          overflowX="auto"
                          color={{ xs: 'gray', md: 'black' }}
                          fontSize={{ xs: 'sm', md: 'md' }}
                        >
                          <dd
                            style={{
                              flex: '20%',
                              padding: '10px',
                              fontWeight: 500,
                            }}
                          >
                            <FormattedMessage
                              id="countryofPassport"
                              values={{ locale }}
                            ></FormattedMessage>
                          </dd>
                        </Box>
                        <Box
                          mt={{ xs: '-15px', md: '0px' }}
                          paddingLeft={{ xs: '0', md: '54' }}
                        >
                          <dt
                            style={{
                              flex: '50%',
                              padding: '10px',
                              maxWidth: '200%',
                              width: '200px',
                            }}
                          >
                            {passportCountry}
                          </dt>
                        </Box>
                      </SimpleGrid>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <SimpleGrid
                    columns={{ xs: 1, md: 2 }}
                    spacing="0px"
                    width="50%"
                  >
                    <Box
                      maxW="100%"
                      overflowX="auto"
                      color={{ xs: 'gray', md: 'black' }}
                      fontSize={{ xs: 'sm', md: 'md' }}
                    >
                      <dd
                        style={{
                          flex: '20%',
                          padding: '10px',
                          fontWeight: 500,
                        }}
                      >
                        <FormattedMessage
                          id="preferredLanguage"
                          values={{ locale }}
                        ></FormattedMessage>
                      </dd>
                    </Box>
                    <Box
                      mt={{ xs: '-15px', md: '0px' }}
                      paddingLeft={{ xs: '0', md: '54' }}
                    >
                      <dt
                        style={{
                          flex: '50%',
                          padding: '10px',
                          maxWidth: '200%',
                          width: '200px',
                        }}
                      >
                        {preferredLanguage}
                      </dt>
                    </Box>
                  </SimpleGrid>
                </div>
              </dl>

              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
                <Box h="200px" w="200px">
                  <Box bg={'#ECEFF1'} borderRadius="50%">
                    <Image
                      h="200px"
                      w="200px"
                      borderRadius="full"
                      src={profPicture}
                      fallbackSrc={DefaultUserImage}
                    />
                  </Box>
                </Box>
              </SimpleGrid>
            </SimpleGrid>
          </Box>
          <br />
          <Box
            borderRadius="5"
            border={{ xs: '0px', md: '1px' }}
            borderColor="black.200"
          >
            <Heading
              as="h4"
              size="md"
              padding="10px"
              color={{ xs: 'orange', md: 'black' }}
            >
              <FormattedMessage
                id="contactDetails"
                values={{ locale }}
              ></FormattedMessage>
            </Heading>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  <FormattedMessage
                    id="phoneNumber"
                    values={{ locale }}
                  ></FormattedMessage>
                </dd>
                <dt style={{ flex: '100%', padding: '10px', maxWidth: '160%' }}>
                  {phoneNumber.replace(/^(\+\d{1,2})/, '0')}
                </dt>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  <FormattedMessage
                    id="emailAddress"
                    values={{ locale }}
                  ></FormattedMessage>
                </dd>
                <dt style={{ flex: '100%', padding: '10px', maxWidth: '160%' }}>
                  {email}
                </dt>
              </div>
            </SimpleGrid>
          </Box>
          <br />
          <Box
            borderRadius="5"
            border={{ xs: '0px', md: '1px' }}
            borderColor="black.200"
          >
            <Heading
              as="h4"
              size="md"
              padding="10px"
              color={{ xs: 'orange', md: 'black' }}
            >
              <FormattedMessage
                id="otherDetails"
                values={{ locale }}
              ></FormattedMessage>
            </Heading>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  <FormattedMessage
                    id="signUpvia"
                    values={{ locale }}
                  ></FormattedMessage>
                </dd>
                <dt style={{ flex: '100%', padding: '10px', maxWidth: '160%' }}>
                  {signupSource === 'self' ? (
                    <FormattedMessage
                      id="signupSelf"
                      values={{ locale }}
                    ></FormattedMessage>
                  ) : (
                    signupSource
                  )}
                </dt>
              </div>

              <div style={{ display: 'flex' }}>
                <dd style={{ flex: '50%', padding: '10px', fontWeight: 500 }}>
                  <FormattedMessage
                    id="lastLoggedat"
                    values={{ locale }}
                  ></FormattedMessage>
                </dd>
                <dt style={{ flex: '100%', padding: '10px', maxWidth: '160%' }}>
                  {formatDate(previouslyAccessed)}
                </dt>
              </div>
            </SimpleGrid>
          </Box>
          <br />
          <Center>
            <ButtonGroup variant="outline" spacing="1" padding={10}>
              <Button as={Link} to={CITIZEN_DASHBOARD_ROUTE}>
                <FormattedMessage
                  id="back"
                  values={{ locale }}
                ></FormattedMessage>
              </Button>
            </ButtonGroup>
          </Center>
        </IntlProvider>
      </Box>
    </>
  )
}
