import validator from 'validator'

import { HttpError } from '~services/ApiService'

export const PHONE_NUMBER_REGEX_WITH_COMMAS = /^\+\d{8,11}(,\+\d{8,11})*$/
export const EMAIL_REGEX_WITH_COMMAS =
  /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/

export const SL_PHONE_NUMBER_REGEX =
  /^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/

export const SL_NIC_REGEX = /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/

export const SL_DRIVING_LICENSE_REGEX = /^([a-zA-Z0-9]{7,8})$/

export const PASSPORT_REGEX =
  /^(?:([A-Z])(?!.*\1)){1}(?:([A-Z\d])(?!.*\1)){4,19}$/

export const stringToArray = (value: string): string[] => {
  const array = value.split(',')
  if (array[array.length - 1] === '') {
    array.pop()
  }
  const returnedArray: string[] = []
  array.map((value) => {
    returnedArray.push(value?.trim())
  })
  return returnedArray
}

export const arrayToString = (array: string[]): string => {
  let label = ''
  if (Array.isArray(array)) {
    if (array.length > 0) {
      array.map((value) => {
        label = label + `${value}, `
      })
    } else {
      label = ''
    }
  }
  //remove last comma.
  if (label.substr(label.length - 2) === ', ') {
    label = label.substring(0, label.length - 2)
  }
  return label
}

export const fromObjectToQueryString = (obj: any): string => {
  let queryString = ''

  Object.keys(obj).forEach((key) => {
    queryString = queryString + `${key}=${obj[key]}&`
  })

  if (queryString.substr(queryString.length - 1) === '&') {
    queryString = queryString.substring(0, queryString.length - 1)
  }
  return queryString
}

export const getMappedErrorMessage = (error: Error): string => {
  // check if error is an instance of HttpError to be able to access status code of error
  if (error instanceof HttpError) {
    const errorMessage = `Error - ${error.message}`
    return errorMessage
  }
  // if error is not of type HttpError return the error message encapsulated in Error object
  return 'Something went wrong!'
}

export const validateImageSize = (file: File, bytes: number): boolean => {
  // validate to 2MB
  return file?.size < bytes
}

export const validateProfilePictureFormat = (url: string) => {
  const allowedExtension = ['jpeg', 'jpg', 'png']
  const fileExtension = url?.split('.')?.pop()?.toLowerCase()
  let isValidFile = false

  for (const index in allowedExtension) {
    if (fileExtension === allowedExtension[index]) {
      isValidFile = true
      break
    }
  }

  return isValidFile
}

export const handleEmailValidation = (emails: string): boolean | string => {
  const emailList = stringToArray(emails)
  const isValidEmail = emailList.filter(Boolean).every((e) => {
    if (!validator.isEmail(e)) {
      return false
    }
    return true
  })

  if (!isValidEmail) {
    return 'Invalid Email(s)'
  }

  let isDuplicateEmail = false
  if (!(new Set(emailList).size === emailList.length)) {
    isDuplicateEmail = false
    return 'Please remove duplicate emails.'
  }
  isDuplicateEmail = true

  return isValidEmail && isDuplicateEmail
}

export const handlePhoneValidation = (
  phoneNumbers: string,
): boolean | string => {
  const phoneNumberList = stringToArray(phoneNumbers)
  // ^07{1}\d{8}$
  // ^(?:0|94|\+94|0094)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$
  const isValidEmail = phoneNumberList.filter(Boolean).every((e) => {
    if (!SL_PHONE_NUMBER_REGEX.test(e)) {
      return false
    }
    return true
  })

  if (!isValidEmail) {
    return 'Invalid Phone Number(s)'
  }

  let isDuplicateEmail = false
  if (!(new Set(phoneNumberList).size === phoneNumberList.length)) {
    isDuplicateEmail = false
    return 'Please remove duplicate phone numbers.'
  }
  isDuplicateEmail = true

  return isValidEmail && isDuplicateEmail
}

export const convertToSriLankaNumber = (phoneNumbers: string[]): string[] => {
  const returnedArray: string[] = []

  phoneNumbers.map((value) => {
    if (value.length === 9) {
      // add +94
      const newNumber = '+94' + value
      returnedArray.push(newNumber)
    } else if (value.length === 10) {
      // remove 0 and add +94
      let newNumber = value.substring(1)
      newNumber = '+94' + newNumber
      returnedArray.push(newNumber)
    } else if (value.length === 11) {
      // add +
      const newNumber = '+' + value
      returnedArray.push(newNumber)
    } else {
      // return it
      returnedArray.push(value)
    }
  })

  return returnedArray
}
