import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import { extend, pick } from 'lodash'

import { MasterDataFieldBase } from '~shared/types/field'

import { useToast } from '~hooks/useToast'
import { createBaseValidationRules } from '~utils/fieldValidation'
import { ApiService } from '~services/ApiService'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import Input from '~components/Input'
import Textarea from '~components/Textarea'
import Toggle from '~components/Toggle'

import {
  SPLIT_TEXTAREA_TRANSFORM,
  SPLIT_TEXTAREA_VALIDATION,
} from '../common/constants'
import { DrawerContentContainer } from '../common/DrawerContentContainer'
import { FormFieldDrawerActions } from '../common/FormFieldDrawerActions'
import { EditFieldProps } from '../common/types'
import { useEditFieldForm } from '../common/useEditFieldForm'

type EditDropdownProps = EditFieldProps<MasterDataFieldBase>

const EDIT_DROPDOWN_FIELD_KEYS = ['title', 'description', 'required'] as const

type EditDropdownKeys = typeof EDIT_DROPDOWN_FIELD_KEYS[number]

type EditDropdownInputs = Pick<MasterDataFieldBase, EditDropdownKeys> & {
  fieldOptionsString: string // Differs from fieldOptions in DropdownFieldBase because input is a string. Will be converted to array using SPLIT_TEXTAREA_TRANSFORM
}

const transformDropdownFieldToEditForm = (
  field: MasterDataFieldBase,
): EditDropdownInputs => {
  return {
    ...pick(field, EDIT_DROPDOWN_FIELD_KEYS),
    fieldOptionsString: SPLIT_TEXTAREA_TRANSFORM.input(field.fieldOptions),
  }
}

const transformDropdownEditFormToField = (
  inputs: EditDropdownInputs,
  originalField: MasterDataFieldBase,
): MasterDataFieldBase => {
  console.log('Data saving is fired ', inputs.fieldOptionsString)

  return extend({}, originalField, inputs, {
    fieldOptions: SPLIT_TEXTAREA_TRANSFORM.output(inputs.fieldOptionsString),
  })
}

export const EditMasterData = ({ field }: EditDropdownProps): JSX.Element => {
  const {
    register,
    formState: { errors },
    isSaveEnabled,
    buttonText,
    setValue,
    handleUpdateField,
    isLoading,
    handleCancel,
  } = useEditFieldForm<EditDropdownInputs, MasterDataFieldBase>({
    field,
    transform: {
      input: transformDropdownFieldToEditForm,
      output: transformDropdownEditFormToField,
    },
  })

  const mainFormValues = useForm({
    defaultValues: {
      dataType: '',
    },
  })

  const requiredValidationRule = useMemo(
    () => createBaseValidationRules({ required: true }),
    [],
  )
  const toast = useToast()
  type masterDataTable = {
    value: string
    label: string
  }
  type allMasterDataTable = {
    value: string
    option: string
  }

  const optionLimit = 9999
  const [masterType, setMasterType] = useState<masterDataTable[]>([])
  const [allMasterType, setAllMasterType] = useState([])
  const [masterDataTypeList, setMasterDataTypeList] = useState<
    masterDataTable[]
  >([])

  async function getMasterData() {
    ApiService.get(`/master-data/type/`)
      .then((data: any) => {
        if (data?.status === 200) {
          mapDataToTable(data?.data)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const response = getMasterData()
  }, [])

  const mapDataToTable = (array: any[]) => {
    const currentDataTypeList: masterDataTable[] = []

    array.map((value, index) => {
      if (!currentDataTypeList.find((list) => list.value === value)) {
        const obj = {
          value: value,
          label: value,
        }
        currentDataTypeList.push(obj)
      }
    })

    setMasterDataTypeList(currentDataTypeList)
  }

  const mapDataToOption = (array: any[]) => {
    const currentDataOption: any = []
    array.map((value, index) => {
      const obj = {
        option: value.option,
      }
      currentDataOption.push(obj)
    })

    setAllMasterType(currentDataOption)
  }

  const selectedDataType = mainFormValues.watch('dataType')

  useEffect(() => {
    if (selectedDataType !== undefined && selectedDataType !== '') {
      handleDropdownChange(selectedDataType)
    }
  }, [selectedDataType])

  const handleDropdownChange = (value: any) => {
    ApiService.get(`/master-data/?masterType=${value}&page_size=${optionLimit}`)
      .then((data: any) => {
        if (data?.status === 200) {
          mapDataToOption(data?.data.data)
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    let optionList = ''
    allMasterType?.map((type: any) => {
      optionList = optionList ? optionList + '\n' + type.option : type.option
    })
    setValue('fieldOptionsString', optionList)
  }, [allMasterType])

  return (
    <DrawerContentContainer>
      <FormControl isRequired isReadOnly={isLoading} isInvalid={!!errors.title}>
        <FormLabel>Title</FormLabel>
        <Input autoFocus {...register('title', requiredValidationRule)} />
        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isReadOnly={isLoading} isInvalid={!!errors.description}>
        <FormLabel>Description</FormLabel>
        <Textarea {...register('description')} />
        <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isReadOnly={isLoading}>
        <Toggle {...register('required')} label="Required" />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Form Master Data Type</FormLabel>
        <Controller
          name="dataType"
          control={mainFormValues.control}
          render={({ field }) => (
            <SingleSelect {...field} items={masterDataTypeList} />
          )}
          rules={{ required: 'Master data type is required' }}
        />
      </FormControl>
      <FormControl
        isRequired
        isReadOnly={isLoading}
        isInvalid={!!errors.fieldOptionsString}
      >
        <FormLabel>Options</FormLabel>
        <Textarea
          {...register('fieldOptionsString', {
            validate: SPLIT_TEXTAREA_VALIDATION,
            required: 'Master Data options are required',
          })}
        />
        <FormErrorMessage>
          {errors?.fieldOptionsString?.message}
        </FormErrorMessage>
      </FormControl>
      <FormFieldDrawerActions
        isLoading={isLoading}
        isSaveEnabled={isSaveEnabled}
        buttonText={buttonText}
        handleClick={handleUpdateField}
        handleCancel={handleCancel}
      />
    </DrawerContentContainer>
  )
}
