import { memo, useEffect, useRef, useState } from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  GridItem,
  Heading,
  Link,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import { jsPDF } from 'jspdf'
import { isArray } from 'lodash'
import moment from 'moment-timezone'
import { useRecoilState, useRecoilValue } from 'recoil'

import { ActivityDto } from '~shared/types'

import { VIEW_CITIZEN } from '~constants/routes'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'

import { LanguageFilter } from '../../../../../atom'

import { ConversationMessageModel } from './ConversationMessageModel'
import { useMutateDownloadAttachments } from './mutations'
import {
  getSubmissionActivities,
  useIndividualSubmission,
  useSubmissionConversations,
  useSubmissionPayments,
} from './queries'

export const ViewSubmissionMeta = (): JSX.Element => {
  type activityTable = {
    rowNumber: number
    activityAt: string
    activityBy: string
    activityType: string
    reason: string
    note: string
  }

  const formatDate = (value: string): string => {
    const date = new Date(value)
    const day = date.toLocaleString('default', { day: '2-digit' })
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.toLocaleString('default', { year: 'numeric' })
    const time = date.toLocaleTimeString()
    return day + '/' + month + '/' + year + ' ' + time
  }

  const user = localStorage.getItem('user')
  const userData = user ? JSON.parse(user)._doc : {}
  const { formId, submissionId } = useParams()
  const toast = useToast()
  if (!formId) throw new Error('Missing formId')
  if (!submissionId) throw new Error('Missing submissionId')
  const navigate = useNavigate()

  const { data, isLoading, isError } = useIndividualSubmission()

  const submissionPayments = useSubmissionPayments()
  const submissionConversations = useSubmissionConversations(submissionId)
  const conversations = isArray(submissionConversations?.data)
    ? submissionConversations?.data
    : []
  const [activityTableData, setActivityTableData] = useState<activityTable[]>(
    [],
  )

  //Download PDF
  const ref = useRef<HTMLDivElement | null>(null)
  console.log(ref.current)
  const exportPdf = () => {
    const doc = new jsPDF('p', 'pt')
    savePdf(doc)
  }
  const savePdf = (doc: any) => {
    doc.html(ref.current, {
      callback: function (doc: any) {
        doc.save('view-submission.pdf')
      },
    })
  }

  const getSubmissionActivitiesData = async () => {
    ApiService.get(`/activity/?submissionId=${submissionId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          if (Array.isArray(data?.data?.activity)) {
            setActivityTableData(
              data?.data?.activity.map((value: ActivityDto, index: number) => {
                return {
                  rowNumber: index + 1,
                  activityAt: moment(value?.createdAt).format(
                    'DD MMM, YYYY HH:mm',
                  ),
                  activityBy: value?.executedBy?.username,
                  activityType: value?.activityType,
                  reason:
                    value?.data?.submission?.reason !== undefined
                      ? value?.data?.submission?.reason
                      : '',
                  note:
                    value?.data?.submission?.note !== undefined
                      ? value?.data?.submission?.note
                      : '',
                }
              }),
            )
          }
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    getSubmissionActivitiesData()
  }, [])

  const locale: any = useRecoilValue(LanguageFilter)

  const messages: any = {
    en: {
      responseID: 'Response ID:',
      timestamp: 'Timestamp:',
      attachments: 'Attachments:',
      formSubmissionDetails: 'Form Submission Details',
      submissionID: 'Submission ID:',
      serialNumber: 'Serial Number:',
      createdAts: 'Created at:',
      submittedAt: 'Submitted at:',
      submittedBy: 'Submitted by:',
      submissionStatus: 'Submission Status:',
      govAgencyName: 'Gov. Agency Name:',
      formName: 'Form Name:',
      formDescription: 'Form Description:',
      formLanguage: 'Form Language:',
      acceptedAt: 'Accepted at:',
      acceptedBy: 'Accepted by:',
      acceptedNote: 'Accepted Note:',
      completedAt: 'Completed at:',
      completedBy: 'Completed by:',
      completedNote: 'Completed Note:',
      rejectedAt: 'Rejected at:',
      rejectedBy: 'Rejected by:',
      rejectedReason: 'Rejected Reason:',
      rejectedNote: 'Rejected Note:',
      completedAttachment: 'Completed Attachment:',
      rejectedAttachment: 'Rejected Attachment:',
      paymentDetails: 'Payment Details',
      noPayments: 'No payment details to show',
      paymentAt: 'Payment at:',
      paymentStatus: 'Payment Status:',
      paymentAmount: 'Payment Amount (LKR):',
      more: 'More',
      paymentType: 'Payment Type:',
      paymentTransaction: 'Payment Transaction ID:',
      paymentRequest: 'Payment Transaction Request:',
      paymentResponse: 'Payment Transaction Response:',
      paymentMerchant: 'Payment Merchant ID:',
      paymentService: 'Payment Service ID:',
      paymentRefunded: 'Payment Refunded:',
      paymentRefundedAt: 'Payment Refunded at:',
      paymentRefundedNote: 'Payment Refunded Note:',
      conversation: 'Conversation',
      activities: 'Activities',
      no: 'No.',
      activityAt: 'Activity at',
      activityBy: 'Activity by',
      activityType: 'Activity Type',
      reason: 'Reason',
      note: 'Note',
      messageType: 'Message Type:',
      message: 'Message:',
      viewSubmission: 'View Form Submission',
      cancel: 'Cancel',
      inquiry: 'Inquiry:',
    },
    si: {
      responseID: 'ප්‍රතිචාර ID:',
      timestamp: 'වේලා මුද්රාව:',
      attachments: 'ඇමුණුම්:',
      formSubmissionDetails: 'පෝරමය ඉදිරිපත් කිරීමේ විස්තර',
      submissionID: 'ඉදිරිපත් කිරීමේ ID:',
      serialNumber: 'අනු අංකය:',
      createdAts: 'දී නිර්මාණය කරන ලදී:',
      submittedAt: 'දී ඉදිරිපත් කරන ලදී:',
      submittedBy: 'විසින් ඉදිරිපත් කරන ලදී:',
      submissionStatus: 'ඉදිරිපත් කිරීමේ තත්ත්වය:',
      govAgencyName: 'රජයේ නියෝජිතායතනයේ නම:',
      formName: 'පෝරමයේ නම:',
      formDescription: 'පෝරමය විස්තරය:',
      formLanguage: 'ආකෘති භාෂාව:',
      acceptedAt: 'දී පිළිගත්තා:',
      acceptedBy: 'විසින් පිළිගත්තා:',
      acceptedNote: 'පිළිගත් සටහන:',
      completedAt: 'දී නිම කරන ලදී:',
      completedBy: 'විසින් නිම කරන ලදී:',
      completedNote: 'සම්පූර්ණ කළ සටහන:',
      rejectedAt: 'දී ප්රතික්ෂේප කරන ලදී:',
      rejectedBy: 'විසින් ප්රතික්ෂේප කරන ලදී:',
      rejectedReason: 'ප්‍රතික්ෂේප කළ හේතුව:',
      rejectedNote: 'ප්‍රතික්ෂේප කළ සටහන:',
      completedAttachment: 'සම්පූර්ණ කළ ඇමුණුම්:',
      rejectedAttachment: 'ප්‍රතික්ෂේප කළ ඇමුණුම්:',
      paymentDetails: 'ගෙවීම් තොරතුරු:',
      noPayments: 'පෙන්වීම සදහා ගෙවීම් තොරතුරු  නොමැත',
      paymentAt: 'දී ගෙවීම:',
      paymentStatus: 'ගෙවීම් තත්ත්වය:',
      paymentAmount: 'ගෙවීම් මුදල (LKR):',
      more: 'තව',
      paymentType: 'ගෙවීම් වර්ගය:',
      paymentTransaction: 'ගෙවීම් ගනුදෙනු හැඳුනුම්පත:',
      paymentRequest: 'ගෙවීම් ගනුදෙනු ඉල්ලීම:',
      paymentResponse: 'ගෙවීම් ගනුදෙනු ප්‍රතිචාරය:',
      paymentMerchant: 'ගෙවීම් වෙළඳ හැඳුනුම්පත:',
      paymentService: 'ගෙවීම් සේවා හැඳුනුම්පත:',
      paymentRefunded: 'ගෙවීම ආපසු ගෙවන ලදී:',
      paymentRefundedAt: 'ගෙවීම ආපසු ගෙවන ලදී:',
      paymentRefundedNote: 'ආපසු ගෙවීමේ  සටහන:',
      conversation: 'සංවාදය',
      activities: 'Activities',
      no: 'No.',
      activityAt: 'Activity at',
      activityBy: 'Activity by',
      activityType: 'Activity Type',
      reason: 'Reason',
      note: 'Note',
      messageType: 'පණිවිඩ වර්ගය:',
      message: 'පණිවුඩය:',
      viewSubmission: 'පෝරමය ඉදිරිපත් කිරීම',
      cancel: 'අවලංගු කරන්න',
      inquiry: 'විමසීම:',
    },
    ta: {
      responseID: 'பதில் ஐடி:',
      timestamp: 'நேர முத்திரை:',
      attachments: 'இணைப்புகள்:',
      formSubmissionDetails: 'படிவம் சமர்ப்பிப்பு விவரங்கள்',
      submissionID: 'சமர்ப்பிப்பு ஐடி:',
      serialNumber: 'தொடர் எண்:',
      createdAts: 'இல் உருவாக்கப்பட்டது:',
      submittedAt: 'இல் சமர்ப்பிக்கப்பட்டது:',
      submittedBy: 'மூலம் சமர்ப்பிக்கப்பட்டது:',
      submissionStatus: 'சமர்ப்பிப்பு நிலை:',
      govAgencyName: 'அரசு ஏஜென்சி பெயர்:',
      formName: 'படிவத்தின் பெயர்:',
      formDescription: 'படிவம் விளக்கம்:',
      formLanguage: 'வடிவ மொழி:',
      acceptedAt: 'இல் ஏற்றுக்கொள்ளப்பட்டது:',
      acceptedBy: 'மூலம் ஏற்றுக்கொள்ளப்பட்டது:',
      acceptedNote: 'ஏற்றுக்கொள்ளப்பட்ட குறிப்பு:',
      completedAt: 'மணிக்கு முடிக்கப்பட்டது:',
      completedBy: 'மூலம் முடிக்கப்பட்டது:',
      completedNote: 'பூர்த்தி செய்யப்பட்ட குறிப்பு:',
      rejectedAt: 'இல் நிராகரிக்கப்பட்டது:',
      rejectedBy: 'மூலம் நிராகரிக்கப்பட்டது:',
      rejectedReason: 'நிராகரிக்கப்பட்ட காரணம்:',
      rejectedNote: 'நிராகரிக்கப்பட்ட குறிப்பு:',
      completedAttachment: 'பூர்த்தி செய்யப்பட்ட இணைப்புக்கள்:',
      rejectedAttachment: 'நிராகரிக்கப்பட்ட இணைப்பு:',
      paymentDetails: 'கட்டண விவரங்கள்',
      noPayments: 'கட்டண விவரங்கள் காட்டுவதற்கு இல்லை',
      paymentAt: 'இல் பணம் செலுத்துதல்:',
      paymentStatus: 'கட்டண நிலை:',
      paymentAmount: 'கட்டணத் தொகை (LKR):',
      more: 'மேலும்',
      paymentType: 'கட்டணம் வகை:',
      paymentTransaction: 'கட்டண பரிவர்த்தனை ஐடி:',
      paymentRequest: 'கட்டண பரிவர்த்தனை கோரிக்கை:',
      paymentResponse: 'கட்டண பரிவர்த்தனை பதில்:',
      paymentMerchant: 'பணம் செலுத்தும் வணிகர் ஐடி:',
      paymentService: 'கட்டண சேவை ஐடி:',
      paymentRefunded: 'பணம் திருப்பியளிக்கப்பட்டது:',
      paymentRefundedAt: 'பணம் திருப்பியளிக்கப்பட்டது:',
      paymentRefundedNote: 'திரும்பப் பெறப்பட்ட குறிப்பு:',
      conversation: 'உரையாடல்',
      activities: 'Activities',
      no: 'No.',
      activityAt: 'Activity at',
      activityBy: 'Activity by',
      activityType: 'Activity Type',
      reason: 'Reason',
      note: 'Note',
      messageType: 'செய்தி வகை:',
      message: 'செய்தி:',
      viewSubmission: 'படிவம் சமர்ப்பிப்பு',
      cancel: 'ரத்து செய்',
      inquiry: 'விசாரணை:',
    },
  }

  return (
    // <Flex flexDir="column" marginTop={{ base: '-1.5rem', md: '-3rem' }}>
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Flex flexDir="column">
        {/* <IndividualResponseNavbar /> */}
        <Box height="40px">
          <Heading as="h4" size="md">
            <FormattedMessage
              id="viewSubmission"
              values={{ locale }}
            ></FormattedMessage>
          </Heading>
        </Box>

        <Stack
          px={{ md: '1.75rem', lg: '2rem' }}
          spacing={{ base: '1.5rem', md: '2.5rem' }}
          ref={ref}
        >
          <Stack
            bg="primary.100"
            p="1.5rem"
            sx={{
              fontFeatureSettings: "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
            }}
          >
            <Flex
              mb={userData?.userRole.code === 'DF_SUPER_ADMIN' ? '-1rem' : 3}
              pl="1.5rem"
            >
              <Box>
                <Text textStyle="h4">
                  <FormattedMessage
                    id="formSubmissionDetails"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
              </Box>
              <Spacer />
              {userData?.userRole.code === 'DF_SUPER_ADMIN' ? (
                <Box mt={-3}>
                  <ButtonGroup variant="outline">
                    <Button
                      margin="5px"
                      onClick={() =>
                        navigate(`/${VIEW_CITIZEN}/${data?.createdBy.userId}`)
                      }
                    >
                      View Citizen Profile
                    </Button>
                  </ButtonGroup>
                </Box>
              ) : null}
            </Flex>

            <Stack pl="1.5rem">
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="submissionID"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Text>{submissionId}</Text>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="serialNumber"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.serialNumber ?? '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="createdAts"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.created
                    ? formatDate(data.submittedAt.toString())
                    : '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="submittedAt"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.submittedAt
                    ? formatDate(data.submittedAt.toString())
                    : '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="submittedBy"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.submittedBy?.value ?? '-'}
                </Skeleton>
              </Stack>

              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="submissionStatus"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.status ?? '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="govAgencyName"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.agencyId?.fullName ?? '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="formName"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.form?.title ?? '-'}
                </Skeleton>
              </Stack>
              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="formDescription"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.form?.description ?? '-'}
                </Skeleton>
              </Stack>

              <Stack
                spacing={{ base: '0', md: '0.5rem' }}
                direction={{ base: 'column', md: 'row' }}
              >
                <Text as="span" textStyle="subhead-1">
                  <FormattedMessage
                    id="formLanguage"
                    values={{ locale }}
                  ></FormattedMessage>
                </Text>
                <Skeleton isLoaded={!isLoading && !isError}>
                  {data?.formLanguage ?? '-'}
                </Skeleton>
              </Stack>

              {data?.status === 'Inprogress' ? (
                <>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="acceptedAt"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedAt
                        ? formatDate(data.acceptedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="acceptedBy"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedBy?.value ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="acceptedNote"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedAt
                        ? formatDate(data.acceptedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                </>
              ) : null}

              {data?.status === 'Completed' ? (
                <>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="acceptedAt"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedAt
                        ? formatDate(data.acceptedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="acceptedBy"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedBy?.value ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="acceptedNote"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.acceptedNote ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="completedAt"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.completedAt
                        ? formatDate(data.completedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="completedBy"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.completedBy?.value ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="completedNote"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.completedNote ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="completedAttachment"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.completedAttachment ? (
                        <Link
                          href={`${data.completedAttachment}`}
                          download="Completed Attachment"
                        >
                          Click to download
                        </Link>
                      ) : (
                        '-'
                      )}
                    </Skeleton>
                  </Stack>
                </>
              ) : null}

              {data?.status === 'Rejected' ? (
                <>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="rejectedAt"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedAt
                        ? formatDate(data?.rejectedAt.toString())
                        : '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="rejectedBy"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedBy?.value ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="rejectedReason"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedReason ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="rejectedNote"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedNote ?? '-'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="rejectedAttachment"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.rejectedAttachment ? (
                        <Link
                          href={`${data.rejectedAttachment}`}
                          download="Rejected Attachment"
                        >
                          Click to download
                        </Link>
                      ) : (
                        '-'
                      )}
                    </Skeleton>
                  </Stack>
                </>
              ) : null}
            </Stack>

            {data?.paymentRequired ? (
              <>
                <Stack
                  bg="primary.100"
                  p="1.5rem"
                  sx={{
                    fontFeatureSettings:
                      "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
                  }}
                >
                  <Text textStyle="h4">
                    <FormattedMessage
                      id="paymentDetails"
                      values={{ locale }}
                    ></FormattedMessage>
                  </Text>

                  {submissionPayments?.data?.length === 0 ? (
                    <Text pl="1.5rem">
                      <FormattedMessage
                        id="noPayments"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                  ) : null}

                  {submissionPayments?.data?.map((item, index) => (
                    <Stack pl="1.5rem">
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentAt"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>
                          {item?.created
                            ? formatDate(item.created.toString())
                            : '-'}
                        </Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentStatus"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>{item?.paymentStatus}</Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentAmount"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>{item?.paymentAmount}</Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentType"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>{item?.paymentType?.paymentTypeName}</Text>
                      </Stack>
                      <Stack
                        spacing={{ base: '0', md: '0.5rem' }}
                        direction={{ base: 'column', md: 'row' }}
                      >
                        <Text as="span" textStyle="subhead-1">
                          <FormattedMessage
                            id="paymentTransaction"
                            values={{ locale }}
                          ></FormattedMessage>
                        </Text>
                        <Text>{item?.paymentTxnId}</Text>
                      </Stack>

                      <Accordion allowToggle>
                        <AccordionItem>
                          <AccordionButton>
                            <Box flex="1" textAlign="left">
                              <FormattedMessage
                                id="more"
                                values={{ locale }}
                              ></FormattedMessage>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            <Stack
                              spacing={{ base: '0', md: '0.5rem' }}
                              direction={{ base: 'column', md: 'row' }}
                            >
                              <Text as="span" textStyle="subhead-1">
                                <FormattedMessage
                                  id="paymentRequest"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </Text>
                              <Text>{item?.paymentTxnRequest}</Text>
                            </Stack>
                            <Stack
                              spacing={{ base: '0', md: '0.5rem' }}
                              direction={{ base: 'column', md: 'row' }}
                            >
                              <Text as="span" textStyle="subhead-1">
                                <FormattedMessage
                                  id="paymentResponse"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </Text>
                              <Text>{item?.paymentTxnResponse}</Text>
                            </Stack>
                            {/* <Stack
                              spacing={{ base: '0', md: '0.5rem' }}
                              direction={{ base: 'column', md: 'row' }}
                            >
                              <Text as="span" textStyle="subhead-1">
                                <FormattedMessage
                                  id="paymentMerchant"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </Text>
                              <Text>{item?.paymentMerchantId}</Text>
                            </Stack> */}
                            <Stack
                              spacing={{ base: '0', md: '0.5rem' }}
                              direction={{ base: 'column', md: 'row' }}
                            >
                              <Text as="span" textStyle="subhead-1">
                                <FormattedMessage
                                  id="paymentService"
                                  values={{ locale }}
                                ></FormattedMessage>
                              </Text>
                              <Text>{item?.paymentServiceId}</Text>
                            </Stack>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                      <Divider p="1.5rem" />
                    </Stack>
                  ))}
                </Stack>

                <Stack
                  bg="primary.100"
                  pl="3rem"
                  sx={{
                    fontFeatureSettings:
                      "'tnum' on, 'lnum' on, 'zero' on, 'cv05' on",
                  }}
                >
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="paymentRefunded"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefunded ? 'True' : 'False'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="paymentRefundedAt"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefundedAt ?? 'N/A'}
                    </Skeleton>
                  </Stack>
                  <Stack
                    spacing={{ base: '0', md: '0.5rem' }}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Text as="span" textStyle="subhead-1">
                      <FormattedMessage
                        id="paymentRefundedNote"
                        values={{ locale }}
                      ></FormattedMessage>
                    </Text>
                    <Skeleton isLoaded={!isLoading && !isError}>
                      {data?.paymentRefundedNote ?? 'N/A'}
                    </Skeleton>
                  </Stack>
                </Stack>
              </>
            ) : null}

            <Stack p="1.5rem">
              <Text textStyle="h4">
                <FormattedMessage
                  id="activities"
                  values={{ locale }}
                ></FormattedMessage>
              </Text>

              <Box
                bg={'white'}
                px={10}
                py={5}
                overflowX="auto"
                maxWidth="100Vw"
                borderRadius="12px"
              >
                <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
                  <Box></Box>
                </SimpleGrid>
                <SimpleGrid
                  columns={{ sm: 1, md: 1 }}
                  spacingX="70px"
                  spacingY="20px"
                >
                  <DataTable
                    heading=""
                    rowValues={activityTableData ? activityTableData : []}
                    cols={[
                      {
                        Header: 'No',
                        accessor: 'rowNumber',
                        disableSortBy: true,
                        maxWidth: 50,
                        minWidth: 25,
                      },
                      {
                        Header: 'Activity at',
                        accessor: 'activityAt',
                        disableSortBy: true,
                        maxWidth: 100,
                        minWidth: 70,
                      },
                      {
                        Header: 'Activity by',
                        accessor: 'activityBy',
                        disableSortBy: true,
                        maxWidth: 100,
                        minWidth: 70,
                      },
                      {
                        Header: 'Activity Type',
                        accessor: 'activityType',
                        disableSortBy: true,
                        maxWidth: 100,
                        minWidth: 70,
                      },
                      {
                        Header: 'Reason',
                        accessor: 'reason',
                        disableSortBy: true,
                        maxWidth: 400,
                        minWidth: 50,
                      },
                      {
                        Header: 'Note',
                        accessor: 'note',
                        disableSortBy: true,
                        maxWidth: 400,
                        minWidth: 50,
                      },
                    ]}
                  />
                  {isLoading ? (
                    <Center>
                      <Spinner />
                    </Center>
                  ) : null}
                  {activityTableData.length === 0 ? (
                    <Center>No results found</Center>
                  ) : null}
                </SimpleGrid>
              </Box>
            </Stack>
          </Stack>

          <Stack bg="primary.100" p="1.5rem">
            <Text textStyle="h4">
              <FormattedMessage
                id="conversation"
                values={{ locale }}
              ></FormattedMessage>
            </Text>
            {/* Load conversation history */}
            {conversations.map((conversation, i) => {
              return (
                <Box key={i} p="1.5rem">
                  <SimpleGrid
                    columns={{ sm: 1, md: 4 }}
                    spacing="5px"
                    pt={i === 0 ? '10px' : '40px'}
                  >
                    <GridItem>
                      <Text
                        as="span"
                        textStyle="subhead-1"
                        style={{ fontWeight: 700 }}
                      >
                        {conversation?.conversationType?.code === 'CQ' ? (
                          <FormattedMessage
                            id="inquiry"
                            values={{ locale }}
                          ></FormattedMessage>
                        ) : (
                          <FormattedMessage
                            id="message"
                            values={{ locale }}
                          ></FormattedMessage>
                        )}
                      </Text>
                    </GridItem>
                    <GridItem>
                      {conversation?.conversationType?.code === 'CQ'
                        ? conversation?.senderPublicUser?.name
                        : conversation?.agency?.name}
                    </GridItem>
                    {/* By assuming the server time is in Sri Lankan time zone */}
                    <GridItem>
                      {moment(conversation?.createdAt).format('DD/MM/YY')}
                    </GridItem>
                    <GridItem>
                      {moment(conversation?.createdAt).format('HH:mm')}
                    </GridItem>
                  </SimpleGrid>
                  <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="5px" pt={2}>
                    <GridItem>
                      <Text as="span" textStyle="subhead-1">
                        <FormattedMessage
                          id="messageType"
                          values={{ locale }}
                        ></FormattedMessage>
                      </Text>
                    </GridItem>
                    <GridItem colSpan={3}>
                      {conversation?.message?.type}
                    </GridItem>
                  </SimpleGrid>
                  <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="5px" pt={2}>
                    <GridItem>
                      <Text as="span" textStyle="subhead-1">
                        <FormattedMessage
                          id="message"
                          values={{ locale }}
                        ></FormattedMessage>
                      </Text>
                    </GridItem>
                    <GridItem colSpan={3}>
                      {conversation?.message?.content}
                    </GridItem>
                  </SimpleGrid>
                </Box>
              )
            })}
            {/* Only show for relevant status and collaborator */}
            <SimpleGrid columns={{ sm: 1, md: 4 }} spacing="5px" pt="10px">
              <GridItem colSpan={3}></GridItem>
              <GridItem>
                <ConversationMessageModel
                  formId={formId}
                  agencyId={data?.agencyId}
                  agencyName={data?.agencyName}
                  submissionId={submissionId}
                  formCollaborators={'formCollaborators'}
                  submissionStatus={data?.status}
                />
              </GridItem>
            </SimpleGrid>
          </Stack>
          <Center>
            <Button
              maxWidth={150}
              onClick={() => {
                if (userData?.userCategory.code == 'AGENCY') {
                  navigate('/reports/agency-progress-reports')
                } else {
                  navigate('/reports/admin-progress-reports')
                }
              }}
            >
              <FormattedMessage
                id="cancel"
                values={{ locale }}
              ></FormattedMessage>
            </Button>
          </Center>
        </Stack>
      </Flex>
    </IntlProvider>
  )
}
