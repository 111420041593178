import React, { useEffect, useRef, useState } from 'react'
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { BiPlusCircle } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  Center,
  FormControl,
  GridItem,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { forEach, times } from 'lodash'
import { validate } from 'uuid'
import { any } from 'zod'

import { MasterDataCategory, MasterDataDto } from '~shared/types'

import { BxsChevronDown } from '~assets/icons/BxsChevronDown'
import { BxsChevronUp } from '~assets/icons/BxsChevronUp'
import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import Checkbox from '~components/Checkbox'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import IconButton from '~components/IconButton'
import Input from '~components/Input/index'
import Spinner from '~components/Spinner'
import { DataTable } from '~components/Table'
import Tooltip from '~components/Tooltip'

import { getMappedErrorMessage } from '~pages/Agency/utils'

interface AddUpdateMasterDataFormValues {
  dataType: string
  category: string
  parentDataType: string
  options: {
    option: string
    parent: string
  }[]
}

export const AddUpdateFormMasterDataPage = (): JSX.Element | null => {
  type optionsTable = {
    index: number
    selected: boolean
    option: string
    parentOption: string
  }
  type dataTypeList = {
    id: string
    type: string
  }

  const DATA_TYPE_NEW = 'new'
  const DATA_TYPE_EXISTING = 'existing'
  const optionLimit = 9999
  const [dataTypeOption, setDataTypeOption] = useState<string>(DATA_TYPE_NEW)
  const [categoryOption, setCategoryOption] = useState<string>('OTHER')
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
  const [isParentOpen, setIsParentOpen] = useState<boolean>(false)
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false)
  const [isSelectAllTicked, setIsSelectAllTicked] = useState<boolean>(false)
  const [currentOption, setCurrentOption] = useState(0)

  const toast = useToast()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState('25')
  const [optionsTableData, setOptionsTableData] = useState<optionsTable[]>([])
  const [optionsTableTemp, setOptionsTableTemp] = useState<optionsTable[]>([])
  const [masterDataTypeList, setMasterDataTypeList] = useState<dataTypeList[]>(
    [],
  )
  const [parentTypeList, setParentTypeList] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [parentDataTypeSelected, setParentDataTypeSelected] =
    useState<string>('')
  const [parentDataTypeSelectedTemp, setParentDataTypeSelectedTemp] =
    useState<string>('')
  const [existingMaterSelected, setExistingMaterSelected] = useState<string>('')
  const [removeData, setRemoveData] = useState<any[]>([])
  const optionRef = useRef<HTMLInputElement | null>(null)!

  const toTitleCase = (str: string): string => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  const mainFormValues = useForm<AddUpdateMasterDataFormValues>({
    defaultValues: {
      dataType: '',
      parentDataType: '',
      options: [],
    },
  })

  const { control } = mainFormValues
  const { fields, append, swap, remove } =
    useFieldArray<AddUpdateMasterDataFormValues>({
      control,
      name: 'options',
    })

  async function getMasterDataTypeList() {
    setIsLoading(true)

    ApiService.get(`/master-data/type/`)
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          mapDataTypeList(data?.data)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const response = getMasterDataTypeList()
  }, [])

  const mapDataTypeList = (array: any[]) => {
    const currentDataTypeList: dataTypeList[] = []

    array.map((value, index) => {
      if (!currentDataTypeList.find((list) => list.type === value)) {
        const obj = {
          id: value,
          type: value,
        }
        currentDataTypeList.push(obj)
      }
    })

    setMasterDataTypeList(currentDataTypeList)
  }

  const parentTypeSelection = (array: any[]) => {
    const currentDataTypeList: any = []
    array.map((value, index) => {
      const obj = {
        value: value._id,
        label: value.option,
      }
      currentDataTypeList.push(obj)
    })
    setParentTypeList(currentDataTypeList)
  }

  const onSubmitMain = (data: any) => {
    if (data.options.length === 0) {
      toast({
        title: '',
        description: 'Options cannot be empty',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
    } else {
      const optionArray: any = []
      console.log(optionsTableData)
      optionsTableData.forEach((option) => {
        const options = {
          option: option.option,
          parent: option.parentOption ? option.parentOption : '',
        }
        optionArray.push(options)
      })
      const params = {
        masterType: data.dataType ? data.dataType : existingMaterSelected,
        category: data.category,
        options: optionArray,
      }
      if (dataTypeOption === DATA_TYPE_NEW) {
        ApiService.put(`/master-data/type?isNew=true`, params)
          .then((data: any) => {
            if (data.status === 200) {
              toast({
                title: '',
                description: 'Form Master Data Type Added Successfully',
                duration: 5000,
                isClosable: true,
                status: 'success',
                position: 'top-right',
              })
              navigate('/settings/master-data')
            } else {
              toast({
                title: '',
                description: 'Something went wrong',
                duration: 5000,
                isClosable: true,
                status: 'danger',
                position: 'top-right',
              })
            }
          })
          .catch((error) => {
            toast.closeAll()
            toast({
              description: getMappedErrorMessage(error),
              status: 'danger',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            })
          })
      } else {
        ApiService.put(`/master-data/type?isNew=false`, params)
          .then((data: any) => {
            if (data.status === 200) {
              toast({
                title: '',
                description: 'Form Master Data Type Updated Successfully',
                duration: 5000,
                isClosable: true,
                status: 'success',
                position: 'top-right',
              })
              navigate('/settings/master-data')
            } else {
              toast({
                title: '',
                description: 'Something went wrong',
                duration: 5000,
                isClosable: true,
                status: 'danger',
                position: 'top-right',
              })
            }
          })
          .catch((error) => {
            toast.closeAll()
            toast({
              description: getMappedErrorMessage(error),
              status: 'danger',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            })
          })
      }
    }
  }

  const addOption = useForm({
    defaultValues: {
      option: '',
    },
  })

  const isAddOptionDirty = addOption.formState.isDirty

  const handleAddOption = (data: any) => {
    if (!data.option || data.option === '') return

    const tableData = [...optionsTableData]

    const obj = {
      index: tableData.length,
      selected: false,
      option: data.option,
      parentOption: '',
    }
    tableData.push(obj)

    setOptionsTableData(tableData)

    addOption.reset({ option: '' })
  }

  // useEffect(() => {
  //   mapOptionsTableData(fields)
  // }, [fields])

  useEffect(() => {
    setCategoryOption('OTHER')
    setOptionsTableData([])
    mainFormValues.setValue('dataType', '')
    setParentDataTypeSelected('')
  }, [dataTypeOption])

  // const mapOptionsTableData = (data: any[]) => {
  //   const currentOptionsTableData: optionsTable[] = []

  //   data.map((value, index) => {
  //     if (value.option) {
  //       const obj = {
  //         index: index,
  //         selected: false,
  //         option: value.option,
  //         parentOption: value.parent,
  //       }
  //       currentOptionsTableData.push(obj)
  //     }
  //   })
  //   setOptionsTableData(currentOptionsTableData)
  // }

  const mapExistingTableData = (data: any[]) => {
    const currentOptionsTableData: optionsTable[] = []

    data.map((value, index) => {
      const obj = {
        index: index,
        selected: false,
        option: value.option,
        parentOption: value.parent ? value.parent._id : '',
      }
      currentOptionsTableData.push(obj)
      setCategoryOption(value.category)
    })
    setOptionsTableData(currentOptionsTableData)
  }

  const handleDataTypeSelection = (event: string) => {
    const dataTypeId = event

    setIsLoading(true)
    setExistingMaterSelected(dataTypeId)

    ApiService.get(
      `/master-data/?masterType=${dataTypeId}&page_size=${optionLimit}`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          setIsLoading(false)
          const masterDatas = data?.data.data
          mainFormValues.setValue('category', masterDatas[0].category)
          mainFormValues.setValue(
            'parentDataType',
            masterDatas[0]?.parent?.masterType,
          )
          mainFormValues.setValue('dataType', dataTypeId)
          setParentDataTypeSelected(masterDatas[0]?.parent?.masterType)
          handleParentDataTypeSelection(masterDatas[0]?.parent?.masterType)
          mapExistingTableData(masterDatas)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const handleParentDataTypeSelection = (e: any) => {
    setParentDataTypeSelected(e)
    mainFormValues.setValue('parentDataType', e)

    ApiService.get(`/master-data/?masterType=` + e)
      .then((data: any) => {
        if (data?.status === 200) {
          parentTypeSelection(data?.data.data)
        }
      })
      .catch((error) => console.log('error', error))
  }

  const handleDataTypeOption = (e: any) => {
    setDataTypeOption(e)
    mainFormValues.setValue('dataType', '')
    setExistingMaterSelected('')
  }

  const onAllRowSelect = (e: any) => {
    const tableData = [...optionsTableData]
    if (e.target.checked) {
      setIsSelectAllTicked(true)
      tableData.forEach((el) => {
        el.selected = true
      })
    } else {
      setIsSelectAllTicked(false)
      tableData.forEach((el) => {
        el.selected = false
      })
    }
    setOptionsTableData(tableData)
  }

  const onOptionRowSelect = (index: number) => {
    const tableData = [...optionsTableData]
    tableData[index].selected = !tableData[index].selected
    setOptionsTableData(tableData)

    if (isSelectAllTicked) {
      setIsSelectAllTicked(false)
    }
  }

  const swapElements = (arr: any, i1: number, i2: number) => {
    const temp = arr[i1]
    arr[i1] = arr[i2]
    arr[i2] = temp
    return arr
  }

  const handleMoveUp = (id: number) => {
    let tableData = [...optionsTableData]
    if (id != 0) {
      tableData = swapElements(tableData, id, id - 1)
      setOptionsTableData(tableData)
    }
  }

  const handleMoveDown = (id: number) => {
    let tableData = [...optionsTableData]
    if (id != optionsTableData.length - 1) {
      tableData = swapElements(tableData, id, ++id)
      setOptionsTableData(tableData)
    }
  }

  const handleDeleteRows = () => {
    setIsSelectAllTicked(false)
    const tableData = [...optionsTableData]
    const indexArray: any = []
    tableData.forEach((el, index) => {
      if (el.selected) {
        indexArray.push(index)
      }
    })
    setRemoveData(indexArray.reverse())
  }

  // const handleBlurTableInput = React.useCallback(
  //   (e: any, row: number) => {
  //     if (
  //       optionsTableData[row].option ===
  //       mainFormValues.getValues().options[row].option
  //     )
  //       return

  //     const tableData = [...optionsTableData]
  //     tableData[row].option = e.currentTarget.value
  //     setOptionsTableData(tableData)
  //   },
  //   [mainFormValues],
  // )

  useEffect(() => {
    const tableData = [...optionsTableData]
    removeData.forEach((value: number) => {
      tableData.splice(value, 1)
    })
    setOptionsTableData(tableData)
  }, [removeData])

  const handleDeleteDataType = () => {
    const dataTypeId = existingMaterSelected
    if (dataTypeId === '') {
      toast({
        title: '',
        description: 'No data type selected',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      return
    }
    ApiService.delete(`master-data/type/${dataTypeId}`)
      .then((data: any) => {
        if (data.status === 200) {
          toast({
            title: '',
            description: 'Form Master Data Type Deleted',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          navigate(-1)
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast({
          title: '',
          description: 'Something went wrong',
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  const resetForm = () => {
    setParentDataTypeSelected('')
    mainFormValues.reset({
      dataType: '',
      parentDataType: '',
    })

    navigate(-1)
  }

  // const validateFieldArray = (value: string) => {
  //   if (!fields.length) return

  //   const duplicateList = mainFormValues
  //     .getValues('options')
  //     .map((item) => {
  //       return item.option
  //     })
  //     .filter((item) => item === value)

  //   return duplicateList.length > 1
  // }

  return (
    <>
      <Box bg={'gray.50'} p={10}>
        <Box height="40px">
          <Heading as="h4" size="md">
            Add/Update Form Master Data
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid
            columns={{ sm: 1, md: 2 }}
            spacingX="20px"
            spacingY="20px"
          >
            <GridItem colSpan={2}>
              <Box height="40px">
                <FormControl mb="2.5rem">
                  <Stack
                    mb="0.8rem"
                    direction={{ base: 'column', lg: 'row' }}
                    spacing={{ base: '2.5rem', lg: '3.5rem' }}
                    align="center"
                  >
                    <RadioGroup
                      onChange={handleDataTypeOption}
                      value={dataTypeOption}
                      w="100%"
                    >
                      <Stack spacing="1.5rem" direction="row">
                        <Radio value={DATA_TYPE_NEW}>
                          New Form Master Data Type
                        </Radio>
                        <Radio value={DATA_TYPE_EXISTING}>
                          Existing Form Master Data Type
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Stack>
                </FormControl>
              </Box>
            </GridItem>

            <Box height="80px" mb={3}>
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.dataType ? true : false
                }
              >
                <FormLabel htmlFor="dataType" mb="8px">
                  Form Master Data Type *
                </FormLabel>
                {dataTypeOption === DATA_TYPE_NEW ? (
                  <Input
                    placeholder={'Data Type'}
                    id="dataType"
                    maxLength={50}
                    {...mainFormValues.register('dataType', {
                      maxLength: { value: 50, message: 'Max length is 50' },
                      required: {
                        value: true,
                        message: 'Form Master Data Type is required',
                      },
                    })}
                  />
                ) : (
                  <Controller
                    name="dataType"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        onChange={handleDataTypeSelection}
                        items={Object.values(masterDataTypeList).map((type) => {
                          return {
                            value: type.id,
                            label: type.type,
                          }
                        })}
                        value={existingMaterSelected}
                      />
                    )}
                  />
                )}
                {mainFormValues.formState.errors.dataType && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.dataType.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl isRequired mb="2.5rem">
                <FormLabel>Category *</FormLabel>
                <Stack
                  mb="0.8rem"
                  direction={{ base: 'column', lg: 'row' }}
                  spacing={{ base: '2.5rem', lg: '3.5rem' }}
                  align="center"
                >
                  <RadioGroup
                    onChange={setCategoryOption}
                    value={categoryOption}
                  >
                    <Stack direction="row">
                      <p>
                        {(
                          Object.keys(
                            MasterDataCategory,
                          ) as (keyof typeof MasterDataCategory)[]
                        ).map((key, index) => (
                          <Radio
                            w="30%"
                            key={index + 1}
                            value={key}
                            {...mainFormValues.register('category', {
                              required: {
                                value: true,
                                message: 'Category is required',
                              },
                            })}
                          >
                            {toTitleCase(
                              key == 'CITY' ? 'Divisional Secretariat' : key,
                            )}
                          </Radio>
                        ))}
                      </p>
                    </Stack>
                  </RadioGroup>
                </Stack>
              </FormControl>
            </Box>

            <Box height="70px">
              <FormControl
                isInvalid={
                  mainFormValues?.formState?.errors?.parentDataType
                    ? true
                    : false
                }
              >
                <FormLabel htmlFor="parentDataType" mb="8px">
                  Parent Form Master Data Type
                </FormLabel>
                <Controller
                  name="parentDataType"
                  control={mainFormValues.control}
                  render={({ field }) => (
                    <SingleSelect
                      {...field}
                      items={Object.values(masterDataTypeList).map((type) => {
                        return {
                          value: type.id,
                          label: type.id,
                        }
                      })}
                      onChange={(e: any) => {
                        if (optionsTableData.length > 0) {
                          setIsParentOpen(true)
                          setParentDataTypeSelectedTemp(e)
                        } else {
                          handleParentDataTypeSelection(e)
                        }
                      }}
                      value={parentDataTypeSelected}
                    />
                  )}
                />
                {mainFormValues.formState.errors.parentDataType && (
                  <FormErrorMessage>
                    {mainFormValues.formState.errors.parentDataType.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="80px"></Box>
            <Box height="80px" mt={4}>
              <FormControl
                isInvalid={addOption?.formState?.errors?.option ? true : false}
              >
                <FormLabel htmlFor="option" mb="8px">
                  Option *
                </FormLabel>
                <Input
                  placeholder={'Option'}
                  id="addOption"
                  maxLength={50}
                  {...addOption.register('option', {
                    maxLength: { value: 50, message: 'Max length is 50' },
                  })}
                />
                {addOption.formState.errors.option && (
                  <FormErrorMessage>
                    {addOption.formState.errors.option.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <Box height="80px" mt={4} pt="5">
              <ButtonGroup variant="outline" spacing="1" padding={2}>
                <IconButton
                  isDisabled={!isAddOptionDirty}
                  variant="clear"
                  aria-label="Add option"
                  colorScheme="secondary"
                  onClick={addOption.handleSubmit(handleAddOption)}
                  icon={<BiPlusCircle />}
                />
              </ButtonGroup>
            </Box>

            <Box height="70px" mt={4}>
              <Checkbox
                isChecked={isSelectAllTicked}
                onChange={onAllRowSelect}
                value="ALL"
              >
                Select All
              </Checkbox>
            </Box>
          </SimpleGrid>

          <Box bg={'gray.200'} p={5}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
              <Box bg={'gray.200'} p={5}></Box>
            </SimpleGrid>
            <SimpleGrid columns={{ sm: 1, md: 1 }} spacingX="70px">
              <DataTable
                heading="Options"
                rowValues={optionsTableData ? optionsTableData : []}
                cols={[
                  {
                    Header: 'No.',
                    accessor: (_row: any, i: number) => i + 1,
                    disableSortBy: true,
                    maxWidth: 100,
                    minWidth: 80,
                    width: 80,
                  },
                  {
                    Header: 'Select',
                    Cell: (props: any) => (
                      <Checkbox
                        isChecked={optionsTableData[props.row.id].selected}
                        onChange={() => onOptionRowSelect(props.row.index)}
                      ></Checkbox>
                    ),
                  },
                  {
                    Header: 'Option',
                    Cell: (props: any) => (
                      <FormControl
                        isInvalid={
                          mainFormValues?.formState?.errors?.options?.[
                            props.row.id
                          ]?.option
                            ? true
                            : false
                        }
                      >
                        <Input
                          placeholder={'Option'}
                          id="option"
                          maxLength={100}
                          autoFocus={
                            currentOption == props.row.id ? true : false
                          }
                          {...mainFormValues.register(
                            `options.${props.row.id}.option`,
                            {
                              maxLength: {
                                value: 100,
                                message: 'Max length is 100',
                              },
                              // validate: validateFieldArray,
                            },
                          )}
                          value={optionsTableData[props.row.id]?.option}
                          onChange={(event: any) => {
                            const tableData = [...optionsTableData]
                            tableData[props.row.id].option =
                              event.currentTarget.value
                            setCurrentOption(props.row.id)
                            setOptionsTableData(tableData)
                          }}
                        />
                        {mainFormValues.formState.errors.options?.[props.row.id]
                          ?.option && (
                          <FormErrorMessage>
                            {mainFormValues.formState.errors.options?.[
                              props.row.id
                            ]?.option?.type === 'validate'
                              ? 'Duplicate value'
                              : mainFormValues.formState.errors.options?.[
                                  props.row.id
                                ]?.option?.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    ),
                    maxWidth: 400,
                    minWidth: 190,
                  },
                  {
                    Header: 'Parent Option',
                    Cell: (props: any) => (
                      <FormControl
                        isDisabled={!parentDataTypeSelected}
                        isInvalid={
                          mainFormValues?.formState?.errors?.options?.[
                            props.row.id
                          ]?.parent
                            ? true
                            : false
                        }
                      >
                        <Controller
                          name={`options.${props.row.id}.parent`}
                          control={mainFormValues.control}
                          render={({ field }) => (
                            <SingleSelect
                              {...field}
                              items={parentTypeList}
                              value={
                                optionsTableData[props.row.id]?.parentOption
                              }
                              onChange={(event) => {
                                const tableData = [...optionsTableData]
                                tableData[props.row.id].parentOption = event
                                setOptionsTableData(tableData)
                                mainFormValues.setValue(
                                  `options.${props.row.id}.parent`,
                                  event,
                                )
                              }}
                            />
                          )}
                        />
                        {mainFormValues.formState.errors.options?.[props.row.id]
                          ?.parent && (
                          <FormErrorMessage>
                            {
                              mainFormValues.formState.errors.options?.[
                                props.row.id
                              ]?.parent?.message
                            }
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    ),
                    maxWidth: 400,
                    minWidth: 190,
                  },
                  {
                    Header: 'Action',
                    Cell: (props: any) => (
                      <ButtonGroup variant="outline" spacing="1" padding={2}>
                        <IconButton
                          variant="clear"
                          aria-label="Move up"
                          colorScheme="secondary"
                          onClick={() => handleMoveUp(props.row.id)}
                          icon={<BxsChevronUp />}
                        />
                        <IconButton
                          variant="clear"
                          aria-label="Move down"
                          colorScheme="secondary"
                          onClick={() => handleMoveDown(props.row.id)}
                          icon={<BxsChevronDown />}
                        />
                      </ButtonGroup>
                    ),
                  },
                ]}
              />

              {optionsTableData.length === 0 ? (
                <Center>No results found</Center>
              ) : null}
              {isLoading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : null}
              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="70px">
                <ButtonGroup variant="outline" spacing="1" padding={5}>
                  <Button onClick={handleDeleteRows}>Remove</Button>
                </ButtonGroup>
              </SimpleGrid>
            </SimpleGrid>
          </Box>

          <Box height="80px"></Box>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <Center my="1rem" ml="25rem">
              <ButtonGroup variant="outline" spacing="6">
                <Button type="submit">Save</Button>
                <Button
                  type="reset"
                  onClick={() => {
                    setIsConfirmOpen(true)
                  }}
                >
                  Cancel
                </Button>
                <Tooltip label="Delete Form Master Data Type">
                  <Button
                    isDisabled={dataTypeOption !== DATA_TYPE_EXISTING}
                    onClick={() => {
                      setIsConfirmDelete(true)
                    }}
                  >
                    Delete
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Center>
          </SimpleGrid>
        </form>
      </Box>
      {isConfirmOpen ? (
        <Modal
          isOpen={isConfirmOpen}
          onClose={() => {
            setIsConfirmOpen(false)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              Are you sure you want to cancel?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button onClick={resetForm}>Yes</Button>
                <Button
                  onClick={() => {
                    setIsConfirmOpen(false)
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
      {isParentOpen ? (
        <Modal
          isOpen={isParentOpen}
          onClose={() => {
            setIsParentOpen(false)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              Are you sure you want to change? This will change parent option
              list below.
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    setIsParentOpen(false)
                    handleParentDataTypeSelection(parentDataTypeSelectedTemp)
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setIsParentOpen(false)
                    setParentDataTypeSelected(parentDataTypeSelected)
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
      {isConfirmDelete ? (
        <Modal
          isOpen={isConfirmDelete}
          onClose={() => {
            setIsConfirmDelete(false)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              Are you sure you want to delete master data type?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button onClick={handleDeleteDataType}>Yes</Button>
                <Button
                  onClick={() => {
                    setIsConfirmDelete(false)
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}
