import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'

import { CONVENTIONAL_PERSON_NAME_REGEX } from '~pages/Agency/utils'
import { useTitleUserPublic } from '~pages/Citizen/onboardingScreen/queries'

import Input from '../../../components/Input/index'
import {
  convertNumberToSriLankaNumber,
  getMappedErrorMessage,
  handleEmailValidation,
  handlePhoneValidation,
  PASSPORT_REGEX,
  SL_DRIVING_LICENSE_REGEX,
  SL_NIC_REGEX,
} from '../utils'

export const UpdateUserPage = (): JSX.Element | null => {
  const { userId } = useParams()

  type ContactPersonTable = {
    _id?: string
    id?: number
    personName: string
    designation?: string
    mobile: string[]
    email?: string[]
  }

  type DropdownData = {
    value: string
    label: string
  }

  const navigate = useNavigate()
  const fileUploadRef = useRef<HTMLInputElement | null>(null)
  const SECRET_KEY_REGEX = /^[a-zA-Z0-9/+]+={0,2}$/

  const toast = useToast()
  const { data } = useTitleUserPublic()

  const [roles, setRoles] = useState<any>([{}])
  const [idType, setIdType] = useState<string>('')
  const [maxLength, setMaxLength] = useState<number>(0)
  const [minLength, setMinLength] = useState<number>(0)
  const [idNumberRegex, setIdNumberRegex] = useState<RegExp>(SL_NIC_REGEX)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
  const [titleDropdownData, setTitleDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [dropdownData, setDropdownData] = useState<DropdownData>()

  const contactFormDefault = {
    personName: '',
    designation: '',
    mobile: '',
    email: '',
  }

  const [result, setResult] = useState({
    _id: '',
    username: '',
    status: '',
    memberNo: '',
    title: '',
    gender: '',
    firstName: '',
    lastName: '',
    nameInitials: '',
    identificationType: '',
    passportCountry: '',
    nicNo: '',
    passportNo: '',
    drivingLicenseNo: '',
    designation: '',
    contact: '',
    email: '',
    agencyDetails: {
      fullName: '',
      shortName: '',
      status: '',
      _id: '',
    },
    userCategory: {
      code: '',
      name: '',
    },
    agency: '',
    userRole: {
      code: '',
      name: '',
    },
    createdBy: {
      userCategoryCode: '',
      userCategoryName: '',
      userId: '',
      userName: '',
    },
    lastModifiedBy: {
      userId: '',
      userName: '',
      userCategoryCode: '',
      userCategoryName: '',
    },
    createdAt: '',
    lastModifiedAt: '',
    _v: 0,
    lastAccessed: '',
  })
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [error, setError] = useState()

  async function getUser() {
    ApiService.get(`/user/${userId}`)
      .then((data: any) => {
        if (data?.status === 200) {
          setResult(data.data.user) //result.user
          setIsLoaded(true)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const [agency, setAgency] = useState<any>([])

  async function getAgency() {
    ApiService.get(`/agency`)
      .then((data: any) => {
        if (data?.status === 200) {
          setAgency(data?.data)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  useEffect(() => {
    const response = getAgency()
  }, [])

  const agencyArr: any[] = []

  useEffect(() => {
    if (agency?.agencies) {
      for (const element of agency.agencies) {
        agencyArr.push({ value: element._id, label: element.fullName })
      }
    }
  }, [agency])

  useEffect(() => {
    const response = getUser()
  }, [])

  const titlesDropdownData: DropdownData[] = []
  const mapDataToDropDown = () => {
    data?.map((value) => {
      titlesDropdownData.push({
        value: `${value?.title}`,
        label: value?.title,
      })
    })
  }

  mapDataToDropDown()

  const titlesGenderDropdownData: DropdownData[] = []
  const titlesGenderDropDown = () => {
    data?.map((value) => {
      titlesGenderDropdownData.push({
        value: `${value?.gender}/${value?.title}`,
        label: value?.title,
      })
    })
  }

  useEffect(() => {
    mainFormValues.setValue('userId', result?._id ? result?._id : '-')
    mainFormValues.setValue('agency', result?.agency ? result?.agency : '-')
    mainFormValues.setValue('userStatus', result?.status ? result?.status : '-')
    mainFormValues.setValue(
      'userCategory',
      result?.userCategory.code ? result?.userCategory.code : '-',
    )
    mainFormValues.setValue(
      'userRole',
      result?.userRole.code ? result?.userRole.code : '-',
    )
    mainFormValues.setValue(
      'username',
      result?.username ? result?.username : '-',
    )
    mainFormValues.setValue('title', result?.title ? result?.title : '-')
    mainFormValues.setValue(
      'memberNo',
      result?.memberNo ? result?.memberNo : '',
    )
    mainFormValues.setValue('gender', result?.gender ? result?.gender : '-')
    mainFormValues.setValue(
      'firstName',
      result?.firstName ? result?.firstName : '-',
    )
    mainFormValues.setValue(
      'lastName',
      result?.lastName ? result?.lastName : '-',
    )
    mainFormValues.setValue(
      'nameInitials',
      result?.nameInitials ? result?.nameInitials : '-',
    )
    mainFormValues.setValue(
      'identificationType',
      result?.identificationType ? result?.identificationType : '-',
    )
    mainFormValues.setValue(
      'nicNo',
      result?.identificationType === 'N'
        ? result?.nicNo
          ? result?.nicNo
          : '-'
        : result?.identificationType === 'P'
        ? result?.passportNo
          ? result?.passportNo
          : '-'
        : result?.drivingLicenseNo
        ? result?.drivingLicenseNo
        : '-',
    )
    mainFormValues.setValue(
      'passportCountry',
      result?.passportCountry ? result?.passportCountry : 'Sri Lanka',
    )
    mainFormValues.setValue(
      'designation',
      result?.designation ? result?.designation : '-',
    )
    mainFormValues.setValue(
      'contact',
      result?.contact ? '0' + result.contact.substring(3) : '-',
    )
    mainFormValues.setValue('email', result?.email ? result?.email : '-')
  }, [result])

  const mainFormValues = useForm({
    defaultValues: {
      userId: '',
      userCategory: 'ALL',
      userRole: 'ALL',
      agency: 'ALL',
      username: '',
      userStatus: '',
      memberNo: '',
      title: 'ALL',
      gender: '',
      firstName: '',
      lastName: '',
      nameInitials: '',
      identificationType: '',
      nicNo: '',
      passportCountry: 'Sri Lanka',
      designation: '',
      contact: '',
      email: '',
      logo: '',
      paymentEncryptionKey: '',
    },
  })

  const enteredTitle = mainFormValues.watch('title')

  useEffect(() => {
    titlesGenderDropDown()
    let titleArray = []
    for (const i in titlesGenderDropdownData) {
      titleArray = titlesGenderDropdownData[i].value.split('/')
      if (mainFormValues.getValues('title') == titleArray[1]) {
        if (titleArray[0] == 'undefined') {
          mainFormValues.setValue('gender', result?.gender)
        } else {
          mainFormValues.setValue('gender', titleArray[0])
        }
      }
    }

    mainFormValues.clearErrors('title')

    mainFormValues.clearErrors('gender')
  }, [enteredTitle])

  const onSubmitMain = (data: any) => {
    const phoneNumber = convertNumberToSriLankaNumber(data.contact)

    const userParams = {
      username: result?.username,
      status: data?.userStatus,
      title: data.title,
      memberNo: data.memberNo,
      gender: data.gender,
      firstName: data.firstName,
      lastName: data.lastName,
      nameInitials: data.nameInitials,
      identificationType: data.identificationType,
      nicNo: data.identificationType == 'N' ? data.nicNo : null,
      passportNo: data.identificationType == 'P' ? data.nicNo : null,
      drivingLicenseNo: data.identificationType == 'D' ? data.nicNo : null,
      passportCountry: null,
      designation: data.designation,
      contact: phoneNumber === result?.contact ? undefined : phoneNumber,
      email: data.email === result?.email ? undefined : data.email,
      userCategory: {
        code: result?.userCategory.code,
        name: result?.userCategory.name,
      },
      agency: result?.agency,
      userRole: {},
    }
    if (data.identificationType == 'P') {
      userParams.passportCountry = data?.passportCountry
    }

    if (data.userRole == 'DF_AGENCY_USER') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Agency User',
      }
    } else if (data.userRole == 'DF_AGENCY_ADMIN') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Agency Administrator',
      }
    } else if (data.userRole == 'DF_SYS_USER') {
      userParams.userRole = {
        code: data.userRole,
        name: 'System User',
      }
    } else if (data.userRole == 'DF_SUPER_ADMIN') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Super Administrator',
      }
    } else if (data.userRole == 'DF_SYS_ADMIN') {
      userParams.userRole = {
        code: data.userRole,
        name: 'System Administrator',
      }
    } else if (data.userRole == 'DF_EXEC_USER') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Executive User',
      }
    }

    const requestBody = {
      user: userParams,
    }

    ApiService.put(`/user/${userId}`, requestBody)
      .then((data: any) => {
        if (data?.status === 200) {
          toast({
            title: '',
            description: 'User Updated',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          navigate('/user/view/' + userId)
        } else {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast.closeAll()
        toast({
          title: '',
          description: getMappedErrorMessage(error),
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  const logo = mainFormValues.watch('logo')

  //encryption key
  const handleFileSelect = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const file = target.files?.[0]
      // Reset file input so the same file selected will trigger this onChange
      // function.
      if (fileUploadRef.current) {
        fileUploadRef.current.value = ''
      }

      if (!file) return

      const reader = new FileReader()
      reader.onload = async (e) => {
        if (!e.target) return
        const text = e.target.result?.toString()

        if (!text || !SECRET_KEY_REGEX.test(text)) {
          return mainFormValues.setError(
            'paymentEncryptionKey',
            {
              type: 'invalidFile',
              message: 'Selected file seems to be invalid',
            },
            { shouldFocus: true },
          )
        }

        mainFormValues.setValue('paymentEncryptionKey', text, {
          shouldValidate: true,
        })
      }
      reader.readAsText(file)
    },
    [mainFormValues.setError, mainFormValues.setValue],
  )

  const identificationType = mainFormValues.watch('identificationType')
  useEffect(() => {
    if (identificationType === 'P') {
      setIdType('P')
      setMaxLength(30)
      setMinLength(5)
      setIdNumberRegex(PASSPORT_REGEX)
    } else if (identificationType === 'N') {
      setIdType('N')
      setMaxLength(12)
      setMinLength(10)
      // setIdNumberRegex(SL_NIC_REGEX)
    } else {
      setIdType('D')
      setMaxLength(8)
      setMinLength(7)
      setIdNumberRegex(SL_DRIVING_LICENSE_REGEX)
    }
    // mainFormValues.setValue('identificationNo', '')
  }, [identificationType])

  useEffect(() => {
    if (result?.userCategory.code === 'AGENCY') {
      setRoles([
        {
          value: 'DF_AGENCY_USER',
          label: ' Agency User',
        },
        {
          value: 'DF_AGENCY_ADMIN',
          label: 'Agency Administrator',
        },
      ])
    } else {
      setRoles([
        {
          value: 'DF_SYS_USER',
          label: 'System User',
        },
        {
          value: 'DF_SUPER_ADMIN',
          label: 'Super Administrator',
        },
        {
          value: 'DF_SYS_ADMIN',
          label: 'System Administrator',
        },
        {
          value: 'DF_EXEC_USER',
          label: 'Executive User',
        },
      ])
    }
  }, [result?.userCategory])

  const changeIdentification = (e: any) => {
    mainFormValues.setValue('identificationType', e)
    mainFormValues.setValue('nicNo', '')
  }

  return (
    <>
      <Box bg={'gray.50'} p={10}>
        <Box height="40px">
          <Heading as="h4" size="md">
            Update User
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <Tabs>
            <TabList
              py={{ base: '1.25rem', lg: 0 }}
              px={{ base: '1.25rem', lg: '1rem' }}
              my={5}
              w={{ base: '100vw', lg: 'initial' }}
              gridArea="tabs"
              borderBottom="none"
              justifyContent={{ base: 'flex-start', lg: 'left' }}
              alignSelf="center"
            >
              <Tab
                style={{ fontWeight: 'bold' }}
                _selected={{ bg: 'gray.400' }}
                p={3}
              >
                Personal Details
              </Tab>
              <Tab
                style={{ fontWeight: 'bold' }}
                _selected={{ bg: 'gray.400' }}
                p={3}
              >
                Contact Details
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel margin="10px">
                <Box borderRadius="12px" border="none" bg="white" p={5}>
                  <Heading as="h6" size="md" mb={4}>
                    Personal Details
                  </Heading>

                  <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
                    <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                      <dl>
                        <div style={{ display: 'flex' }}>
                          <dd
                            style={{
                              paddingTop: '8px',
                              paddingRight: '10px',
                              fontWeight: 500,
                            }}
                          >
                            User Category :
                          </dd>
                          <dt
                            style={{
                              flex: '50%',
                              paddingTop: '8px',
                              paddingRight: '10px',
                            }}
                          >
                            {result?.userCategory.name}
                          </dt>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <dd
                            style={{
                              paddingTop: '8px',
                              paddingRight: '10px',
                              fontWeight: 500,
                            }}
                          >
                            Organization Agency :
                          </dd>
                          <dt
                            style={{
                              flex: '50%',
                              paddingTop: '8px',
                              paddingRight: '10px',
                            }}
                          >
                            {result?.agencyDetails.fullName}
                          </dt>
                        </div>
                      </dl>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.userRole
                              ? true
                              : false
                          }
                        >
                          <ChakraFormLabel htmlFor="userRole:" mb="8px">
                            User Role *
                          </ChakraFormLabel>
                          <Controller
                            name="userRole"
                            control={mainFormValues.control}
                            render={({ field }) => (
                              <SingleSelect {...field} items={roles} />
                            )}
                            rules={{ required: 'User Role is required' }}
                          />
                          {mainFormValues.formState.errors.userRole && (
                            <FormErrorMessage>
                              {mainFormValues.formState.errors.userRole.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.userStatus
                              ? true
                              : false
                          }
                        >
                          <ChakraFormLabel htmlFor="status" mb="8px">
                            Status*
                          </ChakraFormLabel>
                          <Controller
                            name="userStatus"
                            control={mainFormValues.control}
                            render={({ field }) => (
                              <SingleSelect
                                {...field}
                                items={[
                                  {
                                    value: 'Active',
                                    label: 'Active',
                                  },
                                  {
                                    value: 'Inactive',
                                    label: 'Inactive',
                                  },
                                ]}
                              />
                            )}
                            rules={{ required: 'Status is required' }}
                          />
                          {mainFormValues.formState.errors.userStatus && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors.userStatus
                                  .message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <dl>
                        <div style={{ display: 'flex' }}>
                          <dd
                            style={{
                              paddingTop: '8px',
                              paddingRight: '10px',
                              fontWeight: 500,
                            }}
                          >
                            Username :
                          </dd>
                          <dt
                            style={{
                              flex: '50%',
                              paddingTop: '8px',
                              paddingRight: '10px',
                            }}
                          >
                            {result?.username}
                          </dt>
                        </div>
                      </dl>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.memberNo
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="memberNo" mb="8px">
                            Member No
                          </FormLabel>
                          <Input
                            placeholder={'Member No'}
                            id="memberNo"
                            maxLength={10}
                            {...mainFormValues.register('memberNo', {
                              maxLength: {
                                value: 10,
                                message: 'Max lenght is 10',
                              },
                            })}
                          />
                          {mainFormValues.formState.errors.memberNo && (
                            <FormErrorMessage>
                              {mainFormValues.formState.errors.memberNo.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.title
                              ? true
                              : false
                          }
                        >
                          <ChakraFormLabel htmlFor="title" mb="8px">
                            Title *
                          </ChakraFormLabel>
                          <Controller
                            name="title"
                            control={mainFormValues.control}
                            render={({ field }) => (
                              <SingleSelect
                                {...field}
                                items={titlesDropdownData}
                              />
                            )}
                            rules={{ required: 'Title is required' }}
                          />
                          {mainFormValues.formState.errors.title && (
                            <FormErrorMessage>
                              {mainFormValues.formState.errors.title.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.gender
                              ? true
                              : false
                          }
                        >
                          <ChakraFormLabel htmlFor="gender" mb="8px">
                            Gender
                          </ChakraFormLabel>
                          <Controller
                            name="gender"
                            control={mainFormValues.control}
                            render={({ field }) => (
                              <SingleSelect
                                {...field}
                                items={[
                                  {
                                    value: 'M',
                                    label: 'Male',
                                  },
                                  {
                                    value: 'F',
                                    label: 'Female',
                                  },
                                ]}
                              />
                            )}
                          />
                          {mainFormValues.formState.errors.gender && (
                            <FormErrorMessage>
                              {mainFormValues.formState.errors.gender.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.firstName
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="firstName" mb="8px">
                            First Name *
                          </FormLabel>
                          <Input
                            placeholder={'First Name'}
                            id="firstName"
                            maxLength={100}
                            {...mainFormValues.register('firstName', {
                              required: 'First Name is required',
                              maxLength: {
                                value: 100,
                                message: 'Max length is 100',
                              },
                              pattern: {
                                value: CONVENTIONAL_PERSON_NAME_REGEX,
                                message: 'Invalid first name',
                              },
                            })}
                          />
                          {mainFormValues.formState.errors.firstName && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors.firstName
                                  .message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.lastName
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="lastName" mb="8px">
                            Last Name *
                          </FormLabel>
                          <Input
                            placeholder={'Last Name'}
                            id="lastName"
                            maxLength={100}
                            {...mainFormValues.register('lastName', {
                              required: 'Last Name is required',
                              maxLength: {
                                value: 100,
                                message: 'Max length is 100',
                              },
                              pattern: {
                                value: CONVENTIONAL_PERSON_NAME_REGEX,
                                message: 'Invalid last name',
                              },
                            })}
                          />
                          {mainFormValues.formState.errors.lastName && (
                            <FormErrorMessage>
                              {mainFormValues.formState.errors.lastName.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.nameInitials
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="nameInitials" mb="8px">
                            Name with Initials *
                          </FormLabel>
                          <Input
                            placeholder={'Name with Initials'}
                            id="nameInitials"
                            maxLength={100}
                            {...mainFormValues.register('nameInitials', {
                              required: 'Name with Initials is required',
                              maxLength: {
                                value: 100,
                                message: 'Max length is 100',
                              },
                            })}
                          />
                          {mainFormValues.formState.errors.nameInitials && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors.nameInitials
                                  .message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors
                              ?.identificationType
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="identificationType" mb="8px">
                            Identification Type *
                          </FormLabel>
                          <Controller
                            name="identificationType"
                            control={mainFormValues.control}
                            render={({ field }) => (
                              <SingleSelect
                                {...field}
                                items={[
                                  {
                                    value: 'N',
                                    label: 'NIC',
                                  },
                                  {
                                    value: 'P',
                                    label: 'Passport',
                                  },
                                  {
                                    value: 'D',
                                    label: 'Driving License',
                                  },
                                ]}
                                onChange={changeIdentification}
                              />
                            )}
                            rules={{
                              required: 'Identification Type is required',
                            }}
                          />
                          {mainFormValues.formState.errors
                            .identificationType && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors
                                  .identificationType.message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.nicNo
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="nicNo" mb="8px">
                            {idType === 'N'
                              ? 'NIC Number*'
                              : idType === 'D'
                              ? 'Driving License Number*'
                              : 'Passport Number*'}
                          </FormLabel>
                          <Input
                            placeholder={'Identification Number'}
                            id="nicNo"
                            maxLength={30}
                            {...mainFormValues.register('nicNo', {
                              required: 'Identification Number is required.',
                              maxLength: {
                                value: maxLength,
                                message:
                                  idType === 'N'
                                    ? 'Max length is 12'
                                    : idType === 'D'
                                    ? 'Max length is 8'
                                    : 'Max length is 30',
                              },
                              minLength: {
                                value: minLength,
                                message:
                                  idType === 'N'
                                    ? 'Min length is 10'
                                    : idType === 'D'
                                    ? 'Min length is 7'
                                    : 'Min length is 5',
                              },
                              // pattern: {
                              //   value: idNumberRegex,
                              //   message: 'Invalid Identification Number',
                              // },
                            })}
                          />
                          {mainFormValues.formState.errors.nicNo && (
                            <FormErrorMessage>
                              {mainFormValues.formState.errors.nicNo.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      {idType === 'P' && (
                        <Box height="70px">
                          <FormControl
                            isInvalid={
                              mainFormValues?.formState?.errors?.passportCountry
                                ? true
                                : false
                            }
                          >
                            <ChakraFormLabel htmlFor="status" mb="8px">
                              Country of Passport *
                            </ChakraFormLabel>
                            <Controller
                              name="passportCountry"
                              control={mainFormValues.control}
                              render={({ field }) => (
                                <SingleSelect
                                  {...field}
                                  items={[
                                    {
                                      value: 'Sri Lanka',
                                      label: 'Sri Lanka',
                                    },
                                    {
                                      value: 'India',
                                      label: 'India',
                                    },
                                  ]}
                                />
                              )}
                              rules={{
                                required: 'Country of Passport is required',
                              }}
                            />
                            {mainFormValues.formState.errors
                              .passportCountry && (
                              <FormErrorMessage>
                                {
                                  mainFormValues.formState.errors
                                    .passportCountry.message
                                }
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>
                      )}
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.designation
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="designation" mb="8px">
                            Designation
                          </FormLabel>
                          <Input
                            placeholder={'Designation'}
                            id="designation"
                            maxLength={100}
                            {...mainFormValues.register('designation', {
                              maxLength: {
                                value: 100,
                                message: 'Max length is 100',
                              },
                            })}
                          />
                          {mainFormValues.formState.errors.designation && (
                            <FormErrorMessage>
                              {
                                mainFormValues.formState.errors.designation
                                  .message
                              }
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                    </SimpleGrid>
                  </SimpleGrid>
                </Box>
              </TabPanel>
              <TabPanel margin="10px">
                <Box borderRadius="12px" border="none" bg="white" p={5}>
                  <Heading as="h5" size="md" mb={4}>
                    Contact Details
                  </Heading>
                  <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
                    <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.contact
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="contact" mb="8px">
                            Phone Number *
                          </FormLabel>
                          <Input
                            placeholder={'Phone Number'}
                            id="contact"
                            {...mainFormValues.register('contact', {
                              required: 'Phone Number is required',
                              validate: handlePhoneValidation,
                            })}
                          />
                          {mainFormValues.formState.errors.contact && (
                            <FormErrorMessage>
                              {mainFormValues.formState.errors.contact.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                      <Box height="70px">
                        <FormControl
                          isInvalid={
                            mainFormValues?.formState?.errors?.email
                              ? true
                              : false
                          }
                        >
                          <FormLabel htmlFor="email" mb="8px">
                            Email Address *
                          </FormLabel>
                          <Input
                            placeholder={'Email'}
                            id="email"
                            maxLength={100}
                            {...mainFormValues.register('email', {
                              required: 'Email is required',
                              maxLength: {
                                value: 100,
                                message: 'Max length is 100',
                              },
                              validate: handleEmailValidation,
                            })}
                          />
                          {mainFormValues.formState.errors.email && (
                            <FormErrorMessage>
                              {mainFormValues.formState.errors.email.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Box>
                    </SimpleGrid>
                  </SimpleGrid>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <ButtonGroup variant="outline" spacing="4" padding={10}>
            <Button type="submit">Save</Button>
            <Button
              onClick={() => {
                setIsConfirmOpen(true)
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </form>
      </Box>
      {isConfirmOpen ? (
        <Modal
          isOpen={isConfirmOpen}
          onClose={() => {
            setIsConfirmOpen(false)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              Are you sure you want to cancel?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    setIsConfirmOpen(false)
                    onClose()
                    navigate('/user')
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="clear"
                  colorScheme="secondary"
                  onClick={() => {
                    setIsConfirmOpen(false)
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}
