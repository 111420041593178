const NIC_FORMAT =
  /^([0-9]{9}[x|X|v|V]|[0-9]{12})$/

/**
 * Validates whether a provided string value adheres to the old format
 * as provided on the Sri Lankan Government's National Identity Card.
 * @param value The value to be validated
 */
export const isNicValid = (value: string): boolean => {
  return !!value?.toUpperCase().match(NIC_FORMAT)
}
