import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { BiMessageSquareX, BiUpload } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  Image,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'

import { MAX_USERNAME_LENGTH } from '~shared/constants'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Badge from '~components/Badge'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import { ComboboxItem } from '~components/Dropdown/types'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'
import IconButton from '~components/IconButton'

import {
  CONVENTIONAL_PERSON_NAME_REGEX,
  NO_SPECIAL_CHARACTER_REGEX,
} from '~pages/Agency/utils'
import { useTitleUserPublic } from '~pages/Citizen/onboardingScreen/queries'

import Input from '../../../components/Input/index'
import {
  convertNumberToSriLankaNumber,
  getMappedErrorMessage,
  handleEmailValidation,
  handlePhoneValidation,
  PASSPORT_REGEX,
  SL_DRIVING_LICENSE_REGEX,
  SL_NIC_REGEX,
  validateImageSize,
  validateUserLogoFormat,
} from '../utils'

export const CreateUserPage = (): JSX.Element | null => {
  type ContactPersonTable = {
    id?: number
    personName: string
    designation?: string
    mobile: string[]
    email: string[]
  }

  type DropdownData = {
    value: string
    label: string
  }

  const navigate = useNavigate()
  const fileUploadRef = useRef<HTMLInputElement | null>(null)
  const SECRET_KEY_REGEX = /^[a-zA-Z0-9/+]+={0,2}$/
  const [agencyLogo, setAgencyLogo] = useState<string>('')
  const { data } = useTitleUserPublic()
  const [roles, setRoles] = useState<any>([{}])
  const [showAgency, setShowAgency] = useState(false)
  const [category, setCategory] = useState<string>('')
  const [idType, setIdType] = useState<string>('')
  const [maxLength, setMaxLength] = useState<number>(0)
  const [minLength, setMinLength] = useState<number>(0)
  const [idNumberRegex, setIdNumberRegex] = useState<RegExp>(SL_NIC_REGEX)
  const imageUploadRef = useRef<HTMLInputElement | null>(null)
  const [logoFile, setLogoFile] = useState<File | null>(null)
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [agencyDropdownData, setAgencyDropdownData] = useState<
    ComboboxItem<string>[]
  >([])
  const [dropdownData, setDropdownData] = useState<DropdownData>()

  const toast = useToast()

  const contactFormDefault = {
    personName: '',
    designation: '',
    mobile: '',
    email: '',
  }
  const titlesDropdownData: DropdownData[] = []

  const mapDataToDropDown = () => {
    data?.map((value) => {
      titlesDropdownData.push({
        value: `${value?._id}/${value?.gender}/${value?.title}`,
        label: value?.title,
      })
    })
  }

  mapDataToDropDown()
  const createContactFormValues = useForm({
    defaultValues: contactFormDefault,
  })

  const updateContactFormValues = useForm({
    defaultValues: contactFormDefault,
  })

  const mainFormValues = useForm({
    defaultValues: {
      userCategory: '',
      userRole: '',
      agency: '',
      username: '',
      memberNo: '',
      title: '',
      gender: '',
      firstName: '',
      lastName: '',
      nameInitials: '',
      identificationType: '',
      nicNo: '',
      passportCountry: '',
      designation: '',
      contact: '',
      email: '',
      logo: '',
    },
  })

  const enteredTitle = mainFormValues.watch('title')
  useEffect(() => {
    const titleIdArray = enteredTitle?.split('/')
    mainFormValues.setValue('gender', titleIdArray[1])
    mainFormValues.clearErrors('gender')
    console.log(enteredTitle)
  }, [enteredTitle])

  const onSubmitMain = (data: any) => {
    const titleIdArray = enteredTitle?.split('/')
    const phoneNumber = convertNumberToSriLankaNumber(data.contact)

    const userParams = {
      username: data.username,
      title: titleIdArray[2],
      memberNo: data.memberNo,
      gender: data.gender,
      firstName: data.firstName,
      lastName: data.lastName,
      nameInitials: data.nameInitials,
      designation: data.designation,
      identificationType: data.identificationType,
      nicNo: data.identificationType == 'N' ? data.nicNo : null,
      passportNo: data.identificationType == 'P' ? data.nicNo : null,
      drivingLicenseNo: data.identificationType == 'D' ? data.nicNo : null,
      passportCountry:
        data.identificationType == 'P' ? data?.passportCountry : '',
      contact: phoneNumber,
      email: data.email,
      userCategory: {
        code: category,
        name: category == 'SYSTEM' ? 'System User' : 'Agency User',
      },
      userRole: {},
      agency: data.agency !== 'ALL' ? data.agency : '',
    }

    if (data.userRole == 'DF_AGENCY_USER') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Agency User',
      }
    } else if (data.userRole == 'DF_AGENCY_ADMIN') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Agency Administrator',
      }
    } else if (data.userRole == 'DF_SYS_USER') {
      userParams.userRole = {
        code: data.userRole,
        name: 'System User',
      }
    } else if (data.userRole == 'DF_SUPER_ADMIN') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Super Administrator',
      }
    } else if (data.userRole == 'DF_SYS_ADMIN') {
      userParams.userRole = {
        code: data.userRole,
        name: 'System Administrator',
      }
    } else if (data.userRole == 'DF_EXEC_USER') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Executive User',
      }
    }

    const requestBody = {
      user: userParams,
    }

    ApiService.post(`/user`, requestBody)
      .then((data: any) => {
        if (data.status === 200) {
          toast({
            title: '',
            description: 'User Created',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          navigate(`/user`)
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast.closeAll()
        toast({
          title: '',
          description: getMappedErrorMessage(error),
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }

  async function getAgency() {
    ApiService.get(
      `/agency/dashboard/dropdown?status=Active&agencyCategory.code=AGENCY`,
    )
      .then((data: any) => {
        if (data?.status === 200) {
          agencyDropDown(data?.data)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const validateUserLogo = (file: File): boolean => {
    const validUserLogoFormat = validateUserLogoFormat(file?.name)
    if (!validUserLogoFormat) {
      toast({
        title: '',
        description: 'Profile picture can be only of types png, jpg, jpeg',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    const isImgSizeValid = validateImageSize(file, 2000000)
    if (!isImgSizeValid) {
      toast({
        title: '',
        description: 'Image has to be less than 2MB',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    return true
  }

  useEffect(() => {
    getAgency()
  }, [])

  const agencyDropDown = (array: any[]) => {
    const currentAgencyData: ComboboxItem<string>[] = []
    array.map((value, index) => {
      const obj = {
        key: value?._id,
        value: value?._id,
        label: value?.fullName,
      }
      currentAgencyData.push(obj)
    })
    setAgencyDropdownData(currentAgencyData)
  }

  // const titlesDropdownData: DropdownData[] = []

  // const mapDataToDropDown = () => {
  //   data?.map((value) => {
  //     titlesDropdownData.push({
  //       value: `${value?.title}`,
  //       label: value?.title,
  //     })
  //   })
  // }

  mapDataToDropDown()

  // const mapDataToTitleDropdown = (array: any[]) => {
  //   const titleData: ComboboxItem<string>[] = []
  //   array.map((value, index) => {
  //     const obj = {
  //       key: value._id,
  //       value: value._id,
  //       label: value.title,
  //       gender: value.gender,
  //     }
  //     titleData.push(obj)
  //   })
  //   setTitleDropdownData(titleData)
  //   //setTitleArray(titleData)
  // }

  const logo = mainFormValues.watch('logo')

  useEffect(() => {
    if (logo?.length > 0) {
      const [file] = logo
      setAgencyLogo(URL.createObjectURL(new Blob([file])))
    }
  }, [logo])

  const removeImage = () => {
    setAgencyLogo('')
    mainFormValues.setValue('logo', '')
  }

  const changeCategory = (e: any) => {
    mainFormValues.setValue('userCategory', e)
    if (e === 'AGENCY') {
      setShowAgency(true)
      setCategory('AGENCY')
      setRoles([
        {
          value: 'DF_AGENCY_USER',
          label: ' Agency User',
        },
        {
          value: 'DF_AGENCY_ADMIN',
          label: 'Agency Administrator',
        },
      ])
    } else {
      setShowAgency(false)
      setCategory('SYSTEM')
      setRoles([
        {
          value: 'DF_SYS_USER',
          label: 'System User',
        },
        {
          value: 'DF_SUPER_ADMIN',
          label: 'Super Administrator',
        },
        {
          value: 'DF_SYS_ADMIN',
          label: 'System Administrator',
        },
        {
          value: 'DF_EXEC_USER',
          label: 'Executive User',
        },
      ])
    }
  }

  //encryption key
  const handleFileSelect = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const file = target.files?.[0]
      // Reset file input so the same file selected will trigger this onChange
      // function.
      if (fileUploadRef.current) {
        fileUploadRef.current.value = ''
      }

      if (!file) return

      const reader = new FileReader()
      reader.onload = async (e) => {
        if (!e.target) return
        const text = e.target.result?.toString()

        // if (!text || !SECRET_KEY_REGEX.test(text)) {
        //   return mainFormValues.setError(
        //     'paymentEncryptionKey',
        //     {
        //       type: 'invalidFile',
        //       message: 'Selected file seems to be invalid',
        //     },
        //     { shouldFocus: true },
        //   )
        // }

        // mainFormValues.setValue('paymentEncryptionKey', text, {
        //   shouldValidate: true,
        // })
      }
      reader.readAsText(file)
    },
    [mainFormValues.setError, mainFormValues.setValue],
  )

  const identificationType = mainFormValues.watch('identificationType')

  useEffect(() => {
    if (identificationType === 'P') {
      setIdType('P')
      setMaxLength(30)
      setMinLength(0)
      setIdNumberRegex(PASSPORT_REGEX)
    } else if (identificationType === 'N') {
      setIdType('N')
      setMaxLength(12)
      setMinLength(10)
      // setIdNumberRegex(SL_NIC_REGEX)
    } else {
      setIdType('D')
      setMaxLength(8)
      setMinLength(0)
      setIdNumberRegex(SL_DRIVING_LICENSE_REGEX)
    }
    // mainFormValues.setValue('identificationNo', '')
  }, [identificationType])

  return (
    <>
      <Box bg={'gray.50'} p={10}>
        <Box height="40px">
          <Heading as="h4" size="md">
            Create User
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.userCategory
                      ? true
                      : false
                  }
                >
                  <ChakraFormLabel htmlFor="userCategory" mb="8px">
                    User Category *
                  </ChakraFormLabel>
                  <Controller
                    name="userCategory"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={[
                          {
                            value: 'AGENCY',
                            label: 'Organization Agency',
                          },
                          {
                            value: 'SYSTEM',
                            label: 'System Admin',
                          },
                        ]}
                        onChange={changeCategory}
                        value={category}
                      />
                    )}
                    rules={{ required: 'User Category is required.' }}
                  />
                  {mainFormValues.formState.errors.userCategory && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.userCategory.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              {showAgency && (
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.agency ? true : false
                    }
                  >
                    <ChakraFormLabel htmlFor="agency" mb="8px">
                      Organization Agency *
                    </ChakraFormLabel>
                    <Controller
                      name="agency"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <SingleSelect {...field} items={agencyDropdownData} />
                      )}
                      rules={{ required: 'Organization Agency is required.' }}
                    />
                    {mainFormValues.formState.errors.agency && (
                      <FormErrorMessage>
                        {mainFormValues.formState.errors.agency.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
              )}
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.userRole ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="userRole:" mb="8px">
                    User Role *
                  </ChakraFormLabel>
                  <Controller
                    name="userRole"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect {...field} items={roles} />
                    )}
                    rules={{ required: 'User Role is required.' }}
                  />
                  {mainFormValues.formState.errors.userRole && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.userRole.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.username ? true : false
                  }
                >
                  <FormLabel htmlFor="username" mb="8px">
                    Username *
                  </FormLabel>
                  <Input
                    placeholder={'Name'}
                    id="username"
                    maxLength={MAX_USERNAME_LENGTH}
                    {...mainFormValues.register('username', {
                      required: 'Username is required.',
                      maxLength: { value: 20, message: 'Max length is 20' },
                      pattern: {
                        value: NO_SPECIAL_CHARACTER_REGEX,
                        message: 'Invalid Username',
                      },
                    })}
                  />
                  {mainFormValues.formState.errors.username && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.username.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.memberNo ? true : false
                  }
                >
                  <FormLabel htmlFor="memberNo" mb="8px">
                    Member No
                  </FormLabel>
                  <Input
                    placeholder={'Member No'}
                    id="memberNo"
                    maxLength={10}
                    {...mainFormValues.register('memberNo', {
                      maxLength: { value: 10, message: 'Max lenght is 10' },
                    })}
                  />
                  {mainFormValues.formState.errors.memberNo && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.memberNo.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.title ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="title" mb="8px">
                    Title*
                  </ChakraFormLabel>
                  <Controller
                    name="title"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect {...field} items={titlesDropdownData} />
                    )}
                    rules={{ required: 'Title is required.' }}
                  />
                  {mainFormValues.formState.errors.title && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.title.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.gender ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="gender" mb="8px">
                    Gender *
                  </ChakraFormLabel>
                  <Controller
                    name="gender"
                    control={mainFormValues.control}
                    rules={{ required: 'Gender is required.' }}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={[
                          {
                            value: 'M',
                            label: 'Male',
                          },
                          {
                            value: 'F',
                            label: 'Female',
                          },
                        ]}
                      />
                    )}
                  />
                  {mainFormValues.formState.errors.gender && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.gender.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.firstName ? true : false
                  }
                >
                  <FormLabel htmlFor="firstName" mb="8px">
                    First Name *
                  </FormLabel>
                  <Input
                    placeholder={'First Name'}
                    id="firstName"
                    maxLength={100}
                    {...mainFormValues.register('firstName', {
                      required: 'First Name is required.',
                      maxLength: { value: 100, message: 'Max lenght is 100' },
                      pattern: {
                        value: CONVENTIONAL_PERSON_NAME_REGEX,
                        message: 'Invalid first name',
                      },
                    })}
                  />
                  {mainFormValues.formState.errors.firstName && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.firstName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.lastName ? true : false
                  }
                >
                  <FormLabel htmlFor="lastName" mb="8px">
                    Last Name *
                  </FormLabel>
                  <Input
                    placeholder={'Last Name'}
                    id="lastName"
                    maxLength={100}
                    {...mainFormValues.register('lastName', {
                      required: 'Last Name is required.',
                      maxLength: { value: 100, message: 'Max lenght is 100' },
                      pattern: {
                        value: CONVENTIONAL_PERSON_NAME_REGEX,
                        message: 'Invalid last name',
                      },
                    })}
                  />
                  {mainFormValues.formState.errors.lastName && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.lastName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.nameInitials
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="nameInitials" mb="8px">
                    Name with Initials *
                  </FormLabel>
                  <Input
                    placeholder={'Name with Initials'}
                    id="nameInitials"
                    maxLength={100}
                    {...mainFormValues.register('nameInitials', {
                      required: 'Name with Initials is required.',
                      maxLength: { value: 100, message: 'Max lenght is 100' },
                    })}
                  />
                  {mainFormValues.formState.errors.nameInitials && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.nameInitials.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.identificationType
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="identificationType" mb="8px">
                    Identification Type *
                  </FormLabel>
                  <Controller
                    name="identificationType"
                    control={mainFormValues.control}
                    rules={{ required: 'Identification Type is required.' }}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={[
                          {
                            value: 'N',
                            label: 'NIC',
                          },
                          {
                            value: 'P',
                            label: 'Passport',
                          },
                          {
                            value: 'D',
                            label: 'Driving License',
                          },
                        ]}
                      />
                    )}
                  />
                  {mainFormValues.formState.errors.identificationType && (
                    <FormErrorMessage>
                      {
                        mainFormValues.formState.errors.identificationType
                          .message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.nicNo ? true : false
                  }
                >
                  <FormLabel htmlFor="nicNo" mb="8px">
                    {idType === 'N'
                      ? 'NIC Number *'
                      : idType === 'D'
                      ? 'Driving License Number *'
                      : 'Passport Number *'}
                  </FormLabel>
                  <Input
                    placeholder={'Identification Number'}
                    id="nicNo"
                    maxLength={
                      idType === 'P'
                        ? 30
                        : idType === 'N'
                        ? 12
                        : idType === 'D'
                        ? 8
                        : 30
                    }
                    minLength={
                      idType === 'N'
                        ? 10
                        : idType === 'P'
                        ? 8
                        : idType === 'D'
                        ? 7
                        : 8
                    }
                    {...mainFormValues.register('nicNo', {
                      required: 'Identification Number is required.',
                      pattern: {
                        value:
                          idType === 'D'
                            ? /^[a-zA-Z0-9]{7,8}$/
                            : idType === 'P'
                            ? /^.{8,30}$/
                            : /^.{8,30}$/,
                        message: 'Invalid Identification Number',
                      },
                    })}
                  />
                  {mainFormValues.formState.errors.nicNo && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.nicNo.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              {idType === 'P' && (
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.passportCountry
                        ? true
                        : false
                    }
                  >
                    <ChakraFormLabel htmlFor="status" mb="8px">
                      Country of Passport *
                    </ChakraFormLabel>
                    <Controller
                      name="passportCountry"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          items={[
                            {
                              value: 'Sri Lanka',
                              label: 'Sri Lanka',
                            },
                            {
                              value: 'India',
                              label: 'India',
                            },
                          ]}
                        />
                      )}
                      rules={{ required: 'Country of Passport is required.' }}
                    />
                    {mainFormValues.formState.errors.passportCountry && (
                      <FormErrorMessage>
                        {
                          mainFormValues.formState.errors.passportCountry
                            .message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
              )}
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.designation
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="designation" mb="8px">
                    Designation
                  </FormLabel>
                  <Input
                    placeholder={'Designation'}
                    id="designation"
                    maxLength={100}
                    {...mainFormValues.register('designation', {
                      maxLength: { value: 100, message: 'Max length is 100' },
                    })}
                  />
                  {mainFormValues.formState.errors.designation && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.designation.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.contact ? true : false
                  }
                >
                  <FormLabel htmlFor="contact" mb="8px">
                    Phone Number *
                  </FormLabel>
                  <Input
                    placeholder={'Phone Number'}
                    id="contact"
                    maxLength={10}
                    {...mainFormValues.register('contact', {
                      required: 'Phone Number is required.',
                      validate: handlePhoneValidation,
                    })}
                  />

                  {mainFormValues.formState.errors.contact && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.contact.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.email ? true : false
                  }
                >
                  <FormLabel htmlFor="email" mb="8px">
                    Email Address *
                  </FormLabel>
                  <Input
                    placeholder={'Email'}
                    id="email"
                    maxLength={100}
                    {...mainFormValues.register('email', {
                      required: 'Email is required.',
                      maxLength: { value: 100, message: 'Max length is 100' },
                      validate: handleEmailValidation,
                    })}
                  />
                  {mainFormValues.formState.errors.email && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.email.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            </SimpleGrid>

            <Box height="20px"></Box>

            <ButtonGroup variant="outline" spacing="2" padding={10}>
              <Button type="submit">Save</Button>
              <Button
                onClick={() => {
                  setIsConfirmOpen(true)
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </SimpleGrid>

          <div style={{ paddingTop: 80 }}></div>
        </form>
      </Box>
      {isConfirmOpen ? (
        <Modal
          isOpen={isConfirmOpen}
          onClose={() => {
            setIsConfirmOpen(false)
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm</ModalHeader>
            <ModalBody whiteSpace="pre-line">
              Are you sure you want to cancel?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    setIsConfirmOpen(false)
                    onClose()
                    navigate('/user')
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setIsConfirmOpen(false)
                  }}
                >
                  No
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : null}
    </>
  )
}
