import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  ButtonGroup,
  FormControl,
  FormLabel as ChakraFormLabel,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react'

import { MAX_USERNAME_LENGTH } from '~shared/constants'

import { useToast } from '~hooks/useToast'
import { ApiService } from '~services/ApiService'
import Button from '~components/Button'
import { SingleSelect } from '~components/Dropdown'
import FormErrorMessage from '~components/FormControl/FormErrorMessage'
import FormLabel from '~components/FormControl/FormLabel'

import { NO_SPECIAL_CHARACTER_REGEX } from '~pages/Agency/utils'

import Input from '../../../../components/Input/index'
import {
  convertNumberToSriLankaNumber,
  getMappedErrorMessage,
  handleEmailValidation,
  handlePhoneValidation,
  PASSPORT_REGEX,
  SL_DRIVING_LICENSE_REGEX,
  SL_NIC_REGEX,
  validateImageSize,
  validateUserLogoFormat,
} from '../../utils'

export const AgencyCreateUserPage = (): JSX.Element | null => {
  type ContactPersonTable = {
    id?: number
    personName: string
    designation?: string
    mobile: string[]
    email: string[]
  }

  const navigate = useNavigate()
  const fileUploadRef = useRef<HTMLInputElement | null>(null)
  const SECRET_KEY_REGEX = /^[a-zA-Z0-9/+]+={0,2}$/
  const [agencyLogo, setAgencyLogo] = useState<string>('')
  const [idType, setIdType] = useState<string>('')
  const [maxLength, setMaxLength] = useState<number>(0)
  const [minLength, setMinLength] = useState<number>(0)
  const [idNumberRegex, setIdNumberRegex] = useState<RegExp>(SL_NIC_REGEX)
  const imageUploadRef = useRef<HTMLInputElement | null>(null)
  const [logoFile, setLogoFile] = useState<File | null>(null)

  const toast = useToast()

  const contactFormDefault = {
    personName: '',
    designation: '',
    mobile: '',
    email: '',
  }

  const createContactFormValues = useForm({
    defaultValues: contactFormDefault,
  })

  const updateContactFormValues = useForm({
    defaultValues: contactFormDefault,
  })

  const mainFormValues = useForm({
    defaultValues: {
      userCategory: '',
      userRole: '',
      agency: '',
      username: '',
      memberNo: '',
      title: '',
      gender: '',
      firstName: '',
      lastName: '',
      nameInitials: '',
      identificationType: '',
      nicNo: '',
      passportCountry: '',
      designation: '',
      contact: '',
      email: '',
      logo: '',
    },
  })

  const user = localStorage.getItem('user')
  const userData = user ? JSON.parse(user)._doc : {}

  const onSubmitMain = (data: any) => {
    const phoneNumber = convertNumberToSriLankaNumber(data.contact)

    const userParams = {
      username: data.username,
      title: data.title,
      memberNo: data.memberNo,
      gender: data.gender,
      firstName: data.firstName,
      lastName: data.lastName,
      nameInitials: data.nameInitials,
      designation: data.designation,
      identificationType: data.identificationType,
      nicNo: data.identificationType == 'N' ? data.nicNo : null,
      passportNo: data.identificationType == 'P' ? data.nicNo : null,
      drivingLicenseNo: data.identificationType == 'D' ? data.nicNo : null,
      passportCountry:
        data.identificationType == 'P' ? data?.passportCountry : '',
      contact: phoneNumber,
      email: data.email,
      userCategory: {
        code: 'AGENCY',
        name: 'Agency User',
      },
      userRole: {},
      // agency: userData.agency,
    }

    if (data.userRole == 'DF_AGENCY_USER') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Agency User',
      }
    } else if (data.userRole == 'DF_AGENCY_ADMIN') {
      userParams.userRole = {
        code: data.userRole,
        name: 'Agency Administrator',
      }
    }

    const requestBody = {
      user: userParams,
    }

    ApiService.post(`/user`, requestBody)
      .then((data: any) => {
        if (data.status === 200) {
          toast({
            title: '',
            description: 'User Created',
            duration: 5000,
            isClosable: true,
            status: 'success',
            position: 'top-right',
          })
          navigate(`/agent/user`)
        } else {
          toast({
            title: '',
            description: 'Something went wrong',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => {
        toast.closeAll()
        toast({
          title: '',
          description: getMappedErrorMessage(error),
          duration: 5000,
          isClosable: true,
          status: 'danger',
          position: 'top-right',
        })
      })
  }
  const [agency, setAgency] = useState<any>([])

  async function getAgency() {
    ApiService.get(`/agency`)
      .then((data: any) => {
        if (data?.status === 200) {
          setAgency(data?.data)
        } else if (data !== 401) {
          toast({
            title: '',
            description: 'Something went wrong!',
            duration: 5000,
            isClosable: true,
            status: 'danger',
            position: 'top-right',
          })
        }
      })
      .catch((error) => console.log('error', error))
  }

  const validateUserLogo = (file: File): boolean => {
    const validUserLogoFormat = validateUserLogoFormat(file?.name)
    if (!validUserLogoFormat) {
      toast({
        title: '',
        description: 'Profile picture can be only of types png, jpg, jpeg',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    const isImgSizeValid = validateImageSize(file, 2000000)
    if (!isImgSizeValid) {
      toast({
        title: '',
        description: 'Image has to be less than 2MB',
        duration: 5000,
        isClosable: true,
        status: 'danger',
        position: 'top-right',
      })
      if (imageUploadRef.current) {
        imageUploadRef.current.value = ''
      }
      return false
    }
    return true
  }

  useEffect(() => {
    const response = getAgency()
  }, [])

  const agencyArr: any[] = []

  useEffect(() => {
    if (agency?.agencies) {
      for (const element of agency.agencies) {
        agencyArr.push({ value: element._id, label: element.fullName })
      }
    }
  }, [agency])

  const logo = mainFormValues.watch('logo')

  useEffect(() => {
    if (logo?.length > 0) {
      const [file] = logo
      setAgencyLogo(URL.createObjectURL(new Blob([file])))
    }
  }, [logo])

  const removeImage = () => {
    setAgencyLogo('')
    mainFormValues.setValue('logo', '')
  }

  //encryption key
  const handleFileSelect = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const file = target.files?.[0]
      // Reset file input so the same file selected will trigger this onChange
      // function.
      if (fileUploadRef.current) {
        fileUploadRef.current.value = ''
      }

      if (!file) return

      const reader = new FileReader()
      reader.onload = async (e) => {
        if (!e.target) return
        const text = e.target.result?.toString()

        // if (!text || !SECRET_KEY_REGEX.test(text)) {
        //   return mainFormValues.setError(
        //     'paymentEncryptionKey',
        //     {
        //       type: 'invalidFile',
        //       message: 'Selected file seems to be invalid',
        //     },
        //     { shouldFocus: true },
        //   )
        // }

        // mainFormValues.setValue('paymentEncryptionKey', text, {
        //   shouldValidate: true,
        // })
      }
      reader.readAsText(file)
    },
    [mainFormValues.setError, mainFormValues.setValue],
  )

  const identificationType = mainFormValues.watch('identificationType')

  useEffect(() => {
    if (identificationType === 'P') {
      setIdType('P')
      setMaxLength(30)
      setMinLength(0)
      setIdNumberRegex(PASSPORT_REGEX)
    } else if (identificationType === 'N') {
      setIdType('N')
      setMaxLength(12)
      setMinLength(10)
      // setIdNumberRegex(SL_NIC_REGEX)
    } else {
      setIdType('D')
      setMaxLength(8)
      setMinLength(0)
      setIdNumberRegex(SL_DRIVING_LICENSE_REGEX)
    }
    // mainFormValues.setValue('identificationNo', '')
  }, [identificationType])

  return (
    <>
      <Box bg={'gray.50'} p={10}>
        <Box height="40px">
          <Heading as="h4" size="md">
            Create User
          </Heading>
        </Box>
        <form onSubmit={mainFormValues.handleSubmit(onSubmitMain)}>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="40px">
            <SimpleGrid columns={{ sm: 1, md: 1 }} spacing="40px">
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.userRole ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="userRole:" mb="8px">
                    User Role *
                  </ChakraFormLabel>
                  <Controller
                    name="userRole"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={[
                          {
                            value: 'DF_AGENCY_USER',
                            label: ' Agency User',
                          },
                          {
                            value: 'DF_AGENCY_ADMIN',
                            label: 'Agency Administrator',
                          },
                        ]}
                      />
                    )}
                    rules={{ required: 'User Role is required.' }}
                  />
                  {mainFormValues.formState.errors.userRole && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.userRole.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.username ? true : false
                  }
                >
                  <FormLabel htmlFor="username" mb="8px">
                    Username *
                  </FormLabel>
                  <Input
                    placeholder={'Name'}
                    id="username"
                    maxLength={MAX_USERNAME_LENGTH}
                    {...mainFormValues.register('username', {
                      required: 'Username is required.',
                      maxLength: { value: 20, message: 'Max length is 20' },
                      pattern: {
                        value: NO_SPECIAL_CHARACTER_REGEX,
                        message: 'Invalid Username',
                      },
                    })}
                  />
                  {mainFormValues.formState.errors.username && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.username.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.memberNo ? true : false
                  }
                >
                  <FormLabel htmlFor="memberNo" mb="8px">
                    Member No
                  </FormLabel>
                  <Input
                    placeholder={'Member No'}
                    id="memberNo"
                    maxLength={10}
                    {...mainFormValues.register('memberNo', {
                      maxLength: { value: 10, message: 'Max lenght is 10' },
                    })}
                  />
                  {mainFormValues.formState.errors.memberNo && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.memberNo.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.gender ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="gender" mb="8px">
                    Gender *
                  </ChakraFormLabel>
                  <Controller
                    name="gender"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={[
                          {
                            value: 'Male',
                            label: 'Male',
                          },
                          {
                            value: 'Female',
                            label: 'Female',
                          },
                        ]}
                      />
                    )}
                    rules={{ required: 'Gender is required.' }}
                  />
                  {mainFormValues.formState.errors.gender && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.gender.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.title ? true : false
                  }
                >
                  <ChakraFormLabel htmlFor="title" mb="8px">
                    Title *
                  </ChakraFormLabel>
                  <Controller
                    name="title"
                    control={mainFormValues.control}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={[
                          {
                            value: 'Mr',
                            label: 'Mr',
                          },
                          {
                            value: 'Ms',
                            label: 'Ms',
                          },
                        ]}
                      />
                    )}
                    rules={{ required: 'Title is required.' }}
                  />
                  {mainFormValues.formState.errors.title && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.title.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.firstName ? true : false
                  }
                >
                  <FormLabel htmlFor="firstName" mb="8px">
                    First Name *
                  </FormLabel>
                  <Input
                    placeholder={'First Name'}
                    id="firstName"
                    maxLength={100}
                    {...mainFormValues.register('firstName', {
                      required: 'First Name is required.',
                      maxLength: { value: 100, message: 'Max lenght is 100' },
                    })}
                  />
                  {mainFormValues.formState.errors.firstName && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.firstName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.lastName ? true : false
                  }
                >
                  <FormLabel htmlFor="lastName" mb="8px">
                    Last Name *
                  </FormLabel>
                  <Input
                    placeholder={'Last Name'}
                    id="lastName"
                    maxLength={100}
                    {...mainFormValues.register('lastName', {
                      required: 'Last Name is required.',
                      maxLength: { value: 100, message: 'Max lenght is 100' },
                    })}
                  />
                  {mainFormValues.formState.errors.lastName && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.lastName.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.nameInitials
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="nameInitials" mb="8px">
                    Name with Initials *
                  </FormLabel>
                  <Input
                    placeholder={'Name with Initials'}
                    id="nameInitials"
                    maxLength={100}
                    {...mainFormValues.register('nameInitials', {
                      required: 'Name with Initials is required.',
                      maxLength: { value: 100, message: 'Max lenght is 100' },
                    })}
                  />
                  {mainFormValues.formState.errors.nameInitials && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.nameInitials.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.identificationType
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="identificationType" mb="8px">
                    Identification Type *
                  </FormLabel>
                  <Controller
                    name="identificationType"
                    control={mainFormValues.control}
                    rules={{ required: 'Identification Type is required.' }}
                    render={({ field }) => (
                      <SingleSelect
                        {...field}
                        items={[
                          {
                            value: 'N',
                            label: 'NIC',
                          },
                          {
                            value: 'P',
                            label: 'Passport',
                          },
                          {
                            value: 'D',
                            label: 'Driving License',
                          },
                        ]}
                      />
                    )}
                  />
                  {mainFormValues.formState.errors.identificationType && (
                    <FormErrorMessage>
                      {
                        mainFormValues.formState.errors.identificationType
                          .message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.nicNo ? true : false
                  }
                >
                  <FormLabel htmlFor="nicNo" mb="8px">
                    {idType === 'N'
                      ? 'NIC Number*'
                      : idType === 'D'
                      ? 'Driving License Number*'
                      : 'Passport Number*'}
                  </FormLabel>
                  <Input
                    placeholder={'Identification Number'}
                    id="nicNo"
                    maxLength={30}
                    {...mainFormValues.register('nicNo', {
                      required: 'Identification Number is required.',
                      maxLength: {
                        value: maxLength,
                        message:
                          idType === 'N'
                            ? 'Max length is 12'
                            : idType === 'D'
                            ? 'Max length is 8'
                            : 'Max length is 30',
                      },
                      minLength: {
                        value: minLength,
                        message: 'Min length is 10',
                      },
                      // pattern: {
                      //   value: idNumberRegex,
                      //   message: 'Invalid Identification Number',
                      // },
                    })}
                  />
                  {mainFormValues.formState.errors.nicNo && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.nicNo.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              {idType === 'P' && (
                <Box height="70px">
                  <FormControl
                    isInvalid={
                      mainFormValues?.formState?.errors?.passportCountry
                        ? true
                        : false
                    }
                  >
                    <ChakraFormLabel htmlFor="status" mb="8px">
                      Country of Passport *
                    </ChakraFormLabel>
                    <Controller
                      name="passportCountry"
                      control={mainFormValues.control}
                      render={({ field }) => (
                        <SingleSelect
                          {...field}
                          items={[
                            {
                              value: 'Sri Lanka',
                              label: 'Sri Lanka',
                            },
                            {
                              value: 'India',
                              label: 'India',
                            },
                          ]}
                        />
                      )}
                      rules={{ required: 'Country of Passport is required.' }}
                    />
                    {mainFormValues.formState.errors.passportCountry && (
                      <FormErrorMessage>
                        {
                          mainFormValues.formState.errors.passportCountry
                            .message
                        }
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
              )}
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.designation
                      ? true
                      : false
                  }
                >
                  <FormLabel htmlFor="designation" mb="8px">
                    Designation
                  </FormLabel>
                  <Input
                    placeholder={'Designation'}
                    id="designation"
                    maxLength={100}
                    {...mainFormValues.register('designation', {
                      maxLength: { value: 100, message: 'Max length is 100' },
                    })}
                  />
                  {mainFormValues.formState.errors.designation && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.designation.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.contact ? true : false
                  }
                >
                  <FormLabel htmlFor="contact" mb="8px">
                    Phone Number *
                  </FormLabel>
                  <Input
                    placeholder={'Phone Number'}
                    id="contact"
                    {...mainFormValues.register('contact', {
                      required: 'Phone Number is required.',
                      validate: handlePhoneValidation,
                    })}
                  />
                  {mainFormValues.formState.errors.contact && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.contact.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
              <Box height="70px">
                <FormControl
                  isInvalid={
                    mainFormValues?.formState?.errors?.email ? true : false
                  }
                >
                  <FormLabel htmlFor="email" mb="8px">
                    Email Address *
                  </FormLabel>
                  <Input
                    placeholder={'Email'}
                    id="email"
                    maxLength={100}
                    {...mainFormValues.register('email', {
                      required: 'Email is required.',
                      maxLength: { value: 100, message: 'Max length is 100' },
                      validate: handleEmailValidation,
                    })}
                  />
                  {mainFormValues.formState.errors.email && (
                    <FormErrorMessage>
                      {mainFormValues.formState.errors.email.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Box>
            </SimpleGrid>

            <Box height="20px"></Box>

            <ButtonGroup variant="outline" spacing="2" padding={10}>
              <Button type="submit">Save</Button>
              <Button onClick={() => navigate('/agent/user')}>Cancel</Button>
            </ButtonGroup>
          </SimpleGrid>

          <div style={{ paddingTop: 80 }}></div>
        </form>
      </Box>
    </>
  )
}
