import { z } from 'zod'
import { Opaque } from 'type-fest'

import { UserDto } from './user'

export type SettingsId = Opaque<string, 'SettingsId'>
export const SettingsId = z.string() as unknown as z.Schema<SettingsId>

// Base used for being referenced by schema/model in the backend.
// Note the lack of typing of _id.
// req type
export const SettingsBase = z.object({
  code: z.string(),
  value: z.any(),
  description: z.string().optional(),
  createdAt: z.date(),
  lastModifiedAt: z.date(),
  createdBy: z.object({
    userId: UserDto.shape._id,
    userCategoryId: z.string(), // change to DTO shape
  }),
  lastModifiedBy: z.object({
    userId: UserDto.shape._id,
    userCategoryId: z.string(),
  }),
})
export type SettingsBase = z.infer<typeof SettingsBase>

// Convert to serialized versions.
// res type
export const SettingsDto = SettingsBase.extend({
  _id: SettingsId,
  createdBy: z.object({
    userId: UserDto,
    userCategoryId: z.string(),
  }),
  lastModifiedBy: z.object({
    userId: UserDto,
    userCategoryId: z.string(),
  }),
})
export type SettingsDto = z.infer<typeof SettingsDto>

export enum  SettingsCode {
  FormArchiveRetentionPeriod = "FORM_ARCHIVE_RETENTION_PERIOD",
  FormDraftRetentionPeriod = "FORM_DRAFT_RETENTION_PERIOD",
  SubmissionArchiveRetentionPeriod = "SUBMISSION_ARCHIVE_RETENTION_PERIOD",
  SubmissionDraftRetentionPeriod = "SUBMISSION_DRAFT_RETENTION_PERIOD",
  ActivityRetentionPeriod = "ACTIVITY_RETENTION_PERIOD",
  ActivityArchiveRetentionPeriod = "ACTIVITY_ARCHIVE_RETENTION_PERIOD",
  NotificationRetentionPeriod = "NOTIFICATION_RETENTION_PERIOD",
  NotificationArchiveRetentionPeriod = "NOTIFICATION_ARCHIVE_RETENTION_PERIOD",
  SubmissionDraftDeletionReminderPeriod = "SUBMISSION_DRAFT_DELETION_REMINDER_PERIOD",
  InactiveUsersRetentionPeriod = "INACTIVE_USERS_RETENTION_PERIOD",
  SMSNotificationRetentionPeriod = "SMS_NOTIFICATION_RETENTION_PERIOD",
}